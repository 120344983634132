import { defineStore } from 'pinia'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import router from '@/router'
import { formatPhoneNumberToSend } from '@/helpers/phoneFormattersHelper'

import { userCartStore } from '@/stores/CartStore'

export const useOrderStore = defineStore('orderStore', {
    state: () => ({
        assaysInCart: JSON.parse(localStorage.getItem('assaysInCart')) || [],
        firstName: null,
        middleName: null,
        lastName: null,
        birthDate: null,
        phoneNumber: null,
        gender: 'Male',
        menopause: false,
        pregnancy: 0,
        menopauseInfo: null,
        pregnancyDays: 0,
        comment: null,
        completedOrders: [],
        assays: null,
        uniqueCode: null,
        isLoading: false,
        ordersByDoctor: [],
        renderErorrInput: '',
        processedOrders: [],
        patientOrder: [],
        patients: [],
        order: {},
        renderOrderPatient: false,
        renderPatient: true,
        isLoadingOrder: false,
        dataPatient: {
            firstname: null,
            middlename: null,
            lastname: null,
            birthday: null,
            gender: null,
            phone: null,
            email: null
        },
        valueDataUrl: null,
        currentPage: 1,
        dateFrom: null,
        dateTo: null,
        ordersPages: 1,
        loading: false,

        valueDataUrlCompleted: null,
        currentPageCompleted: 1,
        dateFromCompleted: null,
        dateToCompleted: null,
        ordersPagesCompleted: 1,
        loadingCompleted: false,
        checkSavePatient: false,
        searchQueryCompleted: '',
        searchQueryOrder: '',
        inputErorrPatient: false,
        patientsOrderCard: [],
        spinnerLoadingPdf: false,
        renderSpinnerAllPdf: false,
        blockButtonCreateApplication: false,
        currentTab: 1,
        renderEditOrder: false

    }),

    getters: {
        hasAssaysInCart() {
            const analyzesStore = useAnalyzesStore()
            return Boolean(
                this.assaysInCart
                    .map(code => analyzesStore.analyzes?.find(analysis => analysis.code === code))
                    .filter(Boolean)
                    .length
            )
        },
        stateCompletedOrders() {
            return this.completedOrders
        },
        stateOrders() {
            return this.processedOrders
        },
        isLoadingOrderGetter() {
            return this.loading
        },
        isLoadingOrderCompleted() {
            return this.loadingCompleted
        }
    },
    actions: {
         deleteOrder(id) {
             axios.delete(`/api/mobile-application/${id}`)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.completedOrders = this.completedOrders.filter(item => item.id !== id)
                        showSuccessNotification('Заявка успешно удалена')
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }
            )
        },
        async getPatient() {
            await axios.get('/api/doctor/patients')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.patientsOrderCard = response.data.data
                    } else if (response.data.message) {
                        showSuccessNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
        },
        setDates(page, dateFrom, dateTo, searchQuery) {
            if (page === 1) {
                this.processedOrders = []
                this.ordersPages = 1
            }
            if (searchQuery) {
                this.searchQueryOrder = searchQuery
            }
            this.dateFrom = dateFrom
            this.dateTo = dateTo
            this.currentPage = 1
        },
        setCompletedDates(page, dateFrom, dateTo, searchQuery) {
            if (page === 1) {
                this.completedOrders = []
                this.ordersPagesCompleted = 1
            }
            if (searchQuery) {
                this.searchQueryCompleted = searchQuery
            }
            this.dateFromCompleted = dateFrom
            this.dateToCompleted = dateTo
            this.currentPageCompleted = 1
        },
        addAssayToCart(assay) {
            this.assaysInCart.push(assay)
        },
        removeAssayFromCart(code) {
            const index = this.assaysInCart.findIndex(item => item.code === code)
            if (index !== -1) {
                this.assaysInCart.splice(index, 1)
            }
        },
        getAssayCount(code) {
            return this.assaysInCart.filter(item => item.code === code).length
        },
        async printOneDocument(assays) {
            this.renderSpinnerAllPdf = true
            try {
                const response = await axios.post('api/order/full/pdf', {
                    'uniqueCode': assays.uniqueCode
                }, {
                    responseType: 'blob'
                })
                if (response?.data?.success && !response.data.success) {
                    showErrorNotification(response?.data?.data ? response.data.data : 'Документ не найден')
                    this.renderSpinnerAllPdf = false
                    return null
                }
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const blobURL = URL.createObjectURL(blob)
                // Открываем PDF в новом окне 
                const newWindow = window.open(blobURL, '_blank')
                if (newWindow) {
                    newWindow.onload = () => {
                        newWindow.focus()
                    }
                } else {
                    showErrorNotification('Документ не открывается в новом окне. Проверте настройки браузера.')
                }
                this.renderSpinnerAllPdf = false
            } catch (error) {
                showErrorNotification('Документ не найден')
                this.renderSpinnerAllPdf = false
            }
        },
        async printDocument(assays) {
            this.spinnerLoadingPdf = true
            try {
                const response = await axios.post('/api/order/pdf', {
                    'documentId': assays.documentId,
                    'documentName': assays.documentName,
                    'download': false
                }, {
                    responseType: 'blob'
                })
                if (response?.data?.success && !response.data.success) {
                    showErrorNotification(response?.data?.data ? response.data.data : 'Документ не найден')
                    this.spinnerLoadingPdf = false
                    return null
                }
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const blobURL = URL.createObjectURL(blob)

                // Открываем PDF в новом окне 
                const newWindow = window.open(blobURL, '_blank')
                if (newWindow) {
                    newWindow.onload = () => {
                        newWindow.focus()
                        // newWindow.print()
                    }
                } else {
                    showErrorNotification('Документ не открывается в новом окне. Проверте настройки браузера.')
                }
                this.spinnerLoadingPdf = false
            } catch (error) {
                console.error('Error downloading PDF', error)
                this.spinnerLoadingPdf = false
            }
        },
        setFirstName(newFirstName) {
            this.firstName = newFirstName
        },

        setLastName(newLastName) {
            this.lastName = newLastName
        },

        setMiddleName(newMiddleName) {
            this.middleName = newMiddleName
        },

        setPhoneNumber(newPhoneNumber) {
            this.phoneNumber = newPhoneNumber
        },

        setBirthDate(newBirthDate) {
            this.birthDate = newBirthDate
        },

        setGender(newGender) {
            this.gender = newGender
        },

        setMenopause(newMenopause, statusMenopause) {
            if (statusMenopause) {
                this.pregnancyDays = 0
                this.pregnancy = 0
            }
            this.menopause = newMenopause
        },

        setMenopauseInfo(newMenopauseInfo) {
            this.menopauseInfo = newMenopauseInfo
        },

        setPregnancy(newPregnancy, statusPregnancy) {
            if (statusPregnancy) {
                this.pregnancyDays = 0
            }
            this.pregnancy = newPregnancy
        },

        setPregnancyDays(newPregnancyDays) {
            this.pregnancyDays = newPregnancyDays
        },

        setComment(newComment) {
            this.comment = newComment
        },

        getAssaysInCart() {
            const analyzesStore = useAnalyzesStore()
            return this.assaysInCart.map(code => analyzesStore.analyzes.find(analysis => analysis.code === code))
        },

        getAssaysById(ids) {
            const analyzesStore = useAnalyzesStore()
            return ids.map(code => analyzesStore.analyzes?.find(analysis => analysis.code === code)).filter(Boolean)
        },

        getOrderById(id) {
            const order = this.completedOrders.find(order => order.id === id)
            if (order) {
                this.firstName = order.firstName
                this.middleName = order.middleName
                this.lastName = order.lastName
                // this.birthDate = new Date(order.birthDate) // Преобразование из Unix timestamp
                this.phoneNumber = formatPhoneNumberToSend(order.phone)
                this.gender = order.gender
                this.menopause = order.menopause
                this.pregnancy = order.pregnancy
                this.menopauseInfo = order.menopauseInfo
                this.pregnancyDays = order.pregnancyPeriod
                this.comment = order.comment
                this.assays = order.assays
                this.uniqueCode = order.uniqueCode
            }
        },
        checkAnalyzes(formData) {
            const errorMessages = {
                firstName: 'имя',
                middleName: 'отчество',
                lastName: 'фамилия',
                birthDate: 'дата рождения',
                phone: 'номер телефона',
                comment: 'комментрий',
                gender: 'пол'
            }
            delete formData.comment
            let listError = ''
            let count = 0
            for (const key in formData) {
                if (formData[key] === null && errorMessages[key]) {
                    listError = listError + ' ' + errorMessages[key]
                    count++
                }
            }
            if (count === 0) {
                this.renderErorrInput = false
                return true
            }
            this.renderErorrInput = true
            showErrorNotification('Не заполнено' + listError)
            return false
        },
        createOrder(order) {
            const cartStore = userCartStore()
            order.assays = this.assaysInCart
            order.phone = order.phone.replace(/\D/g, '')
            if ((order.phone && order.phone.length < 11) || (!order.phone)) {
                this.blockButtonCreateApplication = false
                return showErrorNotification('Поле номер телефона, должно быть заполнено и содержать 11 цифр')
            }
            const noAnonymousApplication = {}
            for (const key in order) {
                if (order[key] !== '' && order[key] !== null && !(order.gender === 'Male' && (key === 'menopause' || key === 'lastMenstruationDate' || key === 'pregnancy' || key === 'pregnancyPeriod'))) {
                    noAnonymousApplication[key] = order[key]
                }
            }
            const anonymousApplication = { 'phone': order.phone, 'assays': order.assays }
            if (order.comment) {
                anonymousApplication.comment = order.comment
            }
            const sendData = cartStore.orderTub === 2 ? noAnonymousApplication : anonymousApplication
            axios.post('/api/mobile-application', sendData, {
                withCredentials: true
            }).then(data => {
                    if (data?.data?.success && data?.data?.message) {
                        showSuccessNotification(data.data.message)
                    } else if (!data?.data?.success && data?.data?.message) {
                        showErrorNotification(data.data.message)
                    }
                    this.clearCart()
                    this.currentTab = 2
                    router.push({ name: 'Orders' })
                }
            ).catch((err) => {
                showErrorNotification(err.response.data.message)
                if (err?.response?.data?.errors) {
                    err.response.data.errors.forEach(item => {
                        const errorArray = item.split(':')
                        showErrorNotification(errorArray[1])
                    })
                } else {
                    showErrorNotification('Ошибка сервера. Обратитесь к разработчикам')
                }
            }).finally(() => {
                this.blockButtonCreateApplication = false
            })
        },

        async editOrderById(id, birthDateOrder) {
            this.isLoading = true
            // const birthDateUnix = (new Date(this.birthDate).getTime())
            // const roundNum = Math.round(birthDateUnix)
            const formData = {
                firstName: this.firstName,
                middleName: this.middleName,
                lastName: this.lastName,
                birthDate: birthDateOrder,
                phone: formatPhoneNumberToSend(this.phoneNumber),
                gender: this.gender,
                menopause: this.menopause,
                pregnancy: this.pregnancy,
                menopauseInfo: this.menopauseInfo,
                pregnancyPeriod: this.pregnancyDays,
                comment: this.comment,
                assays: this.assays,
                uniquecode: this.uniqueCode
            }
            if (!formData.birthDate) {
               delete formData.birthDate 
            }
            const filteredData = {}
            for (const key in formData) {
                if (formData[key] !== '' && formData[key] !== null && !(formData.gender === 'Male' && (key === 'menopause' || key === 'lastMenstruationDate' || key === 'pregnancy' || key === 'pregnancyPeriod'))) {
                    filteredData[key] = formData[key]
                }
            }
            await axios.patch(`/api/mobile-application/${id}`, filteredData)
                .then(response => {
                    if (response.data.success && response.data.data) {
                        this.updateCompletedOrdersInStore({ id, ...response.data.data })
                        showSuccessNotification('Заявка обновлена')
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
            })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification('Ошибка обновления заявки', error.response.data.message)
                        return false
                    } else {
                        showErrorNotification('Ошибка сервера')
                        return false
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async deleteOrderById(orderData) {
            this.isLoading = true
            await axios.delete(`api/mobile-order/${orderData.id}`, { params: { doctor_id: orderData.doctorId } })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        // Условие срабатывает если удаление происходит от имени менеджера
                        orderData.doctorId && this.getCompletedOrdersByDoctorId(orderData.doctorId)

                        // Найти индекс элемента, который нужно удалить
                        const index = this.completedOrders.findIndex(order => order.id === orderData.id)

                        // Удалить элемент, если он найден
                        index !== -1 && this.completedOrders.splice(index, 1)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },
         getPatientOrders() {
            this.renderPatientLoading = true
             axios.get('api/doctor/patients')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.patients = response.data.data
                        this.renderPatientLoading = false
                    } else if (response.data.message) {
                        this.renderPatientLoading = false
                        showErrorNotification(response.data.message)
                    } else {
                        this.renderPatientLoading = false
                        showErrorNotification('Ошибка сервера')
                    }
                }).catch(error => {
                 if (error.response.data.message) {
                     showErrorNotification(error.response.data.message)
                     this.renderPatientLoading = false
                 } else {
                     showErrorNotification('Ошибка сервера')
                     this.renderPatientLoading = false
                 }
             })
        },
         
        async savePatient() {
            const formData = {
                firstname: this.dataPatient.firstname,
                middlename: this.dataPatient.middlename,
                lastname: this.dataPatient.lastname,
                phone: this.dataPatient.phoneNumber,
                gender: this.dataPatient.gender,
                birthday: this.dataPatient.birthDate,
                email: this.dataPatient.email
            }
            if (!formData.email || formData.email === '') {
                delete formData.email
            }
            if (formData.phone) {
                const formattedNumber = formData.phone.replace(/\D/g, '') // Удаляем все нецифровые символы
                if (formattedNumber.startsWith('7')) {
                    formData.phone = formattedNumber.slice(0) // Если номер начинается с 7, удаляем первую цифру
                }
            }
            let count = 0
            for (const key in formData) {
                if (!formData[key]) {
                    count++
                }
            }
            if (count > 0) {
                this.inputErorrPatient = true
                return showErrorNotification('Не все поля заполнены')
            }
            this.inputErorrPatient = false
            await axios.post('api/doctor/patients', formData)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.getPatientOrders()
                        showSuccessNotification('Пациент успешно создан')
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
        },
        async getCompletedOrders() {
            if (this.dateFromCompleted && this.dateToCompleted && this.dateFromCompleted > this.dateToCompleted) {
                this.dateToCompleted = null
                this.dateFromCompleted = null
                return showErrorNotification('Дата окончания не может быть раньше даты начала')
            }

            this.loadingCompleted = true
            if (this.currentPageCompleted !== 1 && this.currentPageCompleted > this.ordersPagesCompleted) {
                this.loadingCompleted = false
                return null
            }
            // this.completedOrders = []
            this.valueDataUrlCompleted = `/api/mobile-application?page=${this.currentPageCompleted}`
            this.currentPageCompleted += 1
            if (this.dateFromCompleted) {
                this.valueDataUrlCompleted += `&dateFrom=${this.dateFromCompleted}`
            }
            if (this.dateToCompleted) {
                this.valueDataUrlCompleted += `&dateTo=${this.dateToCompleted}`
            }
            if (this.searchQueryCompleted) {
                this.valueDataUrlCompleted += `&search=${this.searchQueryCompleted}`
            }
            this.loadingCompleted = true
            try {
                const response = await axios.get(this.valueDataUrlCompleted)
                const newOrders = response.data.data.result
                this.ordersPagesCompleted = response.data.data.pagination.pages
                // this.processedOrders = newOrders

                this.completedOrders = [...this.completedOrders, ...newOrders]
                this.completedOrders.sort(function(a, b) {
                    const keyA = new Date(a.updated_at)
                    const keyB = new Date(b.updated_at)
                    if (keyA < keyB) return -1
                    if (keyA > keyB) return 1
                    return 0
                })
                this.loadingCompleted = false
            } catch (error) {
                if (!error.response) {
                    showErrorNotification('Ошибка сервера, списки не получены')
                } else {
                    showErrorNotification(error.response.data.message)
                }
            } finally {
                this.loadingCompleted = false
            }
        },
        async getProcessedOrders() {
            if (this.dateFrom && this.dateTo && this.dateFrom > this.dateTo) {
                this.dateTo = null
                this.dateFrom = null
                return showErrorNotification('Дата окончания не может быть раньше даты начала')
            }
            if (this.currentPage > this.ordersPages) {
                return null
            }
            this.loading = true
            this.valueDataUrl = `/api/mobile-order?page=${this.currentPage}`
            this.currentPage += 1
            if (this.dateFrom) {
                this.valueDataUrl += `&dateFrom=${this.dateFrom}`
            }
            if (this.dateTo) {
                this.valueDataUrl += `&dateTo=${this.dateTo}`
            }
            if (this.searchQueryOrder) {
                this.valueDataUrl += `&search=${this.searchQueryOrder}`
            }
            this.isLoading = true
            this.isLoadingOrder = true
            try {
                const response = await axios.get(this.valueDataUrl)
                const newOrders = response.data.data.result
                this.ordersPages = response.data.data.pagination.pages
                // this.processedOrders = newOrders

                this.processedOrders = [...this.processedOrders, ...newOrders]
                this.loading = false
            } catch (error) {
                showErrorNotification(error.response.data.message)
            } finally {
                this.isLoading = false
                this.isLoadingOrder = false
                this.loading = false
            }
        },
        async getCompletedOrdersByDoctorId(doctorId) {
            await axios.get('/api/mobile-order', { params: { doctor_id: doctorId } })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.ordersByDoctor[doctorId] = response.data.data
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
        },
        updateCompletedOrdersInStore(orderUpdatedData) {
            const index = this.completedOrders.findIndex(user => user.id === orderUpdatedData.id)
            this.completedOrders[index] = { ...this.completedOrders[index], ...orderUpdatedData }
        },

        clearFormDataValues() {
            this.firstName = null
            this.middleName = null
            this.lastName = null
            this.birthDate = null
            this.phoneNumber = null
            this.gender = null
            this.menopause = false
            this.pregnancy = 0
            this.menopauseInfo = null
            this.pregnancyDays = 0
            this.comment = null
            this.assays = null
        },

        clearCart() {
            this.assaysInCart = []
            localStorage.removeItem('assaysInCart')
            const cartStore = userCartStore()
            cartStore.clearOrderData()
        },

        resetState() {
            this.completedOrders = []
            this.clearFormDataValues()
            this.clearCart()
        }
    }
})
