<template>
  <div class="row d-none d-sm-flex mx-3 mb-4">
    <div class="col-6 d-flex align-items-center">
      <h2 class="text-uppercase h4 m-0"><i class="bi bi-people fs-4 me-2"/>Врачи</h2>
    </div>
  </div>
  <div class="doctors-wrapper">
    <div v-if="!doctorsStore.isLoading && doctorsStore.doctors && doctorsStore.doctors.length === 0" style="    width: 100%;
    height: calc(-333px + 100vh);
    align-content: center;
    text-align: center;">Нет врачей
    </div>
  </div>
  <div class="row mx-0 mx-sm-3 mb-3 mb-sm-0">
    <div class="col-12">
      <div v-if="doctorsStore.doctors" class="size-search">
        <input
            type="text"
            class="form-control mb-3 mobile-search-input search-analysis"
            placeholder="Поиск врачей"
            v-model="searchQuery"
        />
      </div>
      <div
          class="text-center"
          v-if="
          searchQuery !== '' &&
          !filteredDoctor.length
        "
      >
        <h2 class="text-uppercase h4 message-no-analysis">
          Доктор не найден
        </h2>
      </div>
      <div v-if="doctorsStore.doctors" class="accordion accordion-main-style" id="accordionPanelDoctors">
        <div class="accordion-item" v-for="doctor in filteredDoctor" :key="doctor.id">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed justify-content-between"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#doctor-${doctor.id}`"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseOne"
            >
                                <span class="inner-content">
                                   {{ doctor.last_name }}  {{ doctor.first_name }} {{ doctor.middle_name }}
                                    <ActiveStatus :type="doctor.active" class="user-select-none"/>
                                </span>
            </button>
          </h2>
          <div :id="`doctor-${doctor.id}`" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="container-doctor-bonus">
                <DoctorBonusGraph v-if="
                doctorsStore.doctorBonus.find((item) => item.doctor_id === doctor.doctor_id)?.data
                && !doctorsStore.isLoading" :data="doctorsStore.doctorBonus.find((item) => item.doctor_id === doctor.doctor_id).data"  :main-chart-title="`mainChart_${doctor.id}`"/>
                <div v-else class="message-container">
                  <p v-if="!doctorsStore.isLoading">Данные по бонусам формируются...</p>
                </div>
                <LoadingSpinner v-if="doctorsStore.isLoading && doctorsStore.doctorBonus && !doctorsStore.doctorBonus.length"  :wrapperStyle="`width: 100%;
    height: 1px;
    justify-content: center;
    position: relative;
    display: contents;`" :innerStyle="`position: relative;
    left: 50%;
    top: 20px;
    transform: translate(-50%, -50%);`" :smallSpinner="`smallSpinner`" :showSpinner="true"/>
              </div>
            </div>
              <div>

              <p class="mb-0"><b>Телефон: </b>{{ doctor.phone ?? 'телефон не указан' }}</p>
              <p class="mb-0"><b>Почта: </b>{{ doctor.email ?? 'почта не указана' }}</p>
              <div class="orders-wrapper mt-4" v-if="orderStore.ordersByDoctor[doctor.doctor_id]?.length">
                <h5>Заказы</h5>
                <div class="accordion accordion-main-style" id="accordionPanelOrders">
                  <div class="accordion-item" v-for="order in orderStore.ordersByDoctor[doctor.doctor_id]"
                       :key="order.id">

                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed justify-content-between"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="`#orders-${order.id}`"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseOne"
                      >

                        {{ order.lastName }} {{ order.firstName }} {{ order.middleName }}
                        <OrderStatusBadge :type="order.status"/>
                      </button>

                      <span class="action-buttons" v-if="isManagerEditDoctorOrder">
                                                    <button type="button"
                                                            class="btn btn-sm btn-primary me-1"
                                                            @click.stop="setOrderData(order); showEditOrderModal()"
                                                    >
                                                        <i class="bi bi-pencil-square"/>
                                                    </button>

                                                    <button type="button"
                                                            class="btn btn-sm btn-danger"
                                                            @click="setOrderData(order, doctor.doctor_id); showDeleteOrderModal()"
                                                    >
                                                        <i class="bi bi-x-circle"/>
                                                    </button>
                                                </span>
                    </h2>

                    <div :id="`orders-${order.id}`" class="accordion-collapse collapse">
                      <div class="accordion-body">
                        <p><b>День рождения: </b>{{ formatUnixTimestampOnlyDate(order.birthDate) }}</p>
                        <p><b>Пол: </b>{{ order.gender === 'Male' ? 'Мужской' : 'Женский' }}</p>
                        <p><b>Почта: </b>{{ order.email === null ? 'Не указана' : order.email }}</p>
                        <p><b>Телефон: </b>{{ order.phone }}</p>
                        <p v-if="order.gender === 'Female'"><b>Беременность:</b> {{ order.pregnancy }}</p>
                        <p v-if="order.gender === 'Female'"><b>Период беременности: </b>{{ order.pregnancyPeriod }}
                        </p>
                        <p v-if="order.gender === 'Female'"><b>Дата последней
                          менструации: </b>{{ order.lastMenstruationDate }}</p>
                        <p v-if="order.gender === 'Female'"><b>Менопауза: </b>{{ order.menopause }}</p>
                        <p v-if="order.gender === 'Female'"><b>MenopauseInfo: </b>{{ order.menopauseInfo }}</p>
                        <p><b>Анализы: </b>{{ order.assays }}</p>
                        <!-- <p><b>Статус заказа: </b>{{ order.status }}</p> -->
                        <p><b>Заказ создан: </b>{{ formatUnixTimestampOnlyDate(order.createdAt) }}</p>
                        <p><b>Заказ изменен: </b>{{ formatUnixTimestampOnlyDate(order.updatedAt) }}</p>
                        <p><b>Комментарий к заказу: </b>{{ order.comment === null ? 'Не указан' : order.comment }}</p>
                        <p class="mb-0"><b>Уникальный код: </b>{{ order.uniqueCode }}</p>

                        <div class="mt-1" v-if="isManagerEditDoctorOrder">
                          <button type="button"
                                  class="btn btn-sm btn-primary me-2"
                                  @click="setOrderData(order); showEditOrderModal()"
                          >
                            <i class="bi bi-pencil-square"/>
                            Редактировать
                          </button>

                          <button type="button"
                                  class="btn btn-sm btn-danger"
                                  @click="setOrderData(order); showDeleteOrderModal()"
                          >
                            <i  class="bi bi-x-circle"/>
                            Удалить
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingSpinner spinnerStyle="min-height: calc(var(--ps-height) - 53px);" v-if="!doctorsStore.doctors"
                      :showSpinner="!doctorsStore.doctors"/>
    </div>
  </div>

  <EditOrderModal :orderDetails="orderDetails"/>
  <DeleteOrderModal :orderDetails="orderDetails"/>
</template>

<script setup>
import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate } from '@/helpers/dateHelpers'
import { useDoctorsStore } from '@/stores/DoctorsStore'
import { onMounted, ref, provide, computed } from 'vue'
import { useOrderStore } from '@/stores/OrderStore'
import ActiveStatus from '@/components/Profile/Badges/ActiveStatus.vue'
import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
import EditOrderModal from '@/components/Profile/Modals/EditOrderModal.vue'
import DeleteOrderModal from '@/components/Profile/Modals/DeleteOrderModal.vue'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import ProgressBar from '../ui/ProgressBar.vue'
import DoctorBonusGraph from '@/components/ui/DoctorBonusGraph.vue'

const searchQuery = ref('')
const doctorsStore = useDoctorsStore()
const orderStore = useOrderStore()

const editOrderModal = ref(null)
const deleteOrderModal = ref(null)
const orderDetails = ref(null)

// Отключил элементы управление редактирования заказов
const isManagerEditDoctorOrder = ref(false)

provide('editOrderModal', editOrderModal)
provide('deleteOrderModal', deleteOrderModal)

const setOrderData = (orderData, doctorId = null) => {
  orderDetails.value = { ...orderDetails.value, ...orderData }

  if (doctorId !== null) {
    orderDetails.value.doctorId = doctorId
  }
}
const filteredDoctor = computed(() => {
  const searchTerms = searchQuery.value.toLowerCase().split(' ')
  if (searchQuery.value === '') {
    return doctorsStore.doctors
  } else {
    return doctorsStore.doctors.filter((value) => {
      return searchTerms.every((term) => {
        return (
            value.last_name.toLowerCase().includes(term) || (value.first_name.toLowerCase().includes(term) + value.last_name.toLowerCase().includes(term)) ||
            (value.middle_name
                ? (value.first_name.toLowerCase().includes(term) + value.last_name.toLowerCase().includes(term) + value.middle_name.toLowerCase().includes(term))
                : '') || (value.email ? value.email.includes(term) : '') || (value.phone ? value.phone.includes(term) : '')
        )
      })
    })
  }
})
const showEditOrderModal = () => editOrderModal.value.show()
const showDeleteOrderModal = () => deleteOrderModal.value.show()

onMounted(() => {
   doctorsStore.getDoctors().finally(() => {
    if (doctorsStore.doctors) {
      doctorsStore.getDoctorBonus(doctorsStore.doctors.map(doctor => doctor.doctor_id))
    }
  })
})
</script>

<style scoped lang="scss">
.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

#accordionPanelDoctors {
  .accordion-header {
    span.inner-content {
      margin-right: 0 !important;
    }
  }
}

#accordionPanelOrders {
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);

  .action-buttons {
    position: absolute;
    top: 50%;
    right: 3.5rem;
    transform: translateY(-50%);
    display: flex;
    z-index: 1;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7);

      i {
        font-size: 0.75rem;
      }
    }
  }

  span.badge {
    margin-left: auto;
    //margin-right: 4.35rem;
  }
}
.text-center {
  text-align: center !important;
  height: 300px
}
.message-no-analysis {
  height: 50%;
  color: #aeaeae;
  font-size: larger;
  align-content: end;
}
.container-doctor-bonus{
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.message-container {
  color: #7d7b7b;
  font-size: 14px;
}
</style>
