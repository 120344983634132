import { defineStore } from 'pinia'
import axios from '@/services/axios'
import router from '@/router'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import { useOrderStore } from '@/stores/OrderStore'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { useBranchesStore } from '@/stores/BranchesStore'
import { useDoctorsStore } from '@/stores/DoctorsStore'
import { useManagersStore } from '@/stores/ManagersStore'
import { useUsersStore } from '@/stores/UsersStore'
import { useProfileStore } from '@/stores/ProfileStore'

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        token: localStorage.getItem('bearerToken'),
        userProfile: JSON.parse(localStorage.getItem('userProfile')),
        isLoading: false
    }),

    actions: {
        async login(name, password) {
            const orderStore = useOrderStore()
            this.isLoading = true

            await axios.post('/api/login', { name, password })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        orderStore.resetState()
                        localStorage.setItem('bearerToken', response.data.data.token)
                        this.token = localStorage.getItem('bearerToken')
                        this.fetchProfile().then(() => {
                            if (this.userProfile.role === 'doctor' || this.userProfile.role === 'admin') {
                                router.push({ name: 'Analyzes' })
                            } else if (this.userProfile.role === 'manager') {
                                router.push({ name: 'TopManager' })
                            } else if (this.userProfile.role === 'chief-doctor') {
                                router.push({ name: 'FavoriteLists' })
                            } else {
                                router.push({ name: 'Doctors' })
                            }
                        })
                        setTimeout(() => showSuccessNotification('Вы успешно авторизированны'), 750)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }).catch(error => {
                    console.log(error.response)
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }
                )
                .finally(() => setTimeout(() => this.isLoading = false, 1000))
        },

        async fetchProfile() {
            await axios.get('/api/profile')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.userProfile = response.data.data
                        localStorage.setItem('userProfile', JSON.stringify(response.data.data))
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async logout() {
            const orderStore = useOrderStore()
            const analyzesStore = useAnalyzesStore()
            const branchesStore = useBranchesStore()
            const doctorStore = useDoctorsStore()
            const managerStore = useManagersStore()
            const usersStore = useUsersStore()
            const profileStore = useProfileStore()
            await axios.delete('/api/logout')
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => {
                    this.removeToken()
                    orderStore.resetState()
                    branchesStore.resetState()
                    doctorStore.resetState()
                    managerStore.resetState()
                    usersStore.resetState()
                    profileStore.resetState()

                    router.push({ name: 'Login' })
                })
        },

        removeToken() {
            localStorage.removeItem('bearerToken')
            localStorage.removeItem('userProfile')
            this.token = null
            this.userProfile = null
        }
    },

    getters: {
        isAuthenticated() {
            return !!this.token
        },

        isDoctor() {
            return this.userProfile && (this.userProfile.role === 'doctor')
        },

        isStaff() {
            return this.userProfile && (this.userProfile.role === 'staff')
        },

        isAdmin() {
            return this.userProfile && this.userProfile.role === 'admin'
        },
        isManager () {
            return this.userProfile && this.userProfile.role === 'manager'
        },
        isChiefDoctor () {
            return this.userProfile && this.userProfile.role === 'chief-doctor'
        }
    }
})
