<template>
    <div>
        <h5 class="text-uppercase text-danger fw-bold m-0 mb-3 px-2 py-2 rounded">Недостаточно прав</h5>
        <button class="btn  btn-outline-danger text-uppercase" @click="goBack"> <i class="bi bi-backspace me-2"></i>назад</button>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router'

    const router = useRouter()

    const goBack = () => router.go(-1)
</script>

<style lang="scss" scoped>
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - var(--header-height));

        img {
            width: 200px;
        }
    }
</style>
