<template>
  <div class="permissions">
    <h1>Полномочия ролей</h1>
    <div class="spinner-block" v-if="loading">
      <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <div v-if="!loading">
      <table v-if="mode === 'show'" class="table table-responsive table-hover table-striped">
        <thead>
        <th>
          ID роли
        </th>
        <th>
          Название роли
        </th>
        <th>
        </th>
        </thead>

        <tbody>
        <tr v-for="role in roles" :key="role.id">
          <td>{{ role.id }}</td>
          <td>{{ role.title }}</td>
          <td>
            <button class="btn btn-sm btn-danger" @click="changePermissions(role.id)">Изменить права</button>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="edit-block" v-if="mode === 'edit'">
        <div class="header">
          <p class="header_title">Редактирование роли: <span>{{ editRoleName }}</span></p>
        </div>

        <div>
          <label class="checkboxLabel" v-for="permission in permissions" :key="permission.id"
                 :for="`permission_${permission.id}`">
            <input type="checkbox" v-model="rolePermissions" :value="permission.id" :id="`permission_${permission.id}`">
            {{ permission.title }} <span>[{{ permission.name }}]</span>
          </label>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-sm btn-secondary mr-2" @click="mode = 'show'" :disabled="savingPermissions">ОТМЕНИТЬ
          </button>
          <button class="btn btn-danger btn-sm" :disabled="savingPermissions" @click="savePermissions">
            ВНЕСТИ ИЗМЕНЕНИЯ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from '@/services/axios'
import { computed, onMounted, ref } from 'vue'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'

const mode = ref('show')
const roles = ref([])
const editRoleName = ref('')
const editRoleId = ref(null)
const rolePermissions = ref([])
const permissions = ref([])

const loadingRoles = ref(true)
const loadingPermissions = ref(true)
const savingPermissions = ref(false)

const loading = computed(() => {
  return loadingRoles.value || loadingPermissions.value
})

const loadRoles = () => {
  loadingRoles.value = true
  axios.get('/api/admin/roles').then(response => {
    if (response.data && response.data.success) {
      roles.value = response.data.data
    }
  }).catch(error => {
    if (error.response.data && !error.response.data.success) {
      if (!error.response.data.errors) {
        return
      }
      if (error.response.data.errors.length) {
        error.response.data.errors.forEach(item => {
          showErrorNotification(item)
        })
      } else if (error.response.data.message) {
        showErrorNotification(error.response.data.message)
      } else {
        showErrorNotification('Обратитесь в техподдержку')
      }
    } else {
      showErrorNotification('Обратитесь в техподдержку')
    }
  }).finally(() => {
    loadingRoles.value = false
  })
}

const loadPermissions = () => {
  loadingPermissions.value = true
  axios.get('/api/admin/permissions').then(response => {
    if (response.data && response.data.success) {
     return permissions.value = response.data.data
    }
  }).catch(error => {
    if (error.response.data && !error.response.data.success) {
      if (!error.response.data.errors) {
         return showErrorNotification('Обратитесь в техподдержку')
      }
      if (error.response.data.errors.length) {
        error.response.data.errors.forEach(item => {
          showErrorNotification(item)
        })
      } else if (error.response.data.message) {
        showErrorNotification(error.response.data.message)
      } else {
        showErrorNotification('Обратитесь в техподдержку')
      }
    } else {
      showErrorNotification('Обратитесь в техподдержку')
    }
  }).finally(() => {
    loadingPermissions.value = false
  })
}

const changePermissions = (roleId) => {
  mode.value = 'edit'

  editRoleId.value = roleId
  editRoleName.value = ''

  editRoleName.value = roles.value.filter(item => item.id === roleId)[0].title
  rolePermissions.value = roles.value.filter(item => item.id === roleId)[0].permissions.map(item => item.id)
}

const savePermissions = () => {
  savingPermissions.value = true
  axios.patch('/api/admin/role-permissions', {
    role_id: editRoleId.value,
    permissions: rolePermissions.value
  }).then(response => {
    if (response.data && response.data.success) {
      showSuccessNotification('Изменения успешно сохранены')
      loadRoles()
      editRoleId.value = null
      rolePermissions.value = []
      editRoleName.value = ''
      mode.value = 'show'
    } else {
      showErrorNotification('Обратитесь в техподдержку')
    }
  }).catch(error => {
    if (error.response.data && !error.response.data.success) {
      if (error.response.data.errors.length) {
        error.response.data.errors.forEach(item => {
          showErrorNotification(item)
        })
      } else if (error.response.data.message) {
        showErrorNotification(error.response.data.message)
      } else {
        showErrorNotification('Обратитесь в техподдержку')
      }
    } else {
      showErrorNotification('Обратитесь в техподдержку')
    }
  }).finally(() => {
    savingPermissions.value = false
  })
}

onMounted(() => {
  loadRoles()
  loadPermissions()
})

</script>

<style scoped lang="scss">
.permissions {
  padding: 15px;
  box-sizing: border-box;

  h1 {
    font-family: monospace;
    font-size: 18px;
    color: #700606;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  table {
    th {
      padding: 8px;

      &:first-child {
        width: 100px;
        @media screen and (max-width: 800px) {
          width: auto;
        }
      }

      &:last-child {
        width: 200px;
        @media screen and (max-width: 800px) {
          width: auto;
        }
      }

      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }

    td {
      @media screen and (max-width: 800px) {
        font-size: 12px;
        .btn {
          font-size: 10px;
        }

      }

    }
  }

  .edit-block {
    border: 1px solid #333;
    border-radius: 8px;
    padding: 5px 15px;
    box-sizing: border-box;

    .header {
      .header_title {
        font-size: 15px;
        color: #444;
        font-family: monospace;
        font-weight: bold;

        @media screen and (max-width: 800px) {
          font-size: 12px;
        }

        span {
          color: darkred;
          font-weight: bold;
          font-family: monospace;
        }
      }
    }

    .checkboxLabel {
      display: block;
      margin-bottom: 5px;

      font-family: monospace;
      font-size: 13px;
      color: #333;
      font-weight: bold;
      cursor: pointer;

      @media screen and (max-width: 800px) {
        font-size: 13px;
        margin-bottom: 10px;
      }

      span {
        font-size: 10px;
        @media screen and (max-width: 800px) {
          display: block;
          padding-left: 16px;
        }
      }

      input {
        width: 15px;
        height: 15px;

        @media screen and (max-width: 800px) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .spinner-block {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border {
      width: 50px;
      height: 50px;
    }
  }
}

.mr-2 {
  margin-right: 10px;
}
</style>
