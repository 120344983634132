<template>
    <div class="modal fade" id="deleteFavorite" tabindex="-1" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Удаление списка</h1>
                    <button type="button" class="btn-close"  @click="hideDeleteFavorite" aria-label="Close"></button>
                </div>
                <div class="modal-body" >
                    Вы уверены что хотите удалить список?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" aria-label="Close" @click="hideDeleteFavorite">Закрыть</button>
                    <button type="button"
                            :disabled="isLoading"
                            class="btn btn-danger position-relative"
                            :class="{'pe-none': favoriteListsStore.isLoading}"
                            @click="deleteFavoriteList"
                    >
                        Удалить
                        <span v-if="favoriteListsStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted, ref } from 'vue'
    import { Modal } from 'bootstrap'
    import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
    import axios from '@/services/axios'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
    const favoriteListsStore = useFavoriteListsStore()
    const hideDeleteFavorite = () => { deleteFavorites.value.hide() }
    const props = defineProps(['favoriteListDetails', 'idList'])
    const deleteFavorites = inject('deleteFavorite')
    const isLoading = ref(false)
    const deleteFavoriteList = () => {
      isLoading.value = true
      axios.delete(`api/doctor/global/favorite-list/${props.idList}`).then((response) => {
        if (response.data.success && response.data.data) {
          showSuccessNotification(response.data.message)
          favoriteListsStore.favoriteLists = favoriteListsStore.favoriteLists.filter(item => item.id !== props.idList)
          hideDeleteFavorite()
          isLoading.value = false
        } else if (response.data.message) {
          showErrorNotification(response.data.message)
          isLoading.value = false
        } else {
          showErrorNotification('Ошибка сервера')
          isLoading.value = false
        }
      }).catch(error => {
        if (error.response.data.message) {
          showErrorNotification(error.response.data.message)
          isLoading.value = false
        } else {
          showErrorNotification('Ошибка сервера')
          isLoading.value = false
        }
      })
    }
    onMounted(() => deleteFavorites.value = new Modal('#deleteFavorite'))
</script>
