<template>
    <span :class="['badge', className]">
		{{ text }}
	</span>
</template>

<script setup>
    import { ref, computed } from 'vue'
    const props = defineProps(['type', 'order'])

    const classMap = ref({
        0: 'text-bg-primary',
        1: 'text-bg-primary',
        2: 'text-bg-primary',
        3: 'text-bg-primary',
        4: 'text-bg-danger',
        5: 'text-bg-success',
        6: 'text-bg-primary',
        7: 'text-bg-danger',
        8: 'text-bg-success'
    })

    const textMap = ref({
        0: 'Выполняется',
        1: 'Выполняется',
        2: 'Выполняется',
        3: 'Выполняется',
        4: 'Отменен',
        5: 'Принято врачом',
        7: 'Отменено',
        8: 'Завершен'
    })
    const className = computed(() => {
        if (props.type !== 4 && exampleType()) {
            return classMap.value[8]
        }
        if (props.type !== 4 && !exampleType()) {
            return classMap.value[props.type]
        } else {
            return classMap.value[4]
        } 
    }
    )
    const exampleType = () => {
        for (let i = 0; i < props.order.length; i++) {
                if (props.order[i].state !== 5) {
                    return false
                }
            }  
            return true
    }
    const text = computed(() => {
        if (props.type !== 4 && exampleType()) {
            return textMap.value[8]
        }
        if (props.type !== 4 && !exampleType()) {
            return textMap.value[props.type]
        } else {
            return textMap.value[4]
        }
    }
)

</script>

<style scoped>
    span {
        font-size: 0.75rem;
        width: 93px;
        margin-right: 2px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7);

        @media (max-width: 575.98px) {
            margin-right: 0px;
    position: absolute;
    top: 0;
    right: -2px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0 0 0 5px;
    font-size: 0.65rem;

        }
    }
</style>
