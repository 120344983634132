import axios from '@/services/axios'
import { showErrorNotification } from '@/services/notifications'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'

export async function getTopAnalyzes() {
    const analyzesStore = useAnalyzesStore()
    try {
        const result = await axios.get('/api/analyzes/tops')
        if (result.data.success && result.data.data) {
            analyzesStore.topAnalyzes = result.data.data
        } else if (result.data.message) {
            analyzesStore.loadingTopAnalyzes = false
            return showErrorNotification(result.data.message)
        } else {
            analyzesStore.loadingTopAnalyzes = false
            return showErrorNotification('Ошибка сервера')
        }
        function addAmountToAnalyzes(analyzes, topAnalyzes) {
            const topAnalyzesDict = topAnalyzes.reduce((acc, item) => {
                acc[item.assay_code] = item.amount
                return acc
            }, {})
            return analyzes
                .filter(analyze => topAnalyzesDict[analyze.code] !== undefined)
                .map(analyze => ({
                    ...analyze,
                    amount: topAnalyzesDict[analyze.code]
                }))
        }
        if (analyzesStore.topAnalyzes.length) {
            analyzesStore.topAnalyzes = addAmountToAnalyzes(analyzesStore.analyzes, analyzesStore.topAnalyzes)
            analyzesStore.loadingTopAnalyzes = false
        }
    } catch (error) {
        if (error.response.data.message) {
            showErrorNotification(error.response.data.message)
            analyzesStore.loadingTopAnalyzes = false
        } else {
            showErrorNotification('Ошибка сервера')
        }
    }
    analyzesStore.loadingTopAnalyzes = false
}
