<template>
    <main class="form-signin w-100 m-auto" :class="{'show': vh}">
        <div class="form-login-wrapper">
            <img src="@/assets/images/logo.svg" alt="" class="mb-5 px-3 px-sm-0">
            <form @submit.prevent="onSubmit" class="px-3 px-sm-0">

                <div class="form-floating">
                    <input type="text" class="form-control" id="name" placeholder="Логин" v-model="userName">
                    <label for="name">Почта</label>
                </div>
                <div class="form-floating">
                    <input type="password" class="form-control" id="password" placeholder="Пароль" v-model="password">
                    <label for="password">Пароль</label>
                </div>

                <button :class="['btn btn-danger w-100 py-2 mt-1 position-relative', {'pe-none' : authStore.isLoading}]" type="submit">
                    Войти
                    <span v-if="authStore.isLoading" class="placeholder-wave placeholder-surface">
                        <span class="placeholder" />
                    </span>
                </button>
                <p class="mt-5 mb-1 text-body-secondary">© 2006 - 2023 CL Medical Group.</p>
            </form>
        </div>
    </main>
</template>

<script setup>
    import { ref } from 'vue'
    import { useAuthStore } from '@/stores/AuthStore'
    import { vh } from '@/handlers/resizeHandler'

    const authStore = useAuthStore()
    const userName = ref('')
    const password = ref('')

    const onSubmit = () => {
        if (userName?.value.trim() !== '' && password?.value.trim() !== '') {
            authStore.login(userName.value, password.value)
        }
    }
</script>

<style lang="scss" scoped>
    .form-signin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(var(--vh) * 100);
        min-width: 330px;
        padding: 1rem;
        opacity: 0;

        &.show {
            transition: .2s ease;
            opacity: 1;
        }

        .form-login-wrapper {
            margin-top: -8vh;

            img {
                @media (max-width: 575.98px) {
                    width: 100%;
                }
            }

            form {
                width: 360px;

                .form-floating:focus-within {
                    z-index: 2;
                }

                input {
                    &:focus {
                        box-shadow: none;
                        border-color: #dee2e6;
                    }

                    &[type="text"] {
                        margin-bottom: -1px;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                    }

                    &[type="password"] {
                        margin-bottom: 10px;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }

                button {
                    &[type="submit"] {
                        background-color: #bc141b;
                    }
                }

                p {
                    @media (max-width: 575.98px) {
                        position: absolute;
                        bottom: 0.75rem;
                    }
                }

            }
        }
    }
</style>
