import { defineStore } from 'pinia'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import { formatPhoneNumberToSend } from '@/helpers/phoneFormattersHelper'

export const useManagersStore = defineStore('managersStore', {
    state: () => ({
        roles: [],
        managers: [],
        loginName: null,
        userPassword: null,
        firstName: null,
        middleName: null,
        lastName: null,
        emailAddress: null,
        phoneNumber: null,
        selectedRole: null,
        doctorsByManager: [],
        idManager: null,
        isLoading: null,
        passwordResetLoading: {},
        activeStatusLoading: {}
    }),

    actions: {
        async fetchManagers() {
            this.isLoading = true
            await axios.get('/api/user', {
                params: { role: 'staff' }
            })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.managers = response.data.data
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }
            )
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }).finally(() => this.isLoading = false)
        },

        async createManager() {
            this.isLoading = true
            let responseData = null
            const formData = {
                name: this.loginName,
                password: this.userPassword,
                first_name: this.firstName,
                middle_name: this.middleName,
                last_name: this.lastName,
                email: this.emailAddress,
                phone: formatPhoneNumberToSend(this.phoneNumber),
                role: 'staff'
            }
            if (this.idManager !== 'null') {
                formData.manager_id = this.idManager
            }
            await axios.post('/api/user', formData)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.managers.push(response.data.data)
                        responseData = response.data
                        if (response.data.message) showSuccessNotification(response.data.message)
                        else showSuccessNotification('Успешно сохранено')
                    } else {
                        if (response.data.message) {
                            return showErrorNotification(response.data.message)
                        } else {
                            return showErrorNotification('Ошибка сервера')
                        }
                    }
                })
                // Catch пробрасывается, что бы модальное окно не закрывалось
                // и в следующем then можно было ловить catch
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)

            return responseData
        },

        async moveDoctorsToManagerById(targetManagerId, currentManagerId) {
            this.isLoading = true

            const movingDoctorIds = this.doctorsByManager.map(doctor => doctor.id)

            await axios.put(`/api/user/${targetManagerId}/doctor-transfer`, { doctor_ids: movingDoctorIds })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        showSuccessNotification(response.data.message)
                        // Находим исходного менеджера и удаляем из него перемещенных врачей
                        const sourceManager = this.managers.find(manager => manager.id === currentManagerId)
                        if (sourceManager && sourceManager.doctors) {
                            sourceManager.doctors = sourceManager.doctors.filter(doctor => !movingDoctorIds.includes(doctor.id))
                        }

                        // Находим целевого менеджера и добавляем к нему перемещенных врачей
                        const targetManager = this.managers.find(manager => manager.id === targetManagerId)
                        if (targetManager) {
                            targetManager.doctors = (targetManager.doctors || []).concat(this.doctorsByManager)
                        }
                    } else if (response.data.message) {
                        return showErrorNotification(response.data.message)
                    } else {
                        return showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async editManagerById(id, managerEditedData) {
            this.isLoading = true
            const formData = {
                first_name: managerEditedData.first_name,
                middle_name: managerEditedData.middle_name,
                last_name: managerEditedData.last_name,
                email: managerEditedData.email,
                phone: formatPhoneNumberToSend(managerEditedData.phone),
                role: 'staff'
            }
            if (managerEditedData.manager_id !== 'null') {
                formData.manager_id = managerEditedData.manager_id
            }
            await axios.put(`/api/user/${id}`, formData)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        showSuccessNotification(response.data.message)
                        this.updateManagerInStore({ id, ...managerEditedData })
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },
        async getPasswordResetUrl(managerEmail, managerId) {
            this.passwordResetLoading[managerId] = true

            await axios.post('/api/forget-password', { email: managerEmail })
                .then(response => {
                    if (response.data.success && response.data.data) {
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.passwordResetLoading[managerId] = false)
        },

        async activeStatusManagerSwitcher(managerId) {
            this.activeStatusLoading[managerId] = true

            await axios.put(`api/user/${managerId}/disable`)
                .then((response) => {
                        if (response.data.success && response.data.data) {
                            const index = this.managers.findIndex(manager => manager.id === managerId)
                            this.managers[index].active = !this.managers[index].active
                        } else if (response.data.message) {
                            showErrorNotification(response.data.message)
                        } else {
                            showErrorNotification('Ошибка сервера')
                        }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.activeStatusLoading[managerId] = false)
        },

        updateManagerInStore(managerUpdatedData) {
            const index = this.managers.findIndex(manager => manager.id === managerUpdatedData.id)
            this.managers[index] = { ...this.managers[index], ...managerUpdatedData }
        },

        resetState() {
            this.roles = []
            this.managers = []
        }
    }
})
