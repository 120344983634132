import { defineStore } from 'pinia'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import { formatPhoneNumberToSend } from '@/helpers/phoneFormattersHelper'

export const useUsersStore = defineStore('usersStore', {
    state: () => ({
        roles: [],
        users: [],
        loginName: null,
        userPassword: null,
        firstName: null,
        middleName: null,
        lastName: null,
        emailAddress: null,
        phoneNumber: null,
        selectedRole: null,
        managerId: null,
        isLoading: null,    
        passwordResetLoading: {},
        activeStatusLoading: {}
    }),

    actions: {
        async fetchRoles() {
            await axios.get('/api/user/roles')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.roles = response.data.data
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
        },

        async fetchUsers() {
            await axios.get('/api/user')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.users = response.data.data
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
        },

        async createUser() {
            this.isLoading = true
            let responseData = null
            const messages = [
                'Введите фамилию',
                'Введите имя',
                'Введите email',
                'Выберите роль'
            ]
            const values = [this.lastName, this.firstName, this.emailAddress, this.selectedRole]
            for (let i = 0; i < values.length; i++) {
                if (!values[i]) {
                    showErrorNotification(messages[i])
                    this.isLoading = false
                    return
                }
            }
            const formData = {
                name: this.loginName,
                password: this.userPassword,
                first_name: this.firstName,
                middle_name: this.middleName,
                last_name: this.lastName,
                email: this.emailAddress,
                phone: formatPhoneNumberToSend(this.phoneNumber),
                role: this.selectedRole
            }
            if (this.selectedRole === 'staff' && this.managerId !== 'null') {
                formData.manager_id = this.managerId
            }
            await axios.post('/api/user', formData)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.users.push(response.data.data)
                        responseData = response.data
                        showSuccessNotification('Пользователь создан успешно')
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                // Catch пробрасывается, что бы модальное окно не закрывалось
                // и в следующем then можно было ловить catch
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)

            return responseData
        },

        async editUserById(id, userEditedData) {
            this.isLoading = true
            const formData = {
                first_name: userEditedData.first_name,
                middle_name: userEditedData.middle_name,
                last_name: userEditedData.last_name,
                email: userEditedData.email,
                phone: formatPhoneNumberToSend(userEditedData.phone),
                role: userEditedData.role
            }
            if (userEditedData.manager_id) {
                formData.manager_id = userEditedData.manager_id
            }
            await axios.put(`/api/user/${id}`, formData)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.updateUserInStore({ id, ...userEditedData })
                        showSuccessNotification('Пользователь отредактирован')
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
            })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async resetUserPassword({ email, password, passwordConfirmation, token }) {
            this.isLoading = true

            await axios.post(`/api/reset-password/${token}`, {
                email: email,
                password: password,
                password_confirmation: passwordConfirmation
            })
                .then(response => {
                    if (response.data.success && response.data.data) {
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async getPasswordResetUrl(userEmail, userId) {
            this.passwordResetLoading[userId] = true

            await axios.post('/api/forget-password', { email: userEmail })
                .then(response => {
                    if (response.data.success && response.data.data) {
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.passwordResetLoading[userId] = false)
        },

        async activeStatusUserSwitcher(userId) {
            this.activeStatusLoading[userId] = true

            await axios.put(`api/user/${userId}/disable`)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        const index = this.users.findIndex(doctor => doctor.id === userId)
                        this.users[index].active = !this.users[index].active
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.activeStatusLoading[userId] = false)
        },

        updateUserInStore(userUpdatedData) {
            const index = this.users.findIndex(user => user.id === userUpdatedData.id)
            this.users[index] = { ...this.users[index], ...userUpdatedData }
        },

        resetState() {
            this.roles = []
            this.users = []
        }
    }
})
