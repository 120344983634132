<template>
  <div class="card mb-3 order-2 order-sm-1">
    <div class="card-header">
      Оформление заказа
    </div>
    <div class="card-body">
      <h5 class="card-title">Общая стоимость:</h5>

      <p class="card-text" v-if="totalOrderPrice">{{ totalOrderPrice }} &#8381;</p>
      <p class="card-text" v-if="cartStore.FormattedAnalyzes.length">{{ totalPrice }} &#8381;</p>
      <p class="card-text" v-if="!cartStore.FormattedAnalyzes.length">- &#8381;</p>

      <button
          :disabled="!(!orderStore.blockButtonCreateApplication)"
          type="button"
          v-if="authStore.isDoctor && $route.path === '/checkout'"
          class="btn btn-success w-100 mb-3"
          @click="saveOrder()"
      >
        Оформить заказ
      </button>
      <router-link
          :to="{name: 'Checkout'}"
          v-if="authStore.isDoctor && $route.path === '/order-items' && cartStore.state.assays.length"
          class="btn btn-success w-100 mb-3">
        Продолжить оформление
      </router-link>
      <p class="price-text">Стоимость согласно базовому прайсу.</p>
        <p class="price-text">Окончательная стоимость может отличатся от вышеуказанной.</p>
      <button v-if="$route.path === '/checkout'" type="button" class="btn btn-link p-0 text-decoration-none"
              @click="$router.push('/order-items')">Назад к списку
      </button>
    </div>
  </div>
</template>

<script setup>
import { useOrderStore } from '@/stores/OrderStore'
import { computed, ref, watchEffect } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import { userCartStore } from '@/stores/CartStore'

const cartStore = userCartStore()
const orderStore = useOrderStore()
const authStore = useAuthStore()

const saveOrder = () => {
  orderStore.blockButtonCreateApplication = true
  orderStore.assaysInCart = cartStore.state.assays.flatMap(item => Array.from({ length: item.amount }, () => item.assay))
  orderStore.createOrder(cartStore.state.personalData)
}
const assaysInCart = ref(null)
const processingOfPersonalData = ref(false)
const totalOrderPrice = computed(() => {
  return assaysInCart.value
      ? assaysInCart.value.reduce((total, assay) => {
        const price = assay.price
        return total + parseFloat(price)
      }, 0)
      : 0
})
watchEffect(() => assaysInCart.value = orderStore.assaysInCart)

const totalPrice = computed(() => {
  const analyzes = cartStore.FormattedAnalyzes
  let price = 0
  analyzes.forEach(element => {
    price += (element.price * element.amount)
  })
  return price
})
</script>

<style lang="scss" scoped>
.spinner-border {
  width: 16px;
  height: 16px;
}
.price-text{
  font-size: 12px;
  color: #919191; margin-bottom: 2px; text-align: center;
}
</style>
