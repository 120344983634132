<template>

  <Navbar class="container-nav" />
  <main id="main">
    <div class="wrapper-rout" :class="['container', containerClass]">
      <RouterView/>
      <div  v-if="isLoading" class="spinner-loading">
        <img src="@/assets/images/logo.svg" class="icon-logo" alt="">
        <div class="spinner-border text-secondary size-spinner" role="status">
          <span class="visually-hidden">Загрузка...</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import Navbar from '@/components/Global/Navbar.vue'
import { debounce } from 'lodash'
import { userCartStore } from '@/stores/CartStore'
import { getAnalyzes } from '@/api/Analyzes'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { useAuthStore } from '@/stores/AuthStore'

const isLoading = ref(false)
const analyzesStore = useAnalyzesStore()
const cartStore = userCartStore()
const route = useRoute()

const authStore = useAuthStore()
const showScrollUp = ref(false)
const containerClass = computed(() => {
  const componentName = route.name
  if (!componentName) return ''
  const kebabCaseName = componentName.split(/(?=[A-Z])/).map(word => word.toLowerCase()).join('-')
  return `${kebabCaseName}-container`
})

onMounted(() => {
  isLoading.value = true
  if (!authStore.isManager) {
    getAnalyzes().then(() => {
      isLoading.value = false
    }).catch(() => isLoading.value = false)
  } else {
    isLoading.value = false
  }
})

</script>

<style lang="scss" scoped>
.wrapper-rout{
  top: 50px;
  position: relative;
}
.container-nav{
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
}
.icon-logo {
  width: 250px;
  height: 250px;
}

.size-spinner {
  width: 50px;
  height: 50px;
}

.spinner-loading {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ebe9e9;
  z-index: 1001;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-leave-active {
  transition: opacity 0s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  visibility: hidden;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
  visibility: visible;
}

.card-error {
  color: black;
  background-color: orange;
  font-size: x-large;
  text-align: center;
}

#btn-back-to-top {
  position: fixed;
  top: calc(100vh - 7%);
  right: 20px;
  display: block;
  z-index: 1000;
}
</style>
