<template>
    <div class="modal fade" id="editDoctorModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered ">
            <form class="modal-content" @submit.prevent="editDoctor">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Редактирование доктора</h1>
                    <button type="button" class="btn-close" @click="hideEditDoctorModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="lastName">
                      <label for="lastName">Фамилия</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="firstName">
                        <label for="firstName">Имя</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="middleName" placeholder="Отчество" v-model="middleName">
                        <label for="middleName">Отчество</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="email" placeholder="Почта" v-model="email">
                        <label for="email">Почта</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="tel" class="form-control" id="phone" placeholder="Телефон" v-maska data-maska="+7 (###) ###-##-##" v-model="phone">
                        <label for="phone">Телефон</label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideEditDoctorModal">Закрыть</button>
                    <button type="submit"
                            class="btn btn-primary position-relative"
                            :class="{'pe-none': doctorsStore.isLoading}"
                    >
                        Сохранить изменения
                        <span v-if="doctorsStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { useDoctorsStore } from '@/stores/DoctorsStore'
    import { defineProps, inject, onMounted, ref, watchEffect } from 'vue'
    import { Modal } from 'bootstrap'
    import { vMaska } from 'maska'
    import { showErrorNotification } from '@/services/notifications'

    const doctorsStore = useDoctorsStore()

    const props = defineProps(['doctorDetails'])
    const editDoctorModal = inject('editDoctorModal')

    const firstName = ref(null)
    const middleName = ref(null)
    const lastName = ref(null)
    const email = ref(null)
    const phone = ref(null)

    watchEffect(() => {
        if (props.doctorDetails) {
            firstName.value = props.doctorDetails.first_name ?? null
            middleName.value = props.doctorDetails.middle_name ?? null
            lastName.value = props.doctorDetails.last_name ?? null
            email.value = props.doctorDetails.email ?? null
            phone.value = props.doctorDetails.phone ?? null
        }
    })

    const editDoctor = () => {
        const editedData = {
            first_name: firstName.value,
            middle_name: middleName.value,
            last_name: lastName.value,
            email: email.value,
            phone: phone.value
        }

        doctorsStore.editDoctorById(props.doctorDetails.id, editedData)
            .then(() => hideEditDoctorModal())
            .catch(error => showErrorNotification(error))
    }

    const hideEditDoctorModal = () => editDoctorModal.value.hide()

    onMounted(() => {
        editDoctorModal.value = new Modal('#editDoctorModal')
    })
</script>
