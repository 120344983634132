<template>
    <div class="modal fade" id="editUserModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <form class="modal-content" @submit.prevent="editUser">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Редактирование пользователя</h1>
                    <button type="button" class="btn-close" @click="hideEditUserModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body">

                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="lastName">
                    <label for="lastName">Фамилия</label>
                  </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="firstName">
                        <label for="firstName">Имя</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="middleName" placeholder="Отчество" v-model="middleName">
                        <label for="middleName">Отчество</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="emailAddress" placeholder="Электронная почта" v-model="email">
                        <label for="emailAddress">Электронная почта</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="phoneNumber" placeholder="Телефон" v-maska data-maska="+7 (###) ###-##-##" v-model="phone">
                        <label for="phoneNumber">Телефон</label>
                    </div>

                    <div class="form-floating mb-3">
                        <select @click="clearIdManager" class="form-select" id="userRoles" v-model="role">
                            <option disabled value="null">Выберите роль пользователя</option>
                            <option v-for="role in usersStore.roles" :key="role.id" :value="role.name">
                                {{ role.title }}
                            </option>
                        </select>
                        <label for="userRoles">Роли</label>
                    </div>
                    <div v-if="role === 'staff'" class="form-floating ">
                        <select class="form-select" id="userRoles" v-model="idManager">
                            <option disabled value="null">Выберите топ менеджера</option>
                            <option v-for="manager in topManager.managersInCreate" :key="manager.id" :value="manager.id">
                                {{ manager.lastname }} {{ manager.firstname }} {{ manager.middlename }}
                            </option>
                        </select>
                        <label for="userRoles">Региональный менеджеры</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideEditUserModal">Закрыть</button>
                    <button type="submit"
                            class="btn btn-primary position-relative"
                            :class="{'pe-none': usersStore.isLoading}"
                    >
                        Сохранить
                        <span v-if="usersStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted, ref, watchEffect } from 'vue'
    import { Modal } from 'bootstrap'
    import { useUsersStore } from '@/stores/UsersStore'
    import { vMaska } from 'maska'
    import { getTopManager } from '@/api/TopManager'
    import { useTopManager } from '@/stores/TopManager'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'

    const topManager = useTopManager()
    const usersStore = useUsersStore()

    const props = defineProps(['userDetails'])
    const editUserModal = inject('editUserModal')

    const firstName = ref(null)
    const middleName = ref(null)
    const lastName = ref(null)
    const email = ref(null)
    const phone = ref(null)
    const role = ref(null)
    const idManager = ref(null)
    const clearIdManager = () => {
        idManager.value = null
    }
    watchEffect(() => {
        if (props.userDetails) {
            firstName.value = props.userDetails.first_name ?? null
            middleName.value = props.userDetails.middle_name ?? null
            lastName.value = props.userDetails.last_name ?? null
            email.value = props.userDetails.email ?? null
            phone.value = props.userDetails.phone ?? null
            role.value = props.userDetails.role ?? null
            idManager.value = props.userDetails.manager_id ?? null
        }
    })

    const editUser = () => {
        const editedData = {
            first_name: firstName.value,
            middle_name: middleName.value,
            last_name: lastName.value,
            email: email.value,
            role: role.value
        }
        if (phone.value) {
          editedData.phone = phone.value
        }
        if (middleName.value) {
          editedData.middle_name = middleName.value
        }
        if (role.value === 'staff' && idManager.value !== 'null') {
            editedData.manager_id = idManager.value
        }
          const messages = [
            'Введите фамилию',
            'Введите имя',
            'Введите email',
            'Выберите роль'
          ]
          const values = [lastName.value, firstName.value, email.value, role.value]
          for (let i = 0; i < values.length; i++) {
            if (!values[i]) {
              showErrorNotification(messages[i])
              return
            }
          }
        usersStore.editUserById(props.userDetails.id, editedData)
            .then(() => {
                hideEditUserModal()
                getTopManager()
            }
        )
    }

    const hideEditUserModal = () => editUserModal.value.hide()

    onMounted(() => {
        getTopManager()
        usersStore.fetchRoles()
        editUserModal.value = new Modal('#editUserModal')
    })
</script>
