import { Notyf } from '@olegopro/notyf'

const notyf = new Notyf({
    position: { x: 'right', y: 'top' },
    duration: 1500,

    types: [
        {
            type: 'error',
            icon: {
                className: 'bi bi-exclamation-circle',
                tagName: 'i',
                color: 'white'
            },

            duration: 3000
        },
        {
            type: 'success'
            // duration: 3000
        }
    ]
})

export const showSuccessNotification = message => notyf.success(message)
export const showErrorNotification = message => notyf.error(message)

export default notyf
