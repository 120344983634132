<template>
    <div id="top-element"></div>
      <table class="mb-4 w-100">
          <tr>
            <th colspan="4" class="p-0">
              <div class="table-actions p-2">
                <div
                  class="input-container"
                >
                  <div class="search-client">
                    <input
                      type="text"
                      class="form-control"
                      v-model="searchOrderQuery"
                      placeholder="Поиск по ФИО пациента"
                      @input="setSearch(searchOrderQuery)"
                    />
                    <div
                      v-if="searchOrderQuery !== ''"
                      class="clear-button"
                      @click="clearSearchResult"
                    ></div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-5 col-6">
                    <label for="endDateOrder" class="date-picker-label"
                      >Дата начала
                      <input
                        class="date-picker form-control"
                        type="date"
                        id="startDateOrder"
                        placeholder="Дата начала"
                        min="2012-01-01"
                        @input="setStartDate(startDate)"
                        :max="getTodayDate()"
                        v-model="startDate"
                      />
                      <div
                        v-if="startDate"
                        @click="clearStartResult()"
                        class="clear"
                      >
                        +
                      </div>
                    </label>
                  </div>
                  <div class="col-md-5 col-6">
                    <label for="endDateOrder" class="date-picker-label mb-0"
                      >Дата окончания
                      <input
                        class="date-picker form-control"
                        type="date"
                        id="endDateOrder"
                        placeholder="Дата окончания"
                        min="2012-01-01"
                        @input="setEndDate(endDateOrder)"
                        :max="getTodayDate()"
                        v-model="endDateOrder"
                      />
                      <div
                        v-if="endDateOrder"
                        @click="clearEndResult()"
                        class="clear"
                      >
                        +
                      </div>
                    </label>
                  </div>
                  <div class="col-md-auto search-order">
                    <button
                      :disabled="
                        !checkSearch() || orderStore.isLoadingOrderGetter
                      "
                      type="button"
                      class="btn add-to-order btn-secondary float-end"
                      @click="searchOrder()"
                    >
                      Поиск
                    </button>
                  </div>
                  <div class="clear-search-order"
                  >
                    <button
                      :disabled="
                        checkSearch() || orderStore.isLoadingOrderGetter
                      "
                      type="button"
                      class="btn btn-secondary search-btn"
                      @click="clearSearch()"
                    >
                      Очистить
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
      </table>
      <!-- :style="{ height: !isMobile ? 'calc(100vh - 324px)' : 'auto' }" -->
      <div
         
      >
        <table class="w-100 table table-hover">
          <tbody >
            <div   v-if="
              !orderStore.stateOrders.length && !orderStore.isLoadingOrderGetter"
            class="text-center"
            
          >
            <p class="text-uppercase h4 no-order">Нет заказов</p>
          </div> 
           <LoadingSpinner
              v-if="orderStore.isLoadingOrderGetter"
              spinnerStyle="min-height: var(--ps-height);"
              :showSpinner="true"
            />
            <tr
              class="order-container"
              v-for="order in orderStore.stateOrders.length
                ? orderStore.stateOrders
                : []"
              :key="order.id"
              @click="showOrderModal(order)"
              data-bs-toggle="collapse"
              :data-bs-target="`#completedOrders-collapseTwo-${order.id}`"
            >
              <td  class="container-time" >
                <span class="text-wrap">
                   {{ formatDate(order?.created_datetime) }}
                </span>
              </td>
              <td>
                <span class="my-3 my-sm-0 w-50 mobile-font-small">
                  <b style="max-width: min-content;">
                    {{ order.patientLastName }}
                    {{ order.patientFirstName?.charAt(0) }}.
                    {{ order?.patientMiddleName?.charAt(0) }}<span v-if="order?.patientMiddleName?.charAt(0)">.</span>
                  </b>
                </span>
              </td>
              <td class="d-none d-sm-table-cell text-order" >
                <span  data-toggle="tooltip" data-placement="top" title="Номер заказа" class="font-weight-bold w-100 d-none d-sm-block">{{ order.number }}</span>
              </td>
              <td class="d-none d-sm-table-cell badge-order">
                <div
                  class="d-sm-flex d-none justify-content-center align-items-center w-100 position-relative"
                >
                  <AnalyzesStatus
                    :order="order.lines"
                    :type="order.orderState"
                    class="d-inline"
                  />
                </div>
              </td>
              <td class="total-price">
                <div
                  class="d-flex mt-0 mt-sm-0 justify-content-end align-items-center w-100 mobile-font-small"
                >
                  <span
                    ><b class="d-none d-sm-inline ms-0 ms-sm-3"> &nbsp;</b
                    >{{ order.totalPrice }} &#8381;</span
                  >
                </div>
              </td>
              <td
                  class="d-sm-none d-table position-relative"
                >
                  <AnalyzesStatus
                    :order="order.lines"
                    :type="order.orderState"
                    class="d-inline"
                  />
            </td>
            </tr>
            <div ref="loadMoreTrigger" id="loading-trigger">
              <span class="trigger-loading"></span>
            </div>
          </tbody>
        </table>
      </div>
      <DeleteOrderModal :orderDetails="orderDetails" />
      <InfoOrderModal :orderDetails="orderDetails" />
</template>
<script setup>
import { useOrderStore } from '@/stores/OrderStore'
import { onMounted, ref, provide, computed, onBeforeUnmount } from 'vue'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import {
  formatUnixTimestampDateAndTime,
  formatUnixTimestampOnlyDate,
  stateAnalyzes, formatDateHelper, formatDate
} from '@/helpers/dateHelpers'
import DeleteOrderModal from '@/components/Profile/Modals/DeleteOrderModal.vue'
import InfoOrderModal from '@/components/Profile/Modals/InfoOrderModal.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import AnalyzesStatus from '@/components/Profile/Badges/AnalyzesStatus.vue'
import { useAuthStore } from '@/stores/AuthStore'

const orderStore = useOrderStore()
const analyzesStore = useAnalyzesStore()
const authStore = useAuthStore()
const isMobile = ref(window.innerWidth <= 575.98)
const editOrderModal = ref(null)
const deleteOrderModal = ref(null)
const orderDetails = ref(null)
const renderOrder = ref(false)
const infoOrderModal = ref(null)
const startDate = ref('')
const endDateOrder = ref('')
const startDateApplication = ref('')
const endDateApplication = ref('')
const searchOrderQuery = ref('')
const searchApplicationsQuery = ref('')
const loadMoreTrigger = ref(null)
const setSearch = (search) => {
  orderStore.searchQueryOrder = search
}
const setStartDate = (startDate) => {
  orderStore.dateFrom = startDate
}
const setEndDate = (endDate) => {
  orderStore.dateTo = endDate
}
const checkSearch = () => {
  if (endDateOrder.value && endDateOrder.value !== '') {
    return true
  }
  if (startDate.value && startDate.value !== '') {
    return true
  }
  if (searchOrderQuery.value.length >= 2) {
    return true
  }
  return false
}
const checkClearSearch = () => {
  if (endDateOrder.value !== '') {
    return true
  }
  if (startDate.value !== '') {
    return true
  }
  if (searchOrderQuery.value.length < 2) {
    return true
  }
  return false
}
const getTodayDate = () => {
  const today = new Date().toISOString().split('T')[0]
  return today
}
const searchOrder = () => {
  orderStore.setDates(
    1,
    startDate.value,
    endDateOrder.value,
    searchOrderQuery.value
  )
  orderStore.getProcessedOrders()
}
const clearSearch = () => {
  startDate.value = ''
  orderStore.dateFrom = ''
  searchOrderQuery.value = ''
  orderStore.searchQueryOrder = ''
  endDateOrder.value = ''
  orderStore.dateTo = ''
  orderStore.setDates(
    1,
    startDate.value,
    endDateOrder.value,
    searchOrderQuery.value
  )
  orderStore.getProcessedOrders()
}
const clearStartResult = () => {
  startDate.value = ''
  orderStore.dateFrom = ''
}
const clearSearchResult = () => {
  searchOrderQuery.value = ''
  orderStore.searchQueryOrder = ''
}
const clearEndResult = () => {
  endDateOrder.value = ''
  orderStore.dateTo = ''
}

const isLoadingMoreData = ref(false)
const loadMoreData = () => {
  if (!isLoadingMoreData.value) {
    isLoadingMoreData.value = true
    orderStore.getProcessedOrders().finally(() => {
      isLoadingMoreData.value = false
    })
  }
}
//
provide('editOrderModal', editOrderModal)
provide('deleteOrderModal', deleteOrderModal)
provide('infoOrderModal', infoOrderModal)
const showOrderModal = (order) => {
  orderDetails.value = order
  infoOrderModal.value.show()
}
const showDeleteOrderModal = () => deleteOrderModal.value.show()
onMounted(() => {
  orderStore.searchQueryOrder
    ? (searchOrderQuery.value = orderStore.searchQueryOrder)
    : (searchOrderQuery.value = '')
  orderStore.dateTo
    ? (endDateOrder.value = orderStore.dateTo)
    : (endDateOrder.value = null)
  orderStore.dateFrom
    ? (startDate.value = orderStore.dateFrom)
    : (startDate.value = null)
  const options = {
    rootMargin: '0px 0px 0px 0px',
    threshold: 0
  }
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      loadMoreData()
    }
  }, options)

  const loadingTriggerElement = loadMoreTrigger.value
  if (loadingTriggerElement) {
    observer.observe(loadingTriggerElement)
  }
})
</script>
<style scoped lang="scss">
.search-order {
  margin-left: 5px;
  padding: 0;
  align-self: flex-end;
  @media (max-width: 575.98px) {
    margin-left: 0;
    display: flex;
    position: relative;
    width: 75px;
    margin-top: 20px;
    margin-left: 10px;
  }
}

.no-order {
  color: #b0b0b0;
    width: 100%;
    align-content: center;
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color),
    2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}
.not-ps {
  height: auto;
  box-shadow: 0 0 0 1px var(--bs-border-color),
    2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

.accordion-button {
  &:after {
    margin-left: 0;

    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.accordion-main-style {
  button.accordion-button {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.tab-menu {
  display: flex;
  justify-content: space-between;
}

.tab-menu button {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tab-menu button.active {
  background-color: #149cdd;
  color: white; // Цвет текста на активном табе
}

.clear-button {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: transparent;
}

.clear-button::before,
.clear-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 2px;
  background-color: black;
}

.clear-button::before {
  transform: rotate(45deg);
}

.search-btn{
  width: 115px
}

.clear-button::after {
  transform: rotate(-45deg);
}
.container-time{
  width: 100px;
    padding-left: 13px;
    @media (max-width: 575.98px) { 
    font-size: 13px;
    padding-left: 0;
    }
}
.order-container{
  width: 100%;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  z-index: 1009;
  @media (max-width: 575.98px) { 
    height: 60px;
        display: table-row;
        vertical-align: middle;
    }
}
.input-container{
  margin-bottom: 10px;
                    position: relative;
                    display: flex;
                    margin-top: 20px;
}
.search-client{
  flex: 1
}
.clear-search-order{
  width: 115px; align-self: flex-end; margin-left: 5px
}
.text-order{
  width: 150px
}
.badge-order{
  width: 100px
}
.total-price{
  width: 120px; 
  padding-right: 20px;
  @media (max-width: 575.98px) { 
    padding-right: 0
  }
}
.trigger-loading{
  width: 100%; height: 40px
}
</style>
