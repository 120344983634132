<template>
    <h2 class="accordion-header ">
      <button style="word-break: break-all;" class="accordion-button collapsed btn-color" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#completedOrders-collapseTwo-' + props.favoriteList.id" aria-expanded="false">
        <span class="my-2 my-sm-0 me-2 w-100"><b class="d-sm-inline text-black"><i :class="`${props.favoriteList.isGlobal ? 'bi bi-star-fill class-icon' : 'no-icon'}`"></i>{{ props.favoriteList.name }}</b></span>
      </button>
    </h2>

    <div :id="`completedOrders-collapseTwo-${props.favoriteList.id}`" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="row analyzes-search mb-3">
          <div class="col-12">
            <p class="mb-2"><b>Поиск анализов</b></p>
            <div>

              <div class="input-container " style=" margin-bottom: 10px; position: relative; display: flex; margin-top: 20px;">
    <div style="flex: 1;">
        <div style="position: relative;">
            <input 
                type="text" 
                class="form-control w-100" 
                v-model="searchQueries" 
                placeholder="Поиск по названию или коду анализа" 
            />
            <div  v-show="searchQueries.length" class="clear-button" @click="searchQueries = ''">
            </div>
        </div> </div>
      </div>
            </div>
          </div>
        </div>
        <template v-if="props.favoriteList.analyzes.length">
          <template v-if="parseInt(props.favoriteList.type) === 1">
            <div class="container-analyze-btn">
              <p>Список анализов:</p>
              <button v-if="filterAll.length " class="btn btn-primary btn-sm btn-card-save"
                      @click="addAnalyzesCodesToCart(props.favoriteList.analyzes)">
                <i class="bi bi-plus-circle"/>
                Добавить в корзину
              </button>
            </div>
            <ul class="list-group mb-3 analyzes-added-list">
              <li class="d-flex justify-content-between align-items-center list-group-item"
                  v-for="analysisCode in filterAll" :key="analysisCode.id">
                <code class="me-2">
                  <code class="me-0 me-sm-5">  {{
                      analyzesStore.analyzes.filter(item => item.code === String(analysisCode.analyze_code))[0]?.code
                    }} </code>
                </code>
                <span class="overflow-hidden me-auto">{{
                    analyzesStore.analyzes.filter(item => item.code === String(analysisCode.analyze_code))[0]?.name
                  }}</span>
              </li>
              <div v-if="!filterAll.length">Анализы не найдены</div>
            </ul>
          </template>
          <template v-else>
            <div class="container-analyze-btn">
              <p>Список анализов мужчины:</p>
              <button v-if="filterMale.length" class="btn btn-primary btn-sm btn-card-save"
                      @click="addAnalyzesCodesToCart(props.favoriteList.analyzes.filter(item => parseInt(item.gender) === 1))">
                <i class="bi bi-plus-circle"/>
                Добавить в корзину
              </button>
            </div>

            <ul class="list-group mb-3 analyzes-added-list">
              <li class="d-flex justify-content-between align-items-center list-group-item"
                  v-for="analysisCode in filterMale"
                  :key="analysisCode.id">
                <code class="me-2">
                  <code class="me-0 me-sm-5"> {{
                      analyzesStore.analyzes.filter(item => item.code === String(analysisCode.analyze_code))[0]?.code
                    }} </code>
                </code>
                <span class="overflow-hidden me-auto">{{
                    analyzesStore.analyzes.filter(item => item.code === String(analysisCode.analyze_code))[0]?.name
                  }}</span>
              </li>
              <div v-if="!filterMale.length">Анализы не найдены</div>
            </ul>

            <div class="container-analyze-btn">
              <p>Список анализов женщины:</p>
              <button v-if="filterFemale.length" class="btn btn-primary btn-sm btn-card-save"
                      @click="addAnalyzesCodesToCart(props.favoriteList.analyzes.filter(item => parseInt(item.gender) === 2))">
                <i class="bi bi-plus-circle"/>
                Добавить в корзину
              </button>
            </div>

            <ul class="list-group mb-3 analyzes-added-list">
              <li class="d-flex justify-content-between align-items-center list-group-item"
                  v-for="analysisCode in filterFemale"
                  :key="analysisCode.id">
                <code class="me-2">
                  <code class="me-0 me-sm-5"> {{
                      analyzesStore.analyzes.filter(item => item.code === String(analysisCode.analyze_code))[0]?.code
                    }} </code>
                </code>
                <span class="overflow-hidden me-auto">{{
                    analyzesStore.analyzes.filter(item => item.code === String(analysisCode.analyze_code))[0]?.name
                  }}</span>
              </li>
              <div v-if="!filterFemale.length">Анализы не найдены</div>
            </ul>

          </template>
        </template>
        <div class="d-flex flex-wrap justify-content-between gap-2 gap-sm-0">
          <button :disabled='props.favoriteList.isGlobal && authStore.isDoctor'  
          class="btn btn-success btn-sm me-0 me-sm-2 flex-grow-1 flex-sm-grow-0"
                  @click="editFavoriteList(props.favoriteList)">
            <i class="bi bi-floppy"/>
            Изменить список
          </button>
          <!-- :disabled='props.favoriteList.doctor_id === 0 && authStore.isChiefDoctor' -->
          <button :disabled='props.favoriteList.isGlobal && authStore.isDoctor'  
           class="btn btn-danger btn-sm" @click="showDeleteFavoriteListModal(props.favoriteList.id)">
            <i class="bi bi-trash3"/>
            Удалить список
          </button>
        </div>
      </div>
    </div>
</template>
<script setup>
import { computed, provide, ref, defineEmits } from 'vue'
import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { showSuccessNotification } from '@/services/notifications'
import { userCartStore } from '@/stores/CartStore'
import router from '@/router'
import { useAuthStore } from '@/stores/AuthStore'
const authStore = useAuthStore()
const favoriteListsStore = useFavoriteListsStore()
const cartStore = userCartStore()
const analyzesStore = useAnalyzesStore()
const props = defineProps(['favoriteList'])
const searchQueries = ref('')
const emit = defineEmits(['deleteModal'])
const showDeleteFavoriteListModal = (id) => {
  emit('deleteModal', id)
}
const filterAll = computed(() => {
  if (searchQueries.value.length) {
    return props.favoriteList.analyzes.filter((item) => item.gender === '3' && item.analyze_code.includes(searchQueries.value))
  } else {
   return props.favoriteList.analyzes
  }
})
const filterMale = computed(() => {
  if (searchQueries.value.length) {
    return props.favoriteList.analyzes.filter((item) => item.gender === '1' && item.analyze_code.includes(searchQueries.value))
  } else {
    return props.favoriteList.analyzes.filter((item) => item.gender === '1')
  }
})
const filterFemale = computed(() => {
  if (searchQueries.value.length) {
    return props.favoriteList.analyzes.filter((item) => item.gender === '2' && item.analyze_code.includes(searchQueries.value))
  } else {
    return props.favoriteList.analyzes.filter((item) => item.gender === '2')
  }
})

const showCreateFavoriteListModal = () => {
  router.push({ name: 'CreateFavoriteListModal' })
}
const addAnalyzesCodesToCart = analyzesIds => {
  analyzesIds.forEach(code => {
    cartStore.setAssaysAmount(code.analyze_code, 1)
  })
  showSuccessNotification('Ваш список добавлен в заказ')
}
const editFavoriteList = (list) => {
  favoriteListsStore.listId = list.id
  favoriteListsStore.listName = list.name
  favoriteListsStore.gender = parseInt(list.type)
  if (parseInt(list.type) === 1) {
    favoriteListsStore.selectedAnalyzes.all = list.analyzes.map(item => parseInt(item.analyze_code))
  } else {
    favoriteListsStore.selectedAnalyzes.male = list.analyzes.filter(item => parseInt(item.gender) === 1).map(item => parseInt(item.analyze_code))
    favoriteListsStore.selectedAnalyzes.female = list.analyzes.filter(item => parseInt(item.gender) === 2).map(item => parseInt(item.analyze_code))
  }

  router.push({ name: 'GlobalCreateFavoriteListModal' })
}
</script>

<style scoped lang="scss">
.clear-search{
  position: absolute;
  position: absolute;
  right: 34px;
  top: 36px;
  height: 20px;
  width: 20px;
  padding: 0px 0px 3px;
  justify-content: center;
  line-height: 5px;
  border-radius: 5px;
  align-items: center;
  z-index: 100;
  display: flex;
  flex-wrap: nowrap;
  font-size: 25px;
  font-family: monospace;
  color: #333;
  transform: rotate(45deg);
  cursor: pointer;

}
.text-black{
  color: black
}
.btn-color{
  background-color: #f1f1f1;
  margin-bottom: 5px;
}
.container-analyze-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 10px;

  p {
    padding: 0;
    margin:0;
    font-weight: bold;
  }
}

.btn-card-save {
  margin-left: auto;
}
.clear-button {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: transparent;
}

.clear-button::before,
.clear-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
    background-color: black;
}

.clear-button::before {
    transform: rotate(45deg);
}

.clear-button::after {
    transform: rotate(-45deg);
}
.class-icon{
  margin-right:10px
}
.class-icon{
  margin-right:10px;
  display: inline-flex;
}
.no-icon{
  margin-right:26px
}
</style>
