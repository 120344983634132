<template>
  <div class="branch-details" v-if="branch">
    <p><strong>Адрес: </strong>
<span>{{ branch.address }}</span></p>
    <p><strong>Населённый пункт: </strong><span>{{ branch.settlement.type }} {{ branch.settlement.name }}</span></p>
   
       <div style="text-align: center;" class="work-times">
            <h5>Режим работы:</h5>
            <div v-if="branch.workTimes.length === 0">
                Время не указано
            </div> 
            <div>
            <ul>
              <li v-for="time in sortedWorkTimes" :key="time.id">
                <strong>{{ getDayName(time.day_of_week) }}:</strong>
                {{ time.work_from }} - {{ time.work_to }}
              </li>
            </ul>
    </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  branch: Object
})

const sortedWorkTimes = computed(() => {
  return props.branch?.workTimes?.slice().sort((a, b) => a.day_of_week - b.day_of_week)
})

const getDayName = (day) => {
  const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
  return days[day % 7]
}
</script>

<style scoped>
.branch-details {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.work-times ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.work-times li {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .branch-details p {
        display: block; 
    }
}
@media only screen and (max-width: 768px) {
    .branch-details  {
      margin-bottom: 0;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: white;
      padding: 10px;
      border-radius: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
}
@media only screen and (max-width: 768px) {
    .branch-details p span {
      margin-bottom: 0;
      display: block; /* Переносит адрес на новую строку */
      margin-top: 5px; /* Добавляет отступ сверху */
}
}
@media only screen and (max-width: 768px) {
    .work-times {
      margin-bottom: 0;
        font-size: 14px; /* Уменьшает размер шрифта */
        margin-top: 20px; /* Уменьшает отступ сверху */
    }
}
@media only screen and (max-width: 600px) {
    .branch-details strong:nth-child(1) {
        display: none; /* Скрывает второй <p> элемент (с адресом) на мобильных устройствах */
    }
}
</style>
