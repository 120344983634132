<template>
  <div class="modal fade" id="addPatient" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered container-change-patient">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"><span v-if="!orderStore.checkSavePatient"
                                                                    class="d-none d-sm-inline">Создание пациента: </span>
            <span v-else class="d-none d-sm-inline">Изменение пациента: </span>
            <b></b></h1>
          <button type="button" class="btn-close" @click="hideDeleteModal" aria-label="Close"></button>

        </div>
        <div>
          <div class="modal-body">
            <div class="row patient-data">
              <div class="col-12 col-sm-6">
                <div :class="{'error-red': (orderStore.inputErorrPatient && !orderStore.dataPatient.lastname)}"
                     class="form-floating mb-3">
                  <input type="text" class="form-control" id="lastname" placeholder="Фамилия"
                         v-model="orderStore.dataPatient.lastname">
                  <label for="lastname">Фамилия<span class="text-danger">*</span></label>
                </div>

                <div :class="{'error-red': (orderStore.inputErorrPatient && !orderStore.dataPatient.firstname)}"
                     class="form-floating mb-3">
                  <input type="text" class="form-control" id="firstname" placeholder="Имя*"
                         v-model="orderStore.dataPatient.firstname">
                  <label for="firstname">Имя<span class="text-danger">*</span></label>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="middlename" placeholder="Отчество"
                         v-model="orderStore.dataPatient.middlename">
                  <label for="middlename">Отчество<span class="text-danger">*</span></label>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating mb-3">
                      <select :class="{'error-red': (orderStore.inputErorrPatient && !orderStore.dataPatient.gender)}"
                              class="form-select" id="userGender" v-model="orderStore.dataPatient.gender">
                        <option disabled value="null">Выберите пол</option>
                        <option value="1">Мужской</option>
                        <option value="2">Женский</option>
                      </select>
                      <label for="userRoles">Пол<span class="text-danger">*</span></label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-floating mb-3">
                      <input :class="{'error-red': (orderStore.inputErorrPatient && !orderStore.dataPatient.birthday)}"
                             type="date" min="1950-01-01" :max="getTodayDate()" class="form-control" id="birthDate"
                             placeholder="День рождения" v-model="orderStore.dataPatient.birthday">
                      <label for="birthDate">Дата рождения<span class="text-danger">*</span></label>
                    </div>
                  </div>
                </div>
                <div :class="{'error-red': (orderStore.inputErorrPatient && !orderStore.dataPatient.phone)}"
                     class="form-floating mb-3">
                  <input type="tel" class="form-control" id="phone" placeholder="Телефон"
                         v-model="orderStore.dataPatient.phone" v-maska data-maska="+7 (9##) ###-##-##">
                  <label for="phoneNumber">Телефон<span class="text-danger">*</span></label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="email" placeholder="email"
                         v-model="orderStore.dataPatient.email">
                  <label for="email">Email</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideDeleteModal">Закрыть</button>
            <button :disabled="isLoadingSave" v-if="!orderStore.checkSavePatient" type="button" class="btn btn-primary"
                    @click="savePatient()">
              Сохранить
              <div v-if="isLoadingSave" class="spinner-border text-secondary loading-size"
                   :class="{'small' : smallSpinner}" role="status">
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
            <button v-else :disabled="isLoadingSave" type="button" class="btn btn-primary"
                    @click="chengePatient(orderStore.dataPatient)">Изменить
              <div v-if="isLoadingSave" class="spinner-border text-secondary loading-size"
                   :class="{'small' : smallSpinner}" role="status">
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject, onMounted, ref, computed } from 'vue'
import axios from '@/services/axios'
import { useOrderStore } from '@/stores/OrderStore'
import { Modal } from 'bootstrap'
import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate } from '@/helpers/dateHelpers'
import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import { vMaska } from 'maska'

const firstName = ref(null)
const middleName = ref(null)
const lastName = ref(null)
const birthdate = ref(null)
const gender = ref(null)
const phone = ref(null)
const orderStore = useOrderStore()
const props = defineProps(['addPatient'])
const addPatients = inject('addPatient')
const isLoadingSave = ref(false)
const hideDeleteModal = () => addPatients.value.hide()
const getTodayDate = () => {
  const today = new Date().toISOString().split('T')[0]
  return today
}
const chengePatient = (patient) => {
  isLoadingSave.value = true
  const formData = {
    firstname: orderStore.dataPatient.firstname,
    middlename: orderStore.dataPatient.middlename,
    lastname: orderStore.dataPatient.lastname,
    phone: orderStore.dataPatient.phone,
    gender: orderStore.dataPatient.gender,
    birthday: orderStore.dataPatient.birthday,
    email: orderStore.dataPatient.email
  }
  if (!formData.email || formData.email === '') {
    delete formData.email
  }
  if (formData.phone) {
    const formattedNumber = formData.phone.replace(/\D/g, '') // Удаляем все нецифровые символы
    if (formattedNumber.startsWith('7')) {
      formData.phone = formattedNumber.slice(0) // Если номер начинается с 7, удаляем первую цифру
    }
  }
  let count = 0
  for (const key in formData) {
    if (!formData[key]) {
      count++
    }
  }
  if (count > 0) {
    orderStore.inputErorrPatient = true
    isLoadingSave.value = false
    return showErrorNotification('Не все поля заполнены')
  }
  orderStore.inputErorrPatient = false
  axios.patch(`api/doctor/patients/${orderStore.dataPatient.id}`, formData)
      .then((response) => {
        if (response.data.success && response.data.data) {
          orderStore.getPatientOrders()
          showSuccessNotification('Пациент успешно изменен')
          hideDeleteModal()
          isLoadingSave.value = false
        } else if (response.data.message) {
          showErrorNotification(response.data.message)
        } else {
          showErrorNotification('Ошибка сервера')
        }
      }).catch(error => {
        if (error.response.data.message) {
          showErrorNotification(error.response.data.message)
        } else {
          showErrorNotification('Ошибка сервера')
        }
      }
  )
}
const savePatient = async () => {
  isLoadingSave.value = true
  const formData = {
    firstname: orderStore.dataPatient.firstname,
    middlename: orderStore.dataPatient.middlename,
    lastname: orderStore.dataPatient.lastname,
    phone: orderStore.dataPatient.phone,
    gender: orderStore.dataPatient.gender,
    birthday: orderStore.dataPatient.birthday,
    email: orderStore.dataPatient.email
  }
  if (!formData.email || formData.email === '') {
    delete formData.email
  }
  if (formData.phone) {
    const formattedNumber = formData.phone.replace(/\D/g, '') // Удаляем все нецифровые символы
    if (formattedNumber.startsWith('7')) {
      formData.phone = formattedNumber.slice(0) // Если номер начинается с 7, удаляем первую цифру
    }
  }
  let count = 0
  for (const key in formData) {
    if (!formData[key]) {
      count++
    }
  }
  if (count > 0) {
    orderStore.inputErorrPatient = true
    isLoadingSave.value = false
    return showErrorNotification('Не все поля заполнены')
  }
  orderStore.inputErorrPatient = false
  addPatients.value.hide()
  await axios.post('api/doctor/patients', formData)
      .then((response) => {
        if (response.data.success && response.data.data) {
          orderStore.getPatientOrders()
          showSuccessNotification('Пациент успешно создан')
          addPatients.value.hide()
          isLoadingSave.value = false
        } else if (response.data.message) {
          showErrorNotification(response.data.message)
        } else {
          showErrorNotification('Ошибка сервера')
        }
      })
      .catch(error => {
        if (error.response.data.message) {
          showErrorNotification(error.response.data.message)
          isLoadingSave.value = false
        } else {
          showErrorNotification('Ошибка сервера')
          isLoadingSave.value = false
        }
      })
}

onMounted(() => {
  orderStore.inputErorrPatient = false
  firstName.value = null
  middleName.value = null
  lastName.value = null
  birthdate.value = null
  gender.value = null
  phone.value = null
  addPatients.value = new Modal('#addPatient')
})
</script>
<style scoped>
@media (max-width: 576px) {
  .list-group-item {
    display: flex;
    flex-direction: column;
  }
}

.error-red {
  border-radius: 5px;
  border: 1px solid rgba(255, 0, 0, 0.501)
}

.container-change-patient {
  --bs-modal-width: 1140px;
}

.loading-size {
  width: 20px;
  height: 20px;
}
</style>
