<template>
  <div class="col-12 mb-0 mb-sm-4">
    <div class="input-group" @keyup.enter="handleEnterKey">
      <div class="form-floating">
        <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="doctorsStore.lastName">
        <label for="lastName">Фамилия</label>
      </div>
      <div class="form-floating">
        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="doctorsStore.firstName">
        <label for="firstName">Имя</label>
      </div>

      <div class="form-floating">
        <input type="text" class="form-control" id="middleName" placeholder="Отчество"
               v-model="doctorsStore.middleName">
        <label for="middleName">Отчество</label>
      </div>
      <div class="form-floating">
        <input type="tel" class="form-control" id="phone" placeholder="Телефон" v-maska data-maska="+7 (###) ###-##-##"
               v-model="doctorsStore.phone">
        <label for="phone">Телефон</label>
      </div>

      <div class="form-floating">
        <input type="date" class="form-control" id="updated_after" placeholder="Дата обновление"
               v-model="doctorsStore.updated_after" data-bs-toggle="tooltip" data-bs-placement="top"
               data-bs-title="Дата последнего обновление записи доктора">
        <label for="updated_after">Дата обновление</label>
      </div>

      <div class="form-floating">
        <select class="form-select" id="onlyApproved" v-model="doctorsStore.onlyApproved">
          <option value="1">Подтвержденных</option>
          <option value="0">Не подтвержденных</option>
        </select>
        <label for="onlyApproved">Показывать</label>
      </div>

      <button class="btn btn-success position-relative" @click="doctorsStore.searchByQueryFromGenesis()"
              :disabled="isAnyFieldEmpty" type="button">
        Поиск
        <span v-if="doctorsStore.isLoadingSearchQuery" class="placeholder-glow placeholder-surface">
                        <span class="placeholder"/>
                    </span>
      </button>
    </div>
  </div>

  <div class="col-12 mt-3 mt-sm-0" v-if="doctorsStore.searchResult">

    <div class="card w-100" v-for="doctor in doctorsStore.searchResult" :key="doctor.id">
      <div class="card-body">
        <h4 class="card-title">{{ doctor.firstName }} {{ doctor.middleName }} {{ doctor.lastName }}</h4>
        <p class="card-text mb-1"><b>Специальность:</b> {{ doctor.specialty }}</p>
        <p class="card-text mb-1"><b>Город:</b> {{ doctor.city }}</p>
        <p class="card-text mb-1"><b>Клиника:</b> {{ doctor.clinic }}</p>
        <p class="card-text mb-1"><b>Телефон:</b> {{ doctor.phone ?? 'Не заполнен' }}</p>
        <p class="card-text mb-0"><b>Профиль обновлен:</b> {{ formattedUpdatedAt(doctor.updatedAt) }}</p>

        <div class="mt-3">
          <button type="button" class="btn btn-primary btn-sm" @click="openModalWithId(doctor.id)">
            <i class="bi bi-plus-circle me-1"/>
            Добавить врача
          </button>
        </div>
      </div>
    </div>
      
  </div>

  <teleport to="body">
    <AddDoctorModal :selectedDoctorId="selectedDoctorId"/>
  </teleport>

</template>

<script setup>
import { useDoctorsStore } from '@/stores/DoctorsStore'
import { vMaska } from 'maska'
import AddDoctorModal from '@/components/Doctors/Modals/AddDoctorModal.vue'
import { computed, provide, ref } from 'vue'

const doctorsStore = useDoctorsStore()

const addDoctorModal = ref(null)
const selectedDoctorId = ref(null)

provide('addDoctorModal', addDoctorModal)

const showAddDoctorModal = () => addDoctorModal.value.show()

const openModalWithId = (id) => {
  selectedDoctorId.value = id
  showAddDoctorModal()
}

const formattedUpdatedAt = updatedAt => {
  if (updatedAt) {
    const date = new Date(updatedAt * 1000)
    return date.toLocaleDateString()
  }

  return 'Не заполнено'
}

const handleEnterKey = () => {
  if ([doctorsStore.firstName, doctorsStore.middleName, doctorsStore.lastName].some(field => field && field.length >= 1)) {
    doctorsStore.searchByQueryFromGenesis()
  }
}

const isAnyFieldEmpty = computed(() => {
  return ![doctorsStore.firstName, doctorsStore.middleName, doctorsStore.lastName, doctorsStore.phone].some(field => field && field.length > 0)
})
</script>

<style lang="scss" scoped>
.ps {
  max-height: var(--ps-height);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);

  .card {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    &.last-card {
      border-bottom: 1px solid transparent;
    }
  }
}

.input-group {
  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  .form-floating {
    @media (max-width: 575.98px) {
      margin-left: 0 !important;
      width: 100%;
      border-bottom: none;
    }

    .form-control {
      @media (max-width: 575.98px) {
        border-bottom: none;
        position: relative;

        &:focus {
          border-color: var(--bs-border-color);
        }
      }

    }

    &:first-child {
      input.form-control {
        @media (max-width: 575.98px) {
          border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
        }
      }
    }

    select.form-select {
      @media (max-width: 575.98px) {
        border-radius: 0;
      }
    }
  }

  button {
    border-color: var(--bs-border-color);

    @media (max-width: 575.98px) {
      margin-top: -1px;
      margin-right: calc(var(--bs-border-width) * -1);
      border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius) !important;
    }

    .placeholder {
      border-top-right-radius: var(--bs-border-radius);
      border-bottom-right-radius: var(--bs-border-radius);
    }

  }
}
</style>
