<template>
    <div class="modal fade" id="createManagerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <form class="modal-content" @submit.prevent="createManager">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Создание менеджера</h1>
                    <button type="button" class="btn-close" @click="hideCreateManagerModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="managersStore.lastName">
                    <label for="lastName">Фамилия</label>
                  </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="managersStore.firstName">
                        <label for="firstName">Имя</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="middleName" placeholder="Отчество" v-model="managersStore.middleName">
                        <label for="middleName">Отчество</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="emailAddress" placeholder="Электронная почта" v-model="managersStore.emailAddress">
                        <label for="emailAddress">Электронная почта</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="tel" class="form-control" id="phoneNumber" placeholder="Телефон" v-maska data-maska="+7 (###) ###-##-##" v-model="managersStore.phoneNumber">
                        <label for="phoneNumber">Телефон</label>
                    </div>
                    <div  class="form-floating ">
                        <select class="form-select" id="userRoles" v-model="managersStore.idManager">
                            <option disabled value="null">Выберите топ менеджера</option>
                            <option v-for="manager in topManager.managersInCreate" :key="manager.id" :value="manager.id">
                                {{ manager.lastname }} {{ manager.firstname }} {{ manager.middlename }}
                            </option>
                        </select>
                        <label for="userRoles">Региональный менеджер</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideCreateManagerModal">Закрыть</button>
                    <button type="submit" class="btn btn-primary position-relative">
                        Сохранить
                        <span v-if="managersStore.isLoading" class="placeholder-glow placeholder-surface">
                        <span class="placeholder" />
                    </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { inject, onMounted } from 'vue'
    import { Modal } from 'bootstrap'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
    import { useManagersStore } from '@/stores/ManagersStore'
    import { vMaska } from 'maska'
    import { getTopManager } from '@/api/TopManager'
    import { useTopManager } from '@/stores/TopManager'

    const topManager = useTopManager()
    const managersStore = useManagersStore()

    const createManagerModal = inject('createManagerModal')

    const hideCreateManagerModal = () => createManagerModal.value.hide()

    const checkEmail = (email) => {
            const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
            return emailRegex.test(email)
    }

    const createManager = () => {
        if (managersStore.isLoading) return
      const messages = [
        'Введите фамилию',
        'Введите имя',
        'Введите email',
        'Выберите роль'
      ]

      const values = [managersStore.lastName, managersStore.firstName, managersStore.emailAddress, managersStore.idManager]
      for (let i = 0; i < values.length; i++) {
        if (!values[i]) {
          showErrorNotification(messages[i])
          return
        }
      }
        if (!checkEmail(managersStore.emailAddress)) {
            return showErrorNotification('Неправильно введен email')
        }

        managersStore.createManager()
            .then(data => {
                 if (data) {
                  managersStore.firstName = null
                  managersStore.middleName = null
                  managersStore.lastName = null
                  managersStore.emailAddress = null
                  managersStore.phoneNumber = null
                  managersStore.selectedRole = null
                  managersStore.doctorsByManager = []
                  managersStore.idManager = null
                  hideCreateManagerModal()
                  getTopManager()
                }
            })
    }

    onMounted(() => {
        createManagerModal.value = new Modal('#createManagerModal')
        getTopManager()
    })
</script>
