<template>
    <div class="row my-4 align-items-center header-wrapper">
        <div class="col-12 d-flex justify-content-between align-items-center w-100">
            <div class="d-flex justify-content-between align-items-end">
                <h1 class="text-uppercase h3 my-1">
                    <i class="bi bi-heart-pulse fs-4 me-2" />
                    <span v-html="headerText" />
                </h1>
            </div>

            <div class="d-flex justify-content-end">
                <button v-if="!authStore.isAdmin" type="button"
                        class="btn genesis-search-button"
                        :class="isGenesisRoute ? 'btn-secondary' : 'btn-success'"
                        @click="showGenesisSearch"
                >
                    <i v-if="isGenesisRoute" class="bi bi-arrow-left-circle me-0 me-sm-2" />
                    <i v-else class="bi bi-search me-2" />

                    <span v-html="buttonText" />
                </button>
            </div>
        </div>
    </div>

    <div class="row mb-3 mb-sm-0">
        <RouterView />
    </div>

</template>

<script setup>
    import { computed, watchEffect } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useAuthStore } from '@/stores/AuthStore'

    const authStore = useAuthStore()
    const router = useRouter()
    const route = useRoute()

    const showGenesisSearch = () => router.push({ name: (route.name === 'DoctorsGenesis') ? 'DoctorsList' : 'DoctorsGenesis' })
    const isGenesisRoute = computed(() => route.name === 'DoctorsGenesis')

    const headerText = computed(() => {
        return isGenesisRoute.value
            ? 'Поиск <span class="d-none d-sm-inline">врачей</span> по Genesis'
            : 'Врачи'
    })

    const buttonText = computed(() => {
        return !isGenesisRoute.value
            ? 'Поиск по Genesis'
            : '<span class="d-none d-sm-inline">Назад к списку</span>'
    })

    watchEffect(() => {
        const rootStyle = document.documentElement.style
        rootStyle.setProperty('--nav-bar-height', isGenesisRoute.value ? '138px' : null)
    })
</script>

<style lang="scss" scoped>
    .header-wrapper{
        display: flex;
        justify-content: space-between;

        .col-12 {
            button.genesis-search-button {
                width: fit-content;

                @media (max-width: 575.98px) {
                    padding: 0.15rem 0.5rem;
                }

                @media (min-width: 576px) {
                    width: 188px;
                }
            }
        }
    }
</style>
