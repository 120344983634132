import { useTopManager } from '@/stores/TopManager'
import axios from '@/services/axios'
import { showErrorNotification } from '@/services/notifications'

export async function getManagers() {
    const topManager = useTopManager()
    try {
        topManager.isLoadingDoctor = true
        const result = await axios.get('api/user/top-manager/staff').finally(() => topManager.isLoadingDoctor = false)
        if (result.data.success && result.data.data) {
            topManager.managers = result.data.data
        } else if (result.data.message) {
            topManager.isLoadingDoctor = false
            return showErrorNotification(result.data.message)
        } else {
            topManager.isLoadingDoctor = false
            return showErrorNotification('Ошибка сервера')
        }
    } catch (error) {
        if (error.response.data.message) {
            topManager.isLoadingDoctor = false
            showErrorNotification(error.response.data.message)
        } else {
            topManager.isLoadingDoctor = false
            showErrorNotification('Ошибка сервера')
        }
    } 
}

export async function getTopManager() {
    const topManager = useTopManager()
    try {
        const result = await axios.get('api/user/top-managers')

        if (result.data.success && result.data.data) {
            topManager.managersInCreate = result.data.data
        } else if (result.data.message) {
            return showErrorNotification(result.data.message)
        } else {
            return showErrorNotification('Ошибка сервера')
        }
    } catch (error) {
        if (error.response.data.message) {
            showErrorNotification(error.response.data.message)
        } else {
            showErrorNotification('Ошибка сервера')
        }
    } 
}
