<template>
  <div class="mt-3">
    <div>
      <div id="top-element"></div>
      <h1 style="display: inline-block" class="modal-title fs-5">
        <template v-if="favoriteListsStore.listId">Измнение списка</template>
        <template v-else>Создание списка</template>
      </h1>
    </div>
    <div class="row content-container">
      <div class="d-sm-block col-12 col-sm-12 col-md-4 col-lg-3">
        <div class="form-floating mb-3 mt-3">
          <input maxlength="75" type="text" class="form-control" id="firstName" placeholder="Имя"
                 v-model="favoriteListsStore.listName">
          <label for="firstName">Название списка</label>
        </div>
        <div class="mt-3 ">
          <select class="form-select" id="userRoles" v-model="favoriteListsStore.gender" @change="openModalChange">
            <option selected :value="1">
              Все
            </option>
            <option :value="2">
              Раздельный
            </option>
          </select>
        </div>
        <div class="mt-5">
          <button type="button" class="btn mr-3 btn-secondary me-sm-3 me-3" @click="hideCreateFavoriteListModal">Закрыть
          </button>
          <button v-if="!favoriteListsStore.listId" type="submit"
                  :disabled="!favoriteListsStore.isloadingList"
                  class="btn btn-primary position-relative " @click="createListStore()">
            {{  'Сохранить' }}
          </button>
          <button v-else type="submit" :disabled="!favoriteListsStore.isloadingList"
                  class="btn btn-primary position-relative " @click="updateList">
            Сохранить
          </button>
        </div>
      </div>
      <div class="d-sm-block col-12 col-sm-12 col-md-8 col-lg-9">
        <FavoriteAnalyzeForm :type="favoriteListsStore.gender"/>
      </div>
      <LoadingSpinner v-if="!favoriteListsStore.isloadingList"
                      spinnerStyle="min-height: var(--ps-height);"
                      :showSpinner="true"
      />
    </div>
    <ChangeTypeAnalyzeModal @changeAnalyze="changeAnalyze"/>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, provide } from 'vue'
import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
import AddFavoriteAnalyze from './AddFavoriteAnalyze.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { createList, updateList } from '@/api/FavoriteAnalyzes'
import { getCategories } from '@/api/Categories'
import { getAllOrgans } from '@/api/Organs'
import FavoriteAnalyzeForm from '@/components/FavoriteAnalyzes/FavoriteAnalyzeForm.vue'
import router from '@/router'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import ChangeTypeAnalyzeModal from './modals/ChangeTypeAnalyzeModal.vue'

const changeTypeAnalyzeModal = ref(null)
provide('changeTypeAnalyzeModal', changeTypeAnalyzeModal)
const openModalChange = () => {
  if (favoriteListsStore.selectedAnalyzes.all.length || favoriteListsStore.selectedAnalyzes.male.length ||
      favoriteListsStore.selectedAnalyzes.female.length) {
        changeTypeAnalyzeModal.value.show()
  }
}
const changeAnalyze = () => {
  if (favoriteListsStore.gender === 2 && favoriteListsStore.selectedAnalyzes.all.length) {
    favoriteListsStore.selectedAnalyzes.male = []
    favoriteListsStore.selectedAnalyzes.female = []
    favoriteListsStore.selectedAnalyzes.all.forEach(item => {
      if (item) {
        favoriteListsStore.selectedAnalyzes.male.push(item)
        favoriteListsStore.selectedAnalyzes.female.push(item)
      }
    })
  }
  if (favoriteListsStore.gender === 2 && !favoriteListsStore.selectedAnalyzes.all.length) {
    favoriteListsStore.selectedAnalyzes.male = []
    favoriteListsStore.selectedAnalyzes.female = []
  }
  if (favoriteListsStore.gender === 1 && (favoriteListsStore.selectedAnalyzes.male.length || favoriteListsStore.selectedAnalyzes.female.length)) {
    const favoriteMale = []
    const favoriteFemale = []
    favoriteListsStore.selectedAnalyzes.all = []
    favoriteListsStore.selectedAnalyzes.male.forEach(item => {
      if (item) {
        favoriteMale.push(item)
      }
    })
    favoriteListsStore.selectedAnalyzes.female.forEach(item => {
      if (item) {
        favoriteFemale.push(item)
      }
    })
    const combined = Array.from(new Set([...favoriteMale, ...favoriteFemale]))
    favoriteListsStore.selectedAnalyzes.all = combined
  }
  if (favoriteListsStore.gender === 1 && (!favoriteListsStore.selectedAnalyzes.male.length && !favoriteListsStore.selectedAnalyzes.female.length)) {
    favoriteListsStore.selectedAnalyzes.all = []
  }
}

const favoriteListsStore = useFavoriteListsStore()
const createFavoriteListModal = inject('createFavoriFteListModal')
const analyzesStore = useAnalyzesStore()
const setAssaysAmountFavorite = (assay) => {
  const index = favoriteListsStore.selectedAnalyzes.all.findIndex(item => item.assay === assay.assay)
  if (index === -1) {
    favoriteListsStore.selectedAnalyzes.all.push({
      assay: assay.code,
      name: assay.name,
      price: assay.price
    })
  } else {
    favoriteListsStore.selectedAnalyzes.all.splice(index, 1)
  }
}
const createListStore = () => {
  favoriteListsStore.isloadingList = false
  createList()
}
onMounted(() => {
  getCategories()
  getAllOrgans()
})
const hideCreateFavoriteListModal = () => {
  router.push({ name: 'FavoriteLists' })
}

</script>
