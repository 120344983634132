<template>
    <h1>Главная страница лендинг</h1>
</template>

<script setup>

</script>

<style scoped lang="scss">

</style>
