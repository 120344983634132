<template>
    <div class="modal fade" id="infoOrderModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered container-info ">
            <div class="modal-content">
                <div class="modal-header pb-0">
                <div>
                     <button class="accordion-button collapsed" type="button"  aria-expanded="false">
                    <div class="d-flex flex-column">
                        
                        <span class="my-2 my-sm-0 h5"><b>{{ orderDetails?.patientLastName }} {{ orderDetails?.patientFirstName }} {{ orderDetails?.patientMiddleName }} </b></span>
                        <span>{{ formatDate(orderDetails?.created_datetime) }} </span>            
                    </div>
                </button> 
                </div>
                <div class="float-end order-badge" v-if="orderDetails?.lines">
                    <AnalyzesStatus class="check-badge"  :order="orderDetails?.lines" :type="orderDetails?.orderState"/>
                    <button :disabled="orderStore.renderSpinnerAllPdf || orderStore.spinnerLoadingPdf" v-if="orderDetails.uniqueCode" type="button" class="btn btn-primary print-document" @click="printOneDocument(orderDetails)">
                        <div  :disabled="orderStore.renderSpinnerAllPdf">
                         Просмотр результатов<div v-if="orderStore.renderSpinnerAllPdf" class="spinner-border text-secondary btn-container" :class="{'small' : smallSpinner}" role="status">
                          <span class="visually-hidden">Загрузка...</span>
                        </div>
                    </div>

                </button> 
                </div>
                <div class="d-flex flex-column"> 
                    <button  type="button" class="btn-close close-button" @click="hideDeleteOrderModal" aria-label="Close"></button>
                </div>
            </div>
                <div class="modal-body mt-1" v-if="orderDetails">
                                <p><b>День рождения: </b>{{ orderDetails?.patientBirthDate ? formatUnixTimestampOnlyDate(orderDetails?.patientBirthDate) : 'Не указан' }}</p>
                                <p><b>Телефон: </b><span > {{ phoneMask(orderDetails?.patientPhone) }}</span></p>
                                <p><b>Пол: </b>{{ orderDetails?.gender === 'Male' ? 'Мужской' : 'Женский' }}</p>
                                <p><b>Почта: </b>{{ orderDetails?.patientEmail ?? 'Не указана' }}</p>
                    <div class="d-md-flex d-flex justify-content-between align-items-center mb-2"> 
                        <p class="mb-0"><b>Список анализов:</b></p> 
                        <span><b class="d-none d-sm-inline">Итого:&nbsp;</b>{{ orderDetails?.totalPrice }} &#8381;</span>
                    </div>                      
                    <ul class="list-group mb-3"> 
                        <li class="list-group-item" v-for="assay in orderDetails.lines" :key="assay.id"> 
                            <div class="d-md-flex justify-content-between align-items-center"> 
                                <div class="container-assay d-flex d-sm-none row">  
                                    <div class="row col row-container-print">
                                        <div class="col-12 row-container-print">
                                    {{ assay.assayName }}
                                   </div>
                                    <div class="col-12 d-flex justify-content-between row-container-print">
                                        <code class="me-2 code-assay">{{ assay.assayCode }}</code> 
                                        <span class="d-block d-md-inline ms-3 ml-3 price-assay">{{ assay?.price }} &#8381;</span>
                                    </div>
                                </div>
                                <div v-if="assay.assayCode !== '1012' && assay.assayCode !== '1012.1' && assay.assayCode !== '1010' && assay.documentId?.length >= 5"  class="d-block d-sm-none col-2 align-self-center">
                                    <div v-if="assay.assayCode !== '1012' && assay.assayCode !== '1012.1' && assay.assayCode !== '1010' && assay.documentId?.length >= 5" class="ms-2 d-block d-md-none justify-content-end" >
                                    <button :disabled="orderStore.spinnerLoadingPdf || orderStore.renderSpinnerAllPdf" @click="printDocument(assay)" type="button" class="btn btn-sm add-to-order btn-primary ml-5 btn-print"><i class="bi bi-filetype-pdf"></i>    
                                    <div  v-if="orderStore.spinnerLoadingPdf && assay.id === assayId" class=" btn-container spinner-border text-secondary" :class="{'small' : smallSpinner}" role="status">
                                        <span class="visually-hidden">Загрузка...</span>
                                    </div> 
                                </button>
                                </div>
                                </div>
                                </div>  
                                <div class="container-assay d-none d-sm-flex">  
                                    <code class="me-2 code-assay">{{ assay.assayCode }}</code> 
                                    {{ assay.assayName }}   
                                </div>  
                                <div class="ms-auto price-container" >
                                    <span class="d-none d-md-inline ms-3 ml-3 price-assay">{{ assay?.price }} &#8381; </span>
                                </div> 
                                <div  v-if="assay.assayCode !== '1012' && assay.assayCode !== '1012.1' && assay.assayCode !== '1010'" class="d-block d-md-inline ms-3 ml-3"> 
                                    <AnalyzeStatus :type="assay.state == 5 && (assay.documentId?.length >= 5) ? 9 : assay.state" /> 
                                </div> 
                                <div v-if="assay.assayCode === '1012' || assay.assayCode === '1012.1' || assay.assayCode === '1010'" class="d-block d-md-inline ms-3 ml-3"> 
                                    <AnalyzeStatus :type="10" />
                                </div> 
                                <div v-if="assay.assayCode !== '1012' && assay.assayCode !== '1012.1' && assay.assayCode !== '1010' && assay.documentId?.length >= 5" class="ms-2 d-none d-md-flex justify-content-end" >
                                    <button :disabled="orderStore.spinnerLoadingPdf || orderStore.renderSpinnerAllPdf" @click="printDocument(assay)" type="button" class="btn btn-sm add-to-order btn-primary ml-5"><i class="bi bi-filetype-pdf"></i>    
                                    <div  v-if="orderStore.spinnerLoadingPdf && assay.id === assayId" class=" btn-container spinner-border text-secondary" :class="{'small' : smallSpinner}" role="status">
                                        <span class="visually-hidden">Загрузка...</span>
                                    </div> 
                                </button>
                                </div>
                                <div v-else class='container-assays'></div>
                            </div> 
                        </li> 
                    </ul>
                <p><b> Уникальный номер: </b>{{ orderDetails?.uniqueCode }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideDeleteOrderModal" aria-label="Close">Закрыть</button> 
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted, ref, computed } from 'vue'
    import { useOrderStore } from '@/stores/OrderStore'
    import { Modal } from 'bootstrap'
    import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate, formatDateHelper, formatDate } from '@/helpers/dateHelpers'
    import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
    import AnalyzeStatus from '@/components/Profile/Badges/AnalyzeStatus.vue'
    import AnalyzesStatus from '@/components/Profile/Badges/AnalyzesStatus.vue'
    import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
    import maska from 'maska' 
    const orderStore = useOrderStore()
    const props = defineProps(['orderDetails'])
    const infoOrderModal = inject('infoOrderModal')
    const assayId = ref(null)
    const printDocument = (orderDetails) => {
        orderStore.printDocument(orderDetails) 
        assayId.value = orderDetails.id
    }
    const printOneDocument = (orderDetails) => {
        orderStore.printOneDocument(orderDetails) 
    }
    const phoneMask = (phone) => {
    if (phone) {
        const x = phone
            .replace(/\D/g, '')
            .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
        x[1] = '+7'
        phone = !x[3]
            ? x[1] + '(' + x[2]
            : x[1] +
              '(' +
              x[2] +
              ')' +
              x[3] +
              (x[4] ? '-' + x[4] : '') +
              (x[5] ? '-' + x[5] : '')
    }
    return phone
}
    const deleteOrder = () => orderStore.deleteOrderById(props.orderDetails)
        .then(() => hideDeleteOrderModal())

    const hideDeleteOrderModal = () => infoOrderModal.value.hide()

    onMounted(() => infoOrderModal.value = new Modal('#infoOrderModal'))
</script>
<style lang="scss" scoped>
@media (max-width: 576px) { 
    .list-group-item { 
        display: flex; 
        flex-direction: column; 
        place-content: center;
    } 
}
.order-badge{
    position: absolute;
    right: 60px;
    top: 15px;
        @media (max-width: 576px) { 
            position: static;
        } 
}
.check-badge{
    border-bottom-right-radius: 5px;
    position: absolute;
    right: 220px;
    top: 10px;
    @media (max-width: 576px) { 
        position: absolute;
        top: 0;
        left: 0;
        font-size: small;
        font-weight: 500;
        border-bottom-left-radius: 0px;
        }
}
.close-button{
    margin-bottom: 20px;
    @media (max-width: 576px) { 
        position: absolute;
        top: 10px;
        right: 10px;
        }
}
.price-container{
    width: 260px;
    display: flex;
    justify-content: flex-end;
}
.container-assay{
    @media (max-width: 576px) { 
        word-break: break-all;
    padding-top: 15px;
        }
  
}
.print-document{
    font-size: smaller;
    @media (max-width: 576px) { 
        width: 95px;
        top: 10px;
        display: flex;
        align-content: flex-start;
        position: relative;
        font-size: 12px;
        }
}
.code-assay{
    display: flex;
    align-items: center;
    @media (max-width: 576px) { 
        font-size: 14px;
    }
}
.container-info{
    --bs-modal-width: 1140px;
}
.btn-container{
    width: 20px;
    height: 20px;
}
.price-assay{
    margin-right: 10px; 
    width: max-content;
    @media (max-width: 576px) { 
         margin-right: 0;
    }
}
.container-assays{
    margin-left: 43px;
}
.btn-print {
    height: 50px;
}
// .row > * {
//     flex-shrink: 0;
//     width: 100%;
//     max-width: 100%;
//      padding-right: 0;
//     padding-left: calc(var(--bs-gutter-x)* 0.5);
//     margin-top: var(--bs-gutter-y);
// }
.row-container-print{
    padding-right: 0 !important;
}
</style>
