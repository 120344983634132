<template>
    <div>
        <h1 class="display-1">404</h1>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>
