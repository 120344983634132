<template> 
<div class="col-sm-6 d-flex align-items-center mt-3">
            <h2 class="text-uppercase h4 m-0 mt-3"> <i class="bi bi-person-vcard fs-4 me-2" />Отчет о заказах</h2>
        </div>

</template>

<script setup>

</script>

<style scoped lang="scss">

</style>
