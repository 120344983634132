<template>
  <div class="row my-4 align-items-center">
    <div class="col-12 d-flex align-items-center">
      <h1 class="text-uppercase h3 my-1"><i class="bi bi-person-video2 fs-4 me-2"></i>Личный кабинет</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-3 mb-4 mb-sm-0 menu-wrapper">
      <div class="list-group list-group-main-style">
        <router-link :to="{name: 'Manager'}" v-if="authStore.isDoctor" custom
                     v-slot="{ navigate, isActive, isExactActive }">
          <button
              class="list-group-item list-group-item-action d-flex align-items-center"
              :class="{ 'active': isActive || isExactActive }"
              @click="navigate"
          >
            <i class="bi bi-person-fill-gear me-3 fs-5"/>
            Мой менеджер
          </button>
        </router-link>

        <!-- <router-link :to="{name: 'FavoriteLists'}" v-if="authStore.isDoctor" custom v-slot="{ navigate, isActive, isExactActive }">
            <button
                class="list-group-item list-group-item-action d-flex align-items-center"
                :class="{ 'active': isActive || isExactActive }"
                @click="navigate"
            >
                <i class="bi bi-list-stars me-3 fs-5" />
                Избранные списки
            </button>
        </router-link> -->

        <router-link :to="{name: 'PersonalData'}" custom v-slot="{ navigate, isActive, isExactActive }">
          <button
              class="list-group-item list-group-item-action d-flex align-items-center"
              :class="{ 'active': isActive || isExactActive }"
              @click="navigate"
          >
            <i class="bi bi-person-vcard me-3 fs-5"/>
            Личные данные
          </button>
        </router-link>

        <router-link :to="{name: 'Roles'}" v-if="authStore.isAdmin" custom
                     v-slot="{ navigate, isActive, isExactActive }">
          <button
              class="list-group-item list-group-item-action d-flex align-items-center"
              :class="{ 'active': isActive || isExactActive }"
              @click="navigate"
          >
            <i class="bi bi-shield-lock me-3 fs-5"/>
            Роли пользователей
          </button>
        </router-link>

        <router-link :to="{name: 'Users'}" v-if="authStore.isAdmin" custom
                     v-slot="{ navigate, isActive, isExactActive }">
          <button
              class="list-group-item list-group-item-action  d-flex align-items-center"
              :class="{ 'active': isActive || isExactActive }"
              @click="navigate"
          >
            <i class="bi bi-people me-3 fs-5"/>
            Все пользователи
          </button>
        </router-link>

      </div>
    </div>

    <div class="col-12 col-sm-9 content-wrapper" style="margin-top: -4px;">
      <RouterView/>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore'

const authStore = useAuthStore()
</script>

<style scoped lang="scss">
$ps-height: 100%;

.row {
  [class*="col-"] {
    min-height: fit-content;
    max-height: 80vh;

    .ps {
      position: relative;
      min-height: fit-content;
      max-height: $ps-height;
      border-radius: var(--bs-border-radius);
      box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
    }

    &.content-wrapper {
      @media (max-width: 575.98px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
