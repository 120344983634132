<template>
  <div class="row my-4 align-items-center">
    <div class="col-12">
      <h1 class="text-uppercase h3 my-1"><i class="bi bi-buildings fs-4 me-2"></i>Филиалы</h1>
    </div>
  </div>
  
  <div class="row mb-3 mb-sm-0">
    <!-- Карта -->
    <div class="col-12 order-md-last mb-3 mb-md-0">
      <div style="height: calc(100vh - 200px);" id="map" class="map">
        <div class="marker-address" :style="{ top: markerAddressTop + 'px', left: markerAddressLeft + 'px', opacity: showAddress ? 1 : 0 }" v-if="showAddress">
          {{ selectedBranch.address }}
        </div><BranchDetails :branch="selectedBranch" />
      </div>
      
    </div>
    
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useBranchesStore } from '@/stores/BranchesStore'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import { fromLonLat } from 'ol/proj'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { Style, Icon } from 'ol/style'
import BranchDetails from '@/components/Profile/BranchDetails.vue'

const branchesStore = useBranchesStore()
const map = ref(null)
const selectedBranch = ref(null)
const showAddress = ref(false)
const markerAddressTop = ref(0)
const markerAddressLeft = ref(0)

onMounted(() => {
  branchesStore.getAllBranches()
  initializeMap()
  addClickEventToMarkers()
  addHoverEffectToMarkers()
})

const selectBranch = (branch) => {
  selectedBranch.value = branch
}

const addHoverEffectToMarkers = () => {
  const mapElement = document.getElementById('map')
  mapElement.addEventListener('mousemove', (event) => {
    const pixel = map.value.getEventPixel(event)
    const feature = map.value.forEachFeatureAtPixel(pixel, (feature) => feature)
    if (feature) {
      const branch = feature.get('branch')
      if (branch) {
        const coordinates = map.value.getCoordinateFromPixel(pixel)
        const [x, y] = map.value.getPixelFromCoordinate(coordinates)
        showAddress.value = true
        selectedBranch.value = branch
        markerAddressTop.value = y - 30 // Смещение адреса над маркером
        markerAddressLeft.value = x
      }
    } else {
      showAddress.value = false
    }
  })
}

const addClickEventToMarkers = () => {
  const mapElement = document.getElementById('map')
  mapElement.addEventListener('click', (event) => {
    const pixel = map.value.getEventPixel(event)
    const feature = map.value.forEachFeatureAtPixel(pixel, (feature) => feature)
    if (feature) {
      const branch = feature.get('branch')
      if (branch) {
        const coordinates = map.value.getCoordinateFromPixel(pixel)
        map.value.getView().animate({ center: coordinates, duration: 500 })
        selectedBranch.value = branch
      }
    }
  })
}

const initializeMap = () => {
  const initialCoordinates = fromLonLat([38.97603, 45.04484])
  map.value = new Map({
    target: 'map',
    layers: [
      new TileLayer({
        source: new OSM()
      })
    ],
    view: new View({
      center: initialCoordinates,
      zoom: 12
    })
  })
}

watch(() => branchesStore.branches, (newBranches) => {
  if (newBranches && newBranches.length) {
    addAllBranchesToMap(newBranches)
  }
})

const addAllBranchesToMap = (branches) => {
  const features = branches.map(branch => {
    const coordinates = fromLonLat([branch.lon, branch.lat])
    const feature = new Feature({
      geometry: new Point(coordinates),
      branch: branch
    })
    return feature
  })

  const vectorSource = new VectorSource({
    features
  })

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
image: new Icon({
        anchor: [0.5, 1],
        src: 'https://cl-lab.info/static/images/marker.png',
        color: 'white'
      })
    })
  })

  map.value.addLayer(vectorLayer)
}
</script>

<style scoped lang="scss">
.ps {
  max-height: var(--ps-height);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

#map {
  width: 100%;
  height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
  position: relative; /* Добавлено для позиционирования адреса маркера */
}

.marker-address {
  cursor: pointer;
  position: absolute;
  top: 0; /* Изначально верхний край адреса будет на маркере */
  left: 50%; /* Положение адреса в центре маркера */
  transform: translate(-50%, -100%); /* Центрирование адреса относительно маркера */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0; /* По умолчанию адрес скрыт */
  transition: opacity 0.2s ease; /* Анимация появления и исчезания адреса */
}

.list-group-item.active {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.list-group-item:hover {
  cursor: pointer;
}
</style>
