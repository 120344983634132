<template>
    <div class="row my-4 align-items-center">
        <div class="col-12 d-flex justify-content-between align-items-center w-100">
            <div>
                <h1 class="text-uppercase h3 my-1"><i class="bi bi-person-lines-fill fs-4 me-2"></i>Менеджеры</h1>
            </div>

            <div>
                <button type="button" class="btn btn-primary add-manager-button" @click="showCreateManagerModal" style="margin-right: 3px">
                    <i class="bi bi-plus-circle me-1" />
                    <!--Добавить менеджера-->
                    Добавить
                </button>
            </div>
        </div>
    </div>

    <div class="row mb-3 mb-sm-0">
        <div class="col-12">
                <ul class="list-group list-group-main-style" v-if="managersStore.managers">
                    <li class="list-group-item p-3" v-for="manager in managersStore.managers" :key="manager.id">
                        <h4 class="mb-1 d-flex align-items-center">
                          {{ manager.last_name }} {{ manager.first_name }} {{ manager.middle_name }}  (#id - {{ manager.id }})
                            <ActiveStatus :type="manager.active" class="user-select-none" />
                        </h4>
                        <p class="mb-1"><b>Почта:</b> {{ manager.email }}</p>
                        <p class="mb-1"><b>Телефон:</b> {{ phoneMask(manager.phone) }}</p>
                        <p class="mb-1"><b>Активен:</b> {{ manager.active ? "Активен" : "Не активен" }}</p>

                        <div class="mt-3 row row-cols-2 row-cols-sm-1 g-1 buttons-wrapper">
                            <div class="col order-1 order-sm-1">
                                <button type="button"
                                        class="m-0 btn btn-sm btn-primary"
                                        @click="setManagerData(manager); showEditManagerModal()"
                                >
                                    <i class="bi bi-pencil-square" />
                                    Редактировать
                                </button>
                            </div>

                            <div class="col order-2 order-sm-2">
                                <button type="button"
                                        class="m-0 btn btn-sm btn-secondary position-relative"
                                        :class="{'btn-secondary': manager.active, 'btn-success': !manager.active, 'pe-none': managersStore.isLoading}"
                                        @click="managersStore.activeStatusManagerSwitcher(manager.id)"
                                >
                                    <i class="bi bi bi-shield-x" v-if="manager.active" />
                                    <i class="bi bi-shield-plus" v-if="!manager.active" />
                                    {{ manager.active ? 'Заблокировать' : 'Разблокировать' }}

                                    <span v-if="managersStore.activeStatusLoading[manager.id]" class="placeholder-glow placeholder-surface">
                                    <span class="placeholder" />
                                </span>
                                </button>
                            </div>

                            <div class="col order-4 order-sm-3">
                                <button type="button"
                                        class="m-0 btn btn-sm btn-outline-primary d-sm-n"
                                        @click="setManagerData(manager); showMovingDoctorsModal()"
                                        v-if="manager.doctors.length > 0">
                                    <i class="bi bi-arrow-left-right" />
                                    <span class="mx-1" v-html="`Перенести<span class='d-none d-sm-inline'>врачей</span>`" />
                                    <span class="badge rounded-pill text-bg-primary">{{ manager.doctors.length }}</span>
                                </button>
                            </div>

                            <div class="col order-3 order-sm-4">
                                <button v-if="manager.email"
                                        type="button"
                                        class="m-0 btn btn-sm btn-outline-danger position-relative"
                                        @click="managersStore.getPasswordResetUrl(manager.email, manager.id)"
                                >
                                    <i class="bi bi-arrow-clockwise"></i>
                                    Сбросить пароль

                                    <span v-if="managersStore.passwordResetLoading[manager.id]" class="placeholder-glow placeholder-surface">
                                    <span class="placeholder" />
                                </span>
                                </button>
                            </div>

                        </div>
                    </li>
                </ul>
          <div v-if="managersStore.managers.length === 0 && managersStore.isLoading" style="width: 100%;
    height: calc(100vh - 333px);">
            <LoadingSpinner wrapperStyle="top: 15%" :showSpinner="true"  spinnerStyle="z-index:100" v-if="managersStore.managers.length === 0" />
          </div>
        </div>
    </div>
    <CreateManagerModal />
    <EditManagerModal :managerDetails="managerDetails" />
    <MovingDoctorsModal :managerDetails="managerDetails" />

</template>

<script setup>
    import { useManagersStore } from '@/stores/ManagersStore'
    import { onMounted, ref, provide } from 'vue'

    import CreateManagerModal from '@/components/Managers/Modals/CreateManagerModal.vue'
    import EditManagerModal from '@/components/Managers/Modals/EditManagerModal.vue'
    import MovingDoctorsModal from '@/components/Managers/Modals/MovingDoctorsModal.vue'
    import ActiveStatus from '@/components/Profile/Badges/ActiveStatus.vue'
    import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'

    const managersStore = useManagersStore()

    const managerDetails = ref(null)
    const createManagerModal = ref(null)
    const editManagerModal = ref(null)
    const movingDoctorsModal = ref(null)

    provide('createManagerModal', createManagerModal)
    provide('editManagerModal', editManagerModal)
    provide('movingDoctorsModal', movingDoctorsModal)
    const phoneMask = (phone) => {
    if (phone) {
        const x = phone
            .replace(/\D/g, '')
            .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
        x[1] = '+7'
        phone = !x[3]
            ? x[1] + '(' + x[2]
            : x[1] +
              '(' +
              x[2] +
              ')' +
              x[3] +
              (x[4] ? '-' + x[4] : '') +
              (x[5] ? '-' + x[5] : '')
    }
    return phone
}
    const setManagerData = managerData => managerDetails.value = managerData
    const showCreateManagerModal = () => createManagerModal.value.show()
    const showEditManagerModal = () => editManagerModal.value.show()
    const showMovingDoctorsModal = () => movingDoctorsModal.value.show()

    onMounted(() => managersStore.fetchManagers())
</script>

<style lang="scss" scoped>
    .ps {
        max-height: var(--ps-height);
        box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
        border-radius: var(--bs-border-radius);
    }

    button.add-manager-button {
        width: fit-content;

        @media (max-width: 575.98px) {
            padding: 0.15rem 0.5rem;
        }

        @media (min-width: 576px) {
            width: 188px;
        }
    }

    .list-group {
        margin-bottom: 50px;
        .list-group-item {
            .buttons-wrapper {
                @media (min-width: 576px) {
                    flex-wrap: nowrap;
                }

                .col {
                    @media (min-width: 576px) {
                        width: fit-content;
                        flex-shrink: 1;
                    }

                    button {
                        @media (max-width: 575.98px) {
                            width: 100%;
                        }

                        //Минимальная ширина для текста кнопок в одну линию
                        @media (max-width: 390px) {
                            font-size: calc(var(--bs-btn-font-size) * 0.9);
                        }
                    }
                }
            }
        }
    }
</style>
