<template> 
        <div class="row content-container mt-3">
        </div><component  :is="topManager.tabManager === 'ManagerList' ? ManagerList : DoctorList"></component>
</template>

<script setup>
    import { onMounted, ref, provide } from 'vue'
    import { getManagers } from '@/api/TopManager'
    import { useTopManager } from '@/stores/TopManager'
    import ManagerList from '@/components/SuperManager/ManagerList.vue'
    import DoctorList from '@/components/SuperManager/DoctorList.vue'
    const topManager = useTopManager()
    const currentComponent = ref(null)
    onMounted(async () => {
        await getManagers()
    })
</script>

<style scoped lang="scss">
.message-error{
  text-align: center;
  margin-top: 20px;
}
    .ps {
        min-height: fit-content;
        max-height: calc(80vh - 104px);
        box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
        border-radius: var(--bs-border-radius);
    }
    .row {
  &.content-container {
    [class*="col-"] {
      min-height: fit-content;
      max-height: var(--ps-height);

      input {
        transition: 0.3s;

        &:focus {
          box-shadow: 2px 2px 12px 0 rgba(34, 60, 80, 0.06);
          transition: none;
        }
      }

      .ps {
        position: relative;
        max-height: var(--ps-height);
        border-radius: var(--bs-border-radius);
        box-shadow: 0 0 0 1px var(--bs-border-color),
          2px 2px 12px 0 rgba(34, 60, 80, 0.15);

        &:nth-child(2) {
          max-height: calc(var(--ps-height) - 54px);
        }

        .accordion {
          .accordion-item {
            .accordion-header {
              button.accordion-button {
                @media (max-width: 575.98px) {
                  &:after {
                    display: none;
                  }
                }

                .inner-content {
                  @media (max-width: 575.98px) {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 90px);
                    margin-right: 0;
                    gap: 1rem;

                    span {
                      &.price {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }

              button.add-to-order {
                @media (max-width: 575.98px) {
                  height: 100%;
                  top: 0;
                  right: 0;
                  transform: none;
                  border-radius: 0;
                }
              }
              button.add-to-order:hover{
                  background-color: #0dcaf0;
                  color: black;
                  opacity: 1;
                }
            }
          }
        }
      }
    }
  }
}
</style>
