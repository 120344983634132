<template>
    <main class="form-signin w-100 m-auto">
        <div class="form-login-wrapper">
            <h1 class="mb-3">Сброс пароля</h1>

            <form @submit.prevent="passwordReset">
                <div class="form-floating">
                    <input type="email" class="form-control" id="email" placeholder="Электронная почта" v-model="email">
                    <label for="name">Электронная почта</label>
                </div>

                <div class="form-floating">
                    <input type="password" class="form-control" id="password" placeholder="Пароль" v-model="password">
                    <label for="password">Пароль</label>
                </div>

                <div class="form-floating">
                    <input type="password" class="form-control" id="password_confirmation" placeholder="Повторите пароль" v-model="passwordConfirmation">
                    <label for="password_confirmation">Повторите пароль</label>
                </div>

                <button :class="['btn btn-danger w-100 py-2 mt-3 position-relative', {'pe-none' : userStore.isLoading}]" type="submit">
                    Сбросить пароль
                    <span v-if="userStore.isLoading" class="placeholder-wave placeholder-surface">
                        <span class="placeholder" />
                    </span>
                </button>
            </form>

        </div>
    </main>
</template>

<script setup>
    import { onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { useUsersStore } from '@/stores/UsersStore'
    import { useAuthStore } from '@/stores/AuthStore'
    import router from '@/router'
    import { showErrorNotification } from '@/services/notifications'

    const userStore = useUsersStore()
    const authStore = useAuthStore()
    const route = useRoute()

    const email = ref(null)
    const password = ref(null)
    const passwordConfirmation = ref(null)
    const token = ref(null)

    const passwordReset = () => {
        userStore.resetUserPassword({
            email: email.value,
            password: password.value,
            passwordConfirmation: passwordConfirmation.value,
            token: token.value
        })
            .then(() => setTimeout(() => goBack(), 1200))
            .catch(error => showErrorNotification(error))
    }

    const goBack = () => {
        authStore.isAuthenticated
            ? router.push({ name: 'Analyzes' })
            : router.push({ name: 'Login' })
    }

    onMounted(() => token.value = route.params.token)

</script>

<style lang="scss" scoped>
    .form-signin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        min-width: 330px;
        padding: 1rem;

        .form-login-wrapper {
            margin-top: -10vh;

            form {
                width: 360px;

                .form-floating:focus-within {
                    z-index: 2;
                }

                input {
                    &:focus {
                        box-shadow: none;
                        border-color: #dee2e6;
                    }

                    &[type="email"] {
                        margin-bottom: 10px;
                    }

                    &#password {

                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &#password_confirmation {
                        border-top: none;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }

                button {
                    &[type="submit"] {
                        background-color: #bc141b;
                    }
                }

            }
        }
    }
</style>
