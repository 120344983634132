<template>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="editOrderModal" tabindex="-1" aria-hidden="true">

        <div class="modal-dialog modal-xl modal-dialog-centered  modal-dialog-scrollable">

            <form v-if="!orderStore.renderEditOrder"  class="modal-content" @submit.prevent="editOrder">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel"><span class="d-none d-sm-inline">Редактирование заявки: </span></h1>
                    <button type="button" class="btn-close" @click="hideEditOrderModal" aria-label="Close"></button>
                </div>
                    <div class="modal-body">
                        <div class="row patient-data">
                            <div class="col-12 col-sm-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="lastName" @input="orderStore.setLastName(lastName)">
                                    <label for="lastName">Фамилия</label>
                                </div>
                                
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="firstName" v-model="firstName" @input="orderStore.setFirstName(firstName)">
                                    <label for="firstName">Имя</label>
                                </div>

                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="middleName" placeholder="Отчество" v-model="middleName" @input="orderStore.setMiddleName(middleName)">
                                    <label for="middleName">Отчество</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="tel" class="form-control" id="phone" placeholder="Телефон" v-model="phoneNumber" v-maska data-maska="+7 (###) ###-##-##" @input="orderStore.setPhoneNumber(phoneNumber)">
                                    <label for="phoneNumber">Телефон<span class="required-field">*</span></label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="date" class="form-control" id="birthDate" min="1930-01-01" :max="getTodayDate()" placeholder="Дата рождения"  v-model="birthDate" @input="orderStore.setBirthDate(birthDate)">
                                    <label for="birthDate">День рождения</label>
                                </div>

                                <div class="form-floating mb-3">
                                    <textarea class="form-control comment-size" placeholder="Комментарий к заказу" id="comment" v-model="comment" @input="orderStore.setComment(comment)"></textarea>
                                    <label for="comment">Комментарий</label>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <!-- Пол -->
                                <div class="card mb-3">
                                    <div class="card-header">
                                        Пол
                                    </div>
                                    <div class="card-body">
                                        <div class="d-flex flex-nowrap flex-column flex-sm-row">
                                            <div class="form-check me-0 me-sm-5 ps-0 ps-sm-4">

                                                <label class="form-check-label" for="genderRadioMale">
                                                    <input class="form-check-input" type="radio" name="genderRadio" id="genderRadioMale" value="Male" v-model="gender" @change="orderStore.setGender(gender)">
                                                    Мужчина
                                                </label>
                                            </div>
                                            <div class="form-check ps-0 ps-sm-4">
                                                <label class="form-check-label" for="genderRadioFemale">
                                                    <input class="form-check-input" type="radio" name="genderRadio" id="genderRadioFemale" value="Female" v-model="gender" @change="orderStore.setGender(gender)">
                                                    Женщина
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Менопауза -->
                                <div class="card mb-3" v-if="gender === 'Female'">
                                    <div class="card-header">
                                        Менопауза
                                    </div>
                                    <div class="card-body">
                                        <div class="d-flex flex-nowrap flex-column flex-sm-row">
                                            <div class="form-check me-0 me-sm-5 ps-0 ps-sm-4">
                                                <label class="form-check-label" for="menopauseRadioYes">
                                                    <input class="form-check-input" type="radio" name="menopauseRadio" id="menopauseRadioYes" v-model="menopause" :value="true" @change="orderStore.setMenopause(menopause, 'no'), pregnancy = 0, pregnancyPeriod = 0">
                                                    Да
                                                </label>
                                            </div>
                                            <div class="form-check ps-0 ps-sm-4">
                                                <label class="form-check-label" for="menopauseRadioNo">
                                                    <input class="form-check-input" type="radio" name="menopauseRadio" id="menopauseRadioNo" v-model="menopause" :value="false" @change="orderStore.setMenopause(menopause)">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Дата первого дня последней менструации -->
                                <div class="card mb-3" v-if="gender === 'Female' && menopause === false">
                                    <div class="card-header">
                                        Дата первого дня последней менструации
                                    </div>
                                    <div class="card-body">
                                        <div class="d-flex flex-nowrap">
                                            <input type="date" class="form-control mb-1" placeholder="Дата первого дня последней менструации" v-model="menopauseInfo" @change="orderStore.setMenopauseInfo(menopauseInfo)">
                                        </div>
                                    </div>
                                </div>

                                <!-- Беременность -->
                                <div class="card mb-3" v-if="gender === 'Female' && menopause === false">
                                    <div class="card-header">
                                        Беременность
                                    </div>
                                    <div class="card-body">
                                        <div class="d-flex flex-nowrap flex-column flex-sm-row">
                                            <div class="form-check me-0 me-sm-5 ps-0 ps-sm-4">
                                                <label class="form-check-label" for="pregnancyRadioYes">
                                                    <input class="form-check-input" type="radio" name="pregnancyRadio" id="pregnancyRadioYes" v-model="pregnancy" value="1" @change="orderStore.setPregnancy(pregnancy)">
                                                    <span>Да</span>
                                                </label>
                                            </div>
                                            <div class="form-check ps-0 ps-sm-4">
                                                <label class="form-check-label" for="pregnancyRadioNo">
                                                    <input class="form-check-input" type="radio" name="pregnancyRadio" id="pregnancyRadioNo" v-model="pregnancy" value="0" @change="orderStore.setPregnancy(pregnancy, 'no'), pregnancyPeriod = 0">
                                                    <span>Нет</span>
                                                </label>
                                            </div>
                                            <div class="form-check ps-0 ps-sm-4">
                                                <label class="form-check-label" for="pregnancyRadioMaybe">
                                                    <input class="form-check-input" type="radio" name="pregnancyRadio" id="pregnancyRadioMaybe" v-model="pregnancy" value="2" @change="orderStore.setPregnancy(pregnancy, 'no'), pregnancyPeriod = 0">
                                                    <span>Может быть</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-3" v-if="gender === 'Female' && menopause === false && (pregnancy === 1 || pregnancy === '1')">
                                    <div class="card-header">
                                        Количество недель беременности
                                    </div>
                                    <div class="card-body">
                                        <select class="form-select" aria-label="Default select example" v-model="pregnancyPeriod" @change="orderStore.setPregnancyDays(pregnancyPeriod)">
                                            <option v-for="n in 91" :key="n" :value="n-1">{{ n - 1 }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row analyzes-search mb-3">
                            <div class="col-12">
                                <p class="text-analysis">Поиск анализов</p>

                                <div :class="['wrapper rounded', {'border': shouldShowAccordion}]">
                                    <div class="input-container">
                                        <input type="text" :class="['form-control', {'border-0 border-bottom': shouldShowAccordion}]" v-model="analyzesStore.searchQuery" placeholder="Поиск по названию или коду анализа" />
                                        <div v-show="analyzesStore.searchQuery !== ''" class="clear-button" @click="analyzesStore.searchQuery = ''"></div>
                                    </div>
                                    <div v-if="shouldShowAccordion" class="accordion accordion-main-style" id="accordionPanelAnalyzes">
                                        <div class="accordion-item" v-for="analysis in filteredAndVisibleAnalyzes" :key="analysis.id + analysis.code">
                                            <h2 class="accordion-header">
                                               
                                                <button class="accordion-button collapsed justify-content-between"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        :data-bs-target="`#analysis-${analysis.id + analysis.code}`"
                                                        aria-expanded="false"
                                                        aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    <span class="inner-content">
                                                        <span>
                                                            <code class="me-2">{{ analysis.code }}</code>{{ analysis.name }}
                                                        </span>

                                                        <span class="price-analyzes">{{ analysis.price }} &#8381;</span>
                                                    </span>
                                                </button>
                                                
                                                <button type="button"
                                                        class="btn btn-sm add-to-order"
                                                        :class="isAnalysisInOrder(analysis.code) ? 'btn-danger' : 'btn-secondary'"
                                                        @click="toggleAssayInOrder(analysis)"
                                                >
                                                    {{ isAnalysisInOrder(analysis.code) ? 'Удалить' : 'Добавить' }}
                                                
                                                </button>
                                            </h2>                                        
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row analyzes-added" v-if="convertedAssays.length">
                            <div class="col-12">
                                <div class="d-flex justify-content-between align-items-center"> 
                                    <p class="text-analysis">Добавленные анализы</p>
                                    <span><b class="d-none d-sm-inline">Итого: </b>{{ calculateAssay() }} &#8381;</span>
                                </div> 
                                <div class="wrapper border rounded">
                                    <div class="accordion accordion-main-style" id="accordionPanelAnalyzes">
                                        <div class="accordion-item" v-for="assay in convertedAssays" :key="assay.id + assay.code">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed justify-content-between"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        :data-bs-target="`#analysis-${assay.id + assay.code}`"
                                                        aria-expanded="false"
                                                        aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                <span class="inner-content">
                                                        <span>
                                                            <code class="me-2">{{ assay.code }}</code>{{ assay.name }}
                                                        </span>

                                                        <span class="price-analyzes">{{ assay.price }} &#8381;</span>
                                                    </span>
                                                </button>

                                                <button type="button"
                                                        class="btn btn-sm add-to-order"
                                                        :class="isAnalysisInOrder(assay.code) ? 'btn-danger' : 'btn-secondary'"
                                                        @click="toggleAssayInOrder(assay)"
                                                >
                                                    {{ isAnalysisInOrder(assay.code) ? 'Удалить' : 'Добавить' }}
                                                </button>
                                            </h2>

                                            <div :id="`analysis-${assay.id + assay.code}`" class="accordion-collapse collapse">
                                                <div class="accordion-body">
                                                    <div>
                                                        <strong>Федеральный код</strong> <code>{{ assay.federal_code }}</code>
                                                    </div>

                                                    <div>
                                                        <strong>Код</strong> <code>{{ assay.code }}</code>
                                                    </div>
                                                    <div v-if="assay.description_client">
                                                                <strong>Описание для клиента</strong> <code class="description-client">{{ assay.description_client }}</code>
                                                            </div>
                                                            <div v-if="assay.description_client">
                                                                <strong>Подготовка клиента</strong> <code class="description-client">{{ assay.preparation_client }}</code>
                                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="modal-footer d-none d-sm-flex group-btn">
                    <button type="button" class="btn btn-secondary btn-small" data-bs-dismiss="modal" @click="hideEditOrderModal">Закрыть</button>
                    <button v-if="orderDetails?.status != 3" type="button" class="btn btn-danger btn-small " @click="modalOpenDelete(props.orderDetails?.id)">Удалить</button>
                    <button :disabled="isLoadingPdf" v-if="convertedAssays.length" type="button" class="btn btn-secondary btn-small " @click="printAplication">Распечатать подготовку</button>
                    <button :disabled="isLoadingSave || !convertedAssays.length" type="submit" class="btn btn-primary position-relative btn-small ">
                       <!-- {{  convertedAssays.length ? 'Сохранить изменения' : 'Добавьте анализы в корзину' }}  -->
                      {{ textSave }} 
                        <div v-if="isLoadingSave" class="spinner-border text-secondary loading-size" :class="{'small' : smallSpinner}" role="status">
                        <span class="visually-hidden">Загрузка...</span>
                      </div>
                    </button>
                </div>
                <div class="modal-footer d-flex d-sm-none group-btn">
                    <button v-if="orderDetails?.status != 3" type="button" class="btn btn-danger btn-small " @click="modalOpenDelete(props.orderDetails?.id)">Удалить</button>
                    <button :disabled="isLoadingPdf" v-if="convertedAssays.length" type="button" class="btn btn-secondary btn-small " @click="printAplication">Распечатать подготовку</button>
                    <button :disabled="isLoadingSave || !convertedAssays.length" type="submit" class="btn btn-primary position-relative btn-small ">
                       {{ textSave }} 
                        <div v-if="isLoadingSave" class="spinner-border text-secondary loading-size" :class="{'small' : smallSpinner}" role="status">
                        <span class="visually-hidden">Загрузка...</span>
                      </div>
                    </button>
                </div>
            </form>
            <form v-if="orderStore.renderEditOrder"  class="modal-content" @submit.prevent="editOrder">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Удаление заявки</h1>
                    <button type="button" class="btn-close" @click=backOrderModal aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="orderDetails">
                    <b> Вы уверены что хотетие удалить заявку</b>?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="backOrderModal">Назад</button>
                    <button type="button"
                            :disabled="orderStore.isLoading"
                            class="btn btn-danger position-relative"
                            :class="{'pe-none': orderStore.isLoading}"
                            @click="deleteOrder(orderDetails.id)"
                    >
                        Удалить заявку
                        <span v-if="orderStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </form>
        </div> 
    </div>
    <div id="printArea" class="document-print"></div>
</template>
<script setup>
    import { computed, inject, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'
    import { Modal } from 'bootstrap'
    import { useAnalyzesStore } from '@/stores/AnalyzesStore'
    import { useOrderStore } from '@/stores/OrderStore'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
    import { vMaska } from 'maska'
    import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate } from '@/helpers/dateHelpers'
    import DeleteOrderModal from './DeleteOrderModal.vue'

    import axios from '@/services/axios'

    import { userCartStore } from '@/stores/CartStore'
      const cartStore = userCartStore()

    const orderStore = useOrderStore()
    const analyzesStore = useAnalyzesStore()

    const props = defineProps(['orderDetails'])
    const editOrderModal = inject('editOrderModal')

    const firstName = ref(null)
    const middleName = ref(null)
    const lastName = ref(null)
    const birthDate = ref(null)
    const gender = ref(null)
    const phoneNumber = ref(null)
    const pregnancy = ref(null)
    const pregnancyPeriod = ref(null)
    const menopause = ref(null)
    const menopauseInfo = ref(null)
    const comment = ref(null)
    const assays = ref(null)
    const uniqueCode = ref(null)
    const convertedAssays = ref([])
    const deleteOrderModal = ref(false)
    const isLoadingPdf = ref(false)
    const isLoadingSave = ref(false)
    const getTodayDate = () => {
      const today = new Date().toISOString().split('T')[0]
      return today
    }
    const textSave = computed(() => convertedAssays.value.length ? 'Сохранить изменения' : 'Добавьте анализы в корзину')
    const calculateAssay = () => convertedAssays?.value?.reduce((acc, item) => acc + item.price, 0) || 0
    const printAplication = async() => {
      isLoadingPdf.value = true
        const printArea = document.getElementById('printArea')
              let content = ''
              content += `<p><strong>Фамилия: </strong> ${lastName.value ? lastName.value : '-'}</p>`
              content += `<p><strong>Имя:</strong> ${firstName.value ? firstName.value : '-'}</p>`
            if (middleName.value) {
                content += `<p><strong>Отчество: </strong> ${middleName.value}</p>`
            }
            if (phoneNumber.value) {
                content += `<p><strong>Номер телефона:</strong> ${phoneNumber.value}</p>`
            }

            const codes = convertedAssays.value.map((analyze) => {
              return analyze.code
            }).join(',')

            let analyzesPreparation = await axios.get('/api/analyzes/client/preparation?codes=' + codes)
            analyzesPreparation = analyzesPreparation.data.data

            // Add a separator line
            content += '<hr>'
                    convertedAssays.value.forEach(analysis => {
                        content += `<h2>${analysis.name ? analysis.name : '-'}</h2>`
                        content += `<h4>Код: ${analysis.code ? analysis.code : '-'}</h4>`
                        const preparation = analyzesPreparation.filter(item => item.code === analysis.code)
                        if (preparation.length) {
                            content += `<p>${preparation[0].preparation_client.replace(/\n/g, '<br>')}</p>`
                        }
                    })
                    printArea.innerHTML = content
                    const printWindow = window.open('', '', 'height=800,width=800')
                    if (printWindow) {
                        printWindow.document.write('<html><head><title>Print</title>')
                    printWindow.document.write('</head><body >')
                    printWindow.document.write(printArea.innerHTML)
                    printWindow.document.write('</body></html>')
                    printWindow.document.close()
                    printWindow.print()
                    } else {
                        showErrorNotification('Не удалось открыть новое окно для печати. Проверьте настройки браузера.')
                    }
            isLoadingPdf.value = false
    }
    const deleteOrder = (orderId) => {
        orderStore.deleteOrder(orderId)
        hideEditOrderModal()
    }
    const modalOpenDelete = (id) => {
        orderStore.renderEditOrder = true
    }
    const backOrderModal = () => {
        orderStore.renderEditOrder = false
    }
    const shouldShowAccordion = computed(() => analyzesStore.searchQuery && filteredAndVisibleAnalyzes.value.length > 0)
    const filteredAndVisibleAnalyzes = computed(() => {
        if (analyzesStore.searchQuery) {
            return cartStore.state.analyzes.filter(analysis => analysis.code.includes(analyzesStore.searchQuery) || analysis.name.includes(analyzesStore.searchQuery))
        }
        return []
    })
    const countAssayEnd = ref(null)

    watchEffect(() => {
        if (props.orderDetails?.assays) {
            convertedAssays.value = convertAssaysIdToName(props.orderDetails.assays)
        }
    })
    const convertAssaysIdToName = (assays) => {
      return assays.map(code => analyzesStore.analyzes.find(analysis => analysis.code === code)).filter(Boolean)
    }
    const convertTimestampToDateInput = (timestamp) => {
            const date = new Date(timestamp * 1000)
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            return `${year}-${month}-${day}`
        }
    watchEffect(() => {
        if (props.orderDetails) {
        orderStore.getOrderById(props.orderDetails.id)
        firstName.value = props.orderDetails.firstName
        middleName.value = props.orderDetails.middleName
        lastName.value = props.orderDetails.lastName
        birthDate.value = props.orderDetails.birthDate ? convertTimestampToDateInput(props.orderDetails.birthDate) : null
        gender.value = props.orderDetails.gender
        phoneNumber.value = props.orderDetails?.phone
        pregnancy.value = props.orderDetails?.pregnancy
        pregnancyPeriod.value = props.orderDetails?.pregnancyPeriod
        menopause.value = props.orderDetails?.menopause
        menopauseInfo.value = props.orderDetails?.menopauseInfo
        comment.value = props.orderDetails?.comment
        assays.value = props.orderDetails?.assays
        uniqueCode.value = props.orderDetails?.uniqueCode
        }
    })
    const editOrder = () => {
      isLoadingSave.value = true
      if (phoneNumber.value.length !== 18) {
        isLoadingSave.value = false
        return showErrorNotification('Введите номер телефона')
      }
      orderStore.editOrderById(props.orderDetails.id, birthDate.value)
        .then(() => {
          editOrderModal.value.hide()
          isLoadingSave.value = false
          orderStore.setCompletedDates(1, null, null)
          orderStore.getCompletedOrders()
        })
        .catch(error => {
          showErrorNotification('Ошибка обновления заявки', error)
          isLoadingSave.value = false
        })
    }

    const isAnalysisInOrder = code => assays.value?.includes(code)
    const hideEditOrderModal = () => {
        editOrderModal.value.hide()
    }
    const closeModalAndClearData = () => orderStore.clearFormDataValues()

    const toggleAssayInOrder = analysis => {
        isAnalysisInOrder(analysis.code)
            ? removeAssayFromOrder(analysis.code)
            : addAssayToOrder(analysis.code)
    }
    const removeAssayFromOrder = (code) => {
        const index = assays.value.indexOf(code)
        index !== -1 && assays.value.splice(index, 1)
    }

    const addAssayToOrder = code => assays.value.push(code)

    onMounted(() => {
        editOrderModal.value = new Modal('#editOrderModal')
        const modalElement = document.querySelector('#editOrderModal')
        modalElement.addEventListener('hidden.bs.modal', closeModalAndClearData)
    })

    onBeforeUnmount(() => {
        const modalElement = document.querySelector('#editOrderModal')
        modalElement.removeEventListener('hidden.bs.modal', closeModalAndClearData)
    })
</script>

<style lang="scss" scoped>
.required-field {
  color: red;
  margin-left: 2px;
}
    .ps {
        // высота экрана - modal margins - modal header - modal footer
        max-height: calc(100vh - 56px - 64px - 72px);
        //min-height: fit-content;
    }

    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    input, textarea, select {
                        transition: .3s;

                        &:focus {
                            //box-shadow: 2px 2px 12px 0 rgba(34, 60, 80, 0.06);
                            transition: none;
                        }
                    }

                    .patient-data {

                        .col-6 {
                            &:nth-child(2) {
                                input, label {
                                    cursor: pointer;
                                }
                            }
                        }

                        label {
                            @media (max-width: 575.98px) {
                                padding: 10px;
                            }

                            input {
                                margin-top: 0;
                                margin-left: 0 !important;
                                margin-right: 0.75rem;

                            }
                        }

                        .form-check-label {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .analyzes-search {
                        .wrapper {
                            .input-container {
                                position: relative;

                                .reset-button {
                                    display: flex;
                                    height: 60%;
                                    align-items: center;
                                    position: absolute;
                                    top: 50%;
                                    right: 7px;
                                    transform: translateY(-50%);
                                    font-size: 0.7rem;
                                }
                            }

                            .accordion {
                                .accordion-item {
                                    .accordion-header {
                                        button.accordion-button {

                                            @media (max-width: 575.98px) {
                                                &:after {
                                                    display: none;
                                                }
                                            }

                                            .inner-content {
                                                @media (max-width: 575.98px) {
                                                    display: flex;
                                                    flex-direction: column;
                                                    width: calc(100% - 90px);
                                                    margin-right: 0;
                                                    gap: 1rem;
                                                    font-weight: 300;
                                                    span {
                                                        &.price {
                                                            font-weight: bold;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        button.add-to-order {
                                            @media (max-width: 575.98px) {
                                                height: 100%;
                                                top: 0;
                                                right: 0;
                                                transform: none;
                                                border-radius: 0;
                                                width: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .analyzes-added {
                        .accordion {

                            button.add-to-order:first-of-type {
                                // Ваши стили здесь
                                background-color: blue;  // Пример
                            }

                            .accordion-item {
                                .accordion-header {
                                    button.accordion-button {

                                        @media (max-width: 575.98px) {
                                            &:after {
                                                display: none;
                                            }
                                        }

                                        .inner-content {
                                            @media (max-width: 575.98px) {
                                                display: flex;
                                                flex-direction: column;
                                                width: calc(100% - 90px);
                                                margin-right: 0;
                                                gap: 1rem;

                                                span {
                                                    &.price {
                                                        font-weight: bold;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    button.add-to-order {
                                        @media (max-width: 575.98px) {
                                            height: 100%;
                                            top: 0;
                                            right: 0;
                                            transform: none;
                                            border-radius: 0;
                                            width: 70px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
.accordion-main-style .accordion-item .accordion-header button.accordion-button span.inner-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: 128px;
}

.clear-button {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: transparent;
}

.clear-button::before,
.clear-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
    background-color: black;
}

.clear-button::before {
    transform: rotate(45deg);
}

.clear-button::after {
    transform: rotate(-45deg);
}
.comment-size{
    height: 100px
}
.description-client{
    color: #414141
}
.loading-size{
    width: 20px;
                    height: 20px;
}
.document-print{
    display: none;
}
.text-analysis{
    font-size: 16px;
    font-weight: 500;
}
.price-analyzes{
    @media (min-width: 575.98px) {
    width: 90px;
    float: right;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    }
}
.btn-small {
    @media (max-width: 369px) {
        font-size: 10px !important;
        margin: 1px;
        padding: 7px;
    }
    @media (min-width: 370px) and (max-width: 400px) {
        font-size: 10px !important;
        margin: 1px;
        padding: 7px;
    }
    @media (min-width: 401px) and (max-width: 450px) {
        font-size: 12px !important;
        margin: 1px;
        padding: 6px;
    }
    @media (min-width: 451px) and (max-width: 500px) {
        font-size: 13px !important;
        margin: 3px;
        padding: 7px;
    }
    @media (min-width: 500px) and (max-width: 575.98px) {
        font-size: 14px !important;
        margin: 2px;
        padding: 8px;
    }
}
.group-btn{
    @media (max-width: 575.98px) {
    justify-content: space-between !important;
    }
}
</style>
