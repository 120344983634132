<template>
    <div class="modal fade" id="movingDoctorsModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Перемещение врачей</h1>
                    <button type="button" class="btn-close" @click="hideMovingDoctorsModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body">

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="searchQuery" placeholder="Найти менеджера" v-model="searchQuery">
                        <label for="firstName">Найти менеджера</label>
                    </div>

                    <div class="list-group">
                        <div class="list-group-item list-group-item-action position-relative"
                             v-for="manager in filteredManagers"
                             :key="manager.id"
                             @click="selectManager(manager.id)"
                             :class="{ 'active': isActiveManager(manager.id), 'active pe-none': isCurrentManager(manager.id) }"
                        >
                            <div class="d-flex w-100 justify-content-between"
                            >
                                <h5 class="mb-1">{{ manager.first_name }} {{ manager.middle_name }} {{ manager.last_name }} </h5>
                            </div>
                            <p class="mb-1"><b>Почта:</b> {{ manager.email }}</p>
                            <p class="mb-1"><b>Телефон:</b> {{ manager.phone }}</p>

                            <i v-if="isCurrentManager(manager.id)" class="bi bi-check-circle"></i>

                            <span v-show="isActiveManager(manager.id) && managersStore.isLoading" class="placeholder-glow placeholder-surface">
                                <span class="placeholder" />
                            </span>
                        </div>

                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideMovingDoctorsModal">Закрыть</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, inject, onMounted, ref, toRaw, watchEffect } from 'vue'
    import { Modal } from 'bootstrap'
    import { useManagersStore } from '@/stores/ManagersStore'

    const managersStore = useManagersStore()
    const props = defineProps(['managerDetails'])

    const searchQuery = ref('')
    const activeManagerId = ref(null)
    const currentManagerDetails = ref(null)

    const isCurrentManager = (id) => id === currentManagerDetails.value.id
    const isActiveManager = (id) => id === activeManagerId.value

    watchEffect(() => currentManagerDetails.value = { ...toRaw(props.managerDetails ?? {}) })

    const movingDoctorsModal = inject('movingDoctorsModal')

    const filteredManagers = computed(() => {
        if (!searchQuery.value) return []

        const queryWords = searchQuery.value.toLowerCase().split(' ').filter(word => word)

        return managersStore.managers.filter(manager => {
            const managerString = `${manager.first_name} ${manager.middle_name} ${manager.last_name} ${manager.email} ${manager.phone}`.toLowerCase()
            return queryWords.every(word => managerString.includes(word))
        })
    })

    const selectManager = async managerId => {
        // Извлечение врачей для выбранного менеджера
        const selectedManager = managersStore.managers.find(manager => manager.id === currentManagerDetails.value.id)
        selectedManager.doctors.length > 0 && (managersStore.doctorsByManager = selectedManager.doctors)

        await managersStore.moveDoctorsToManagerById(activeManagerId.value = managerId, currentManagerDetails.value.id)
            // Обновление currentManagerDetails после перемещения
            .then(() => currentManagerDetails.value.id = managerId)
    }

    const hideMovingDoctorsModal = () => movingDoctorsModal.value.hide()

    onMounted(() => movingDoctorsModal.value = new Modal('#movingDoctorsModal'))
</script>

<style scoped lang="scss">
    .modal-dialog {
        .modal-body {
            @media (min-width: 576px) {
                max-height: 50vh;
            }

            &:hover {
                cursor: pointer;
            }

            .list-group {
                .list-group-item {
                    i {
                        position: absolute;
                        top: 10px;
                        right: 12px;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
</style>
