<template>
    <div class="modal fade" id="editManagerModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <form class="modal-content" @submit.prevent="editManager">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Редактирование менеджера</h1>
                    <button type="button" class="btn-close" @click="hideEditManagerModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body">

                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="lastName">
                    <label for="lastName">Фамилия</label>
                  </div>

                  <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="firstName">
                        <label for="firstName">Имя</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="middleName" placeholder="Отчество" v-model="middleName">
                        <label for="middleName">Отчество</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="emailAddress" placeholder="Электронная почта" v-model="email">
                        <label for="emailAddress">Электронная почта</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="tel" class="form-control" id="phoneNumber" placeholder="Телефон" v-maska data-maska="+7 (###) ###-##-##" v-model="phone">
                        <label for="phoneNumber">Телефон</label>
                    </div>
                    <div  class="form-floating ">
                        <select class="form-select" id="userRoles" v-model="idManager">
                            <option disabled value="null">Выберите топ менеджера</option>
                            <option v-for="manager in topManager.managersInCreate" :key="manager.id" :value="manager.id">
                                {{ manager.lastname }} {{ manager.firstname }} {{ manager.middlename }}
                            </option>
                        </select>
                        <label for="userRoles">Региональный менеджеры</label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideEditManagerModal">Закрыть</button>
                    <button type="submit"
                            class="btn btn-primary position-relative"
                            :class="{'pe-none': managersStore.isLoading}"
                    >
                        Сохранить
                        <span v-if="managersStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>

            </form>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted, ref, watchEffect } from 'vue'
    import { Modal } from 'bootstrap'
    import { useManagersStore } from '@/stores/ManagersStore'
    import { vMaska } from 'maska'
    import { getTopManager } from '@/api/TopManager'
    import { useTopManager } from '@/stores/TopManager'
  import { showErrorNotification, showSuccessNotification } from '@/services/notifications'

    const topManager = useTopManager()
    const managersStore = useManagersStore()

    const props = defineProps(['managerDetails'])
    const editManagerModal = inject('editManagerModal')

    const firstName = ref(null)
    const middleName = ref(null)
    const lastName = ref(null)
    const email = ref(null)
    const phone = ref(null)
    const idManager = ref(null)

    watchEffect(() => {
        if (props.managerDetails) {
            firstName.value = props.managerDetails.first_name ?? null
            middleName.value = props.managerDetails.middle_name ?? null
            lastName.value = props.managerDetails.last_name ?? null
            email.value = props.managerDetails.email ?? null
            phone.value = props.managerDetails.phone ?? null
            idManager.value = props.managerDetails.manager_id ?? null
        }
    })
    const checkEmail = (email) => {
            const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
            return emailRegex.test(email)
    }
    const editManager = () => {
        if (managersStore.isLoading) return
        const editedData = {
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value
        }
        if (phone.value) {
          editedData.phone = phone.value
        }
        if (middleName.value) {
          editedData.middle_name = middleName.value
        }
        for (const key in editedData) {
                if (editedData[key] === null || editedData[key] === '') {
                    return showErrorNotification('Не все поля заполнены')
                }
                if (key === 'phone' && editedData[key].length !== 18) {
                    return showErrorNotification('Номер телефона должен содержать 11 цифр')
                }
        }
        if (!checkEmail(email.value)) {
            return showErrorNotification('Неправильно введен email')
        }
        if (idManager.value !== 'null') {
                editedData.manager_id = idManager.value
        }
        if (!editedData.manager_id) {
            return showErrorNotification('Выберите регионального менеджера')
        }
        managersStore.editManagerById(props.managerDetails.id, editedData)
            .then(() => {
                hideEditManagerModal()
                getTopManager()
            })
    }

    const hideEditManagerModal = () => editManagerModal.value.hide()

    onMounted(() => {
        editManagerModal.value = new Modal('#editManagerModal')
        getTopManager()
    })
</script>
