<template>
  <div class="mt-3 mt-sm-4">
    <table class="table">
      <thead>
        <div colspan="4" class="text-center">
          <h2 class="text-uppercase h4"><i class="bi bi-person-fill-check fs-4 me-2"></i>Мои пациенты</h2>
        </div>
      <div>
        <div class="input-container d-flex align-items-center justify-content-start w-100">
          <input type="text" class="form-control me-2" v-model="searchPatient" placeholder="Поиск по ФИО пациента"/>
          <button  type="button" class="btn btn-primary btn-sm container-plus"
                  @click="openModalAddPatient()"><span >+</span></button>
        </div>
        <div class="col-md-3 mb-1">
        </div>
      </div>
      </thead>
      <tbody>
      <tr v-for="order in filteredPatient" :key="order.id">
        <td
            colspan="5"
        >
          <span class="my-3 my-sm-0 d-none d-sm-inline-flex"><b>{{ order.lastname }} {{ order.firstname }} {{ order.middlename }}</b></span>
          <button
              type="button"
              class="btn btn-sm add-to-order btn-secondary float-end d-none d-sm-flex ms-2"
              @click.stop="openModalAddPatient(order)"
          >
            Изменить
          </button>
          <button
              
              type="button"
              class="btn btn-sm add-to-order btn-danger float-end d-none d-sm-flex ms-2"
              @click.stop="deletePatientModal(order.id)"
          >
            Удалить
          </button>
          <div class="d-inline-flex d-sm-none w-100 container-fio">
            <span class="my-2 my-sm-0"><b>{{ order.lastname }} {{ order.firstname }} {{ order.middlename }}</b><span  class="d-flex d-sm-none"><b class="d-none d-sm-inline ms-0 ms-sm-3 ms-sm-3"></b>{{
              phoneMask(order.phone)
            }}</span></span>
          <div class="d-inline-flex">
            <button
              type="button"
              class="btn btn-sm add-to-order btn-secondary float-end ms-3"
              @click.stop="openModalAddPatient(order)"
          >
          <i class="bi bi-pencil fs-6 " />
          </button>
          <button
              type="button"
              class="btn btn-sm add-to-order btn-danger float-end ms-3"
              @click.stop="deletePatientModal(order.id)"
          >
          <i class="bi bi-x-lg fs-6" />
          </button></div>
          </div>
          <span class="float-end d-none d-sm-flex"><b class="d-none d-sm-inline ms-0 ms-sm-3 ms-sm-3"></b>{{
              phoneMask(order.phone)
            }}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <InfoPatientOrder :patientOrders="patientOrders"/>
    <AddPatient :dataPatient="dataPatient"/>
    <DeletePatient :id="deleteIdPatient"/>
    <div class="text-center"
         v-if="filteredPatient.length === 0 && orderStore.patients.length === 0 && orderStore.renderPatientLoading">
      <LoadingSpinner :showSpinner="true"/>
    </div>
    <div v-if="filteredPatient.length === 0 && orderStore.patients.length > 0 && !orderStore.renderPatientLoading" 
      class="text-center"
         >
      <h2 class="text-uppercase message-no-list">Нет пациентов</h2>
    </div>
    <div v-if="filteredPatient.length === 0 && orderStore.patients.length === 0 && !orderStore.renderPatientLoading" 
      class="text-center"
         >
      <h2 class="text-uppercase message-no-list">Нет пациентов</h2>
    </div>
    
  </div>
</template>

<script setup>
import { useOrderStore } from '@/stores/OrderStore'
import { onMounted, ref, provide, computed } from 'vue'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate, stateAnalyzes } from '@/helpers/dateHelpers'
import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import AnalyzesStatus from '@/components/Profile/Badges/AnalyzesStatus.vue'
import { useAuthStore } from '@/stores/AuthStore'
import InfoPatientOrder from '@/components/Patient/Modals/InfoPatientOrder.vue'
import AddPatient from '@/components/Patient/Modals/AddPatient.vue'
import { formatPhoneNumberToSend } from '@/helpers/phoneFormattersHelper'
import DeletePatient from './Modals/DeletePatient.vue'

const orderStore = useOrderStore()
const analyzesStore = useAnalyzesStore()
const authStore = useAuthStore()

const infoPatientOrder = ref(null)
provide('infoPatientOrder', infoPatientOrder)

const deletePatient = ref(null)
provide('deletePatient', deletePatient)

const addPatient = ref(null)
provide('addPatient', addPatient)
const orderDetails = ref(null)
const searchPatient = ref('')
const patientOrders = ref(null)
const dataPatient = ref('')
const deleteIdPatient = ref(0)
const deletePatientModal = (id) => {
  deleteIdPatient.value = id
  deletePatient.value.show()
}

const filteredPatient = computed(() => {
  let filtered = orderStore.patients
  if (searchPatient.value.trim() !== '') {
    const query = searchPatient.value.toLowerCase()
    filtered = filtered.filter(order => {
      const fullName = `${order.lastname} ${order.firstname} ${order.middlename} ${order.phone} ${order.email}`.toLowerCase()
      return fullName.includes(query)
    })
  }
  return filtered
})
const showPatientOrder = (patientFirstName, patientMiddleName, patientLastName, patientId) => {
  orderStore.getCompletedOrders()
  infoPatientOrder.value.show()
  orderStore.patientOrder = orderStore.processedOrders.filter(order => {
    return (
        order.patientFirstName === patientFirstName &&
        order.patientMiddleName === patientMiddleName &&
        order.patientLastName === patientLastName
    )
  })
}
const phoneMask = (phone) => {
  if (phone) {
    const x = phone
        .replace(/\D/g, '')
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
    x[1] = '+7'
    phone = !x[3]
        ? x[1] + '(' + x[2]
        : x[1] +
        '(' +
        x[2] +
        ')' +
        x[3] +
        (x[4] ? '-' + x[4] : '') +
        (x[5] ? '-' + x[5] : '')
  }
  return phone
}
const openModalAddPatient = (order) => {
  orderStore.inputErorrPatient = false
  if (order) {
    dataPatient.value = order
    orderStore.dataPatient = order
    orderStore.checkSavePatient = true
  } else {
    orderStore.dataPatient = {
      firstname: null,
      middlename: null,
      lastname: null,
      birthday: null,
      gender: null,
      phone: null,
      email: null
  }
    orderStore.checkSavePatient = false
  }
  addPatient.value.show()
}
onMounted(() => {
  orderStore.getCompletedOrders()
  orderStore.getPatientOrders()
})
</script>
<style scoped lang="scss">
.message-no-list {
  height: 50%;
  color: rgb(174, 174, 174);
  font-size: larger;
  align-content: end;
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

.accordion-button {
  &:after {
    margin-left: 0;

    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.accordion-main-style {
  button.accordion-button {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}

.tab-menu {
  display: flex;
  justify-content: space-between;
}

.tab-menu button {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tab-menu button.active {
  background-color: #149cdd;
  color: white; // Цвет текста на активном табе
}
.container-plus{
  width: 40px;
  height: 38px;
  span {
    bottom: 6px;
    position: relative;
    display: flow;
    font-size: x-large;
  }
}
.container-fio{
  justify-content: space-between;
}
</style>
