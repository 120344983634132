<template>
  <div class="row my-4 align-items-center">
    <div
        class="align-items-center d-flex justify-content-between align-items-center"
    >
      <h1 class="text-uppercase h3 my-1 w-75">
        <i class="bi bi-bag-check fs-4 me-2"></i>Оформление заказа
        <button
            v-if="cartStore.state.assays.length && $route.path === '/order-items'"
            class="btn btn-secondary float-end clear-order d-none d-sm-flex"
            @click="clearCart"
        >
          <span>Очистить корзину</span>
        </button>
      </h1>
      <button
          v-if="cartStore.state.assays.length && $route.path === '/order-items'"
          class="btn btn-secondary float-end clear-order d-flex d-sm-none"
          @click="clearCart"
      >
        <span class="d-flex d-sm-none bi bi-x-lg"></span>
      </button>
    </div>
  </div>

  <div class="row d-none d-sm-flex">
    <PatientData v-if="$route.path === '/checkout'"/>

    <div
        class="col-12 col-sm-9 mb-4 mb-sm-0 order-items-container"
        v-if="$route.path === '/order-items'"
    >
      <OrderItems/>
      <p
          v-if="!cartStore.state.assays.length"
          class="d-flex pe-3 mb-0 align-items-center"
          style="height: 52px; padding-left: 20px"
      >
        Для оформления заказа необходимо добавить&nbsp;<router-link
          :to="{ name: 'Analyzes' }"
          class="text-decoration-none"
      >анализы
      </router-link
      >
        .
      </p>
    </div>

    <div class="d-flex d-sm-block col-12 col-sm-3 flex-column flex-sm-row">
      <MakeOrder/>
    </div>
  </div>
  <div class="row d-flex d-sm-none">
    <div class="d-flex d-sm-block col-12 col-sm-3 flex-column flex-sm-row">
      <MakeOrder/>
    </div>
    <PatientData v-if="$route.path === '/checkout'"/>

    <div
        class="col-12 col-sm-9 mb-4 mb-sm-0 order-items-container"
        v-if="$route.path === '/order-items'"
    >
      <OrderItems/>
      <p
          v-if="!cartStore.state.assays.length"
          class="d-flex pe-3 mb-0 align-items-center"
          style="height: 52px; padding-left: 20px"
      >
        Для оформления заказа необходимо добавить&nbsp;<router-link
          :to="{ name: 'Analyzes' }"
          class="text-decoration-none"
      >анализы
      </router-link
      >
        .
      </p>
    </div>
  </div>
</template>

<script setup>
import OrderItems from '@/components/Checkout/OrderItems.vue'
import MakeOrder from '@/components/Checkout/MakeOrder.vue'
import PatientData from '@/components/Checkout/PatientData.vue'
import { useOrderStore } from '@/stores/OrderStore'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { userCartStore } from '@/stores/CartStore'

const cartStore = userCartStore()

const orderStore = useOrderStore()
const analyzesStore = useAnalyzesStore()
const clearCart = () => {
  cartStore.clearOrderData()
}
</script>

<style scoped lang="scss">
.clear-order {
  margin-right: 5px;
  @media (max-width: 575.98px) {
    right: 10px;
    position: absolute;
    width: 40px;
    height: 40px;
    place-items: center;
    justify-content: center;
  }
}

.clear-cart {
  margin-right: 5px;
  @media (max-width: 575.98px) {
    margin-left: 10px;
    position: absolute;
  }
}

$ps-height: 100%;

.row {
  :deep([class*="col-"]) {
    min-height: fit-content;
    //max-height: 80vh;

    .ps {
      position: relative;
      min-height: fit-content;
      max-height: $ps-height;
      border-radius: var(--bs-border-radius);
      box-shadow: 0 0 0 1px var(--bs-border-color),
      2px 2px 12px 0 rgba(34, 60, 80, 0.15);

      .accordion {
        .accordion-item {
          border-left: none;
          border-right: none;

          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }

          .accordion-header {
            position: relative;

            button {
              z-index: 1;

              &.accordion-button {
                &:after {
                  margin-left: 1rem;
                }

                &:focus {
                  box-shadow: none;
                }

                @media (max-width: 575.98px) {
                  &:after {
                    display: none;
                  }
                }

                span.inner-content {
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  margin-right: 110px;

                  @media (max-width: 575.98px) {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 90px);
                    margin-right: 0;
                    gap: 1rem;
                  }

                  span.price {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                    @media (max-width: 575.98px) {
                      font-weight: bold;
                    }
                  }
                }
              }

              &.add-to-order {
                position: absolute;
                top: 50%;
                right: 3.6rem;
                width: 90px;
                transform: translateY(-50%);
                z-index: 3;

                @media (max-width: 575.98px) {
                  height: 100%;
                  top: 0;
                  right: 0;
                  transform: none;
                  border-radius: 0;
                }
              }
            }
          }
        }

        .accordion-button {
          &:focus {
            box-shadow: none;
          }
        }
      }

      .list-group {
        button {
          border-left: none;
          border-right: none;

          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
