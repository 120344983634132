<template>
  <div>
    <!--  LIST SELECTED ALL   -->
    <div v-if="favoriteList.gender === 1" class="row" style="padding-right: 0">
      <div class="col-12 mb-3 p-0 mt-3 mt-sm-0"><p style="text-align: center">Общий список</p>
        <div class="mx-3">
          <div v-if="favoriteList.selectedAnalyzes.all.length">
            <div v-for="analysis in favoriteList.selectedAnalyzes.all" :key="analysis" class="ps m-2">
              <div class="row selectedRow">
                <div class="col-1" style="align-items: center;
    position: relative;
    display: flex;
    margin-left: 10px;">
                  <code class="me-0 me-sm-5">
                    {{ analyzesStore.analyzes.filter(item => item.code === String(analysis))[0].code }} </code>
                </div>
                <div class="col-sm-7 col-7 col-md-8 col-lg-9">
                  {{ analyzesStore.analyzes.filter(item => item.code === String(analysis))[0].name }}
                </div>
                <div class="col-1" style="align-items: center;
    position: relative;
    display: flex;
    margin-right: 10px;">
                  <button class="btn btn-sm add-to-order" @click="deSelectAnalyze(analysis)">Удалить</button>
                </div>
              </div>
            </div>

          </div>
          <div class="message-no-select" v-else>Анализы не выбраны</div>
        </div>
      </div>
    </div>
    <!--  LIST SELECTED BOTH   -->
    <div v-else class="row">
      <div class="col-12 col-sm-6 mb-3 p-0 mt-3 mt-sm-0"><p style="text-align: center">Мужские анализы</p>
        <div class="mx-3">
          <div v-if="favoriteList.selectedAnalyzes.male.length">
            <div v-for="analysis in favoriteList.selectedAnalyzes.male" :key="analysis" class="ps m-2">
              <div class="row selectedRow">
                <div class="col-2" style="align-items: center;
    position: relative;
    display: flex;
    margin-left: 10px;">
                  <code class="me-0 me-sm-5">{{
                      analyzesStore.analyzes.filter(item => item.code === String(analysis))[0].code
                    }}</code>
                </div>
                <div class="col-sm-12 col-12 col-md-12 col-lg-6">
                  {{ analyzesStore.analyzes.filter(item => item.code === String(analysis))[0].name }}
                </div>
                <div class="col-2" style="align-items: center;
    position: relative;
    display: flex;
    margin-right: 10px;">
                  <button class="btn btn-sm add-to-order" @click="deSelectAnalyzeMale(analysis)">Удалить</button>
                </div>
              </div>
            </div>
          </div>
          <div class="message-no-select" v-else>Анализы для мужчины не выбраны</div>
        </div>

      </div>
      <div class="col-12 col-sm-6 p-0"><p style="text-align: center">Женские анализы</p>
        <div class="mx-3">
          <div v-if="favoriteList.selectedAnalyzes.female.length">
            <div v-for="analysis in favoriteList.selectedAnalyzes.female" :key="analysis" class="ps m-2">
              <div class="row selectedRow">
                <div class="col-2" style="align-items: center;
    position: relative;
    display: flex;
    margin-left: 10px;">
                  <code class="me-0 me-sm-5">{{
                      analyzesStore.analyzes.filter(item => item.code === String(analysis))[0].code
                    }}</code>
                </div>
                <div class="col-sm-12 col-12 col-md-12 col-lg-6" style="align-items: center;
    position: relative;
    display: flex;
    margin-right: 10px;">
                  {{ analyzesStore.analyzes.filter(item => item.code === String(analysis))[0].name }}
                </div>
                <div class="col-2" style="align-items: center;
    position: relative;
    display: flex;
    margin-right: 10px;">
                  <button class="btn btn-sm add-to-order" @click="deSelectAnalyzeFemale(analysis)">Удалить</button>
                </div>
              </div>
            </div>
          </div>
          <div class="message-no-select" v-else>Анализы для женщины не выбраны</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div>
        <input
            type="text"
            class="form-control mb-3 mt-3 mt-sm-0  me-3 me-sm-3"
            placeholder="Поиск анализа по названию, коду или федеральному коду"
            v-model="searchQuery"

        />
      </div>
      <table class="table table-hover" style="width: 100%">
        <tbody class="container-analysis-body">
        <tr
            v-for="analysisGroup in
            filteredAnalyzeFavorite"
            :key="analysisGroup.id"
            class="container-tr row cart-mobile"
        >
          <div class="col-8 col-sm-9 group-analysis">
            <td colspan="3">{{ analysisGroup.name }}</td>
            <td class="footer-cart-price">
              <code>{{ analysisGroup.code }}</code>
              <span style="text-align: right"
              >{{ analysisGroup.price }}&#8381;</span
              >
            </td>
          </div>
          <div class="col-2 col-sm-3 container-cart-control">
            <td style="justify-content: center; align-content: center;
">
              <button
                  v-if="favoriteList.gender===1 && !favoriteList.selectedAnalyzes.all.includes(parseInt(analysisGroup.code))"
                  type="button"
                  class="btn btn-sm add-to-order"
                  @click="selectAnalyzeTypeFirst(analysisGroup.code)">
                <i class="bi bi-cart fs-5"></i>
              </button>
              <span class="add-to-order check-icon"
                    v-if="favoriteList.gender===1 && favoriteList.selectedAnalyzes.all.includes(parseInt(analysisGroup.code))"><i
                  class="bi bi-check fs-1"></i></span>

              <div style="" v-if="favoriteList.gender===2 " class="btn-group cart-all-gender" role="group"
                   aria-label="Basic example">
                <div></div>
                <button style="border-radius: 5px" @click="selectAnalyzeTypeBoth(analysisGroup.code,1)" type="button"
                        class="btn btn-sm btn-primary button-mode">М
                </button>
                <button style="border-radius: 5px" @click="selectAnalyzeTypeBoth(analysisGroup.code,2)" type="button"
                        class="btn btn-sm btn-primary button-mode">Ж
                </button>
                <button style="border-radius: 5px" @click="selectAnalyzeTypeBoth(analysisGroup.code,3)" type="button"
                        class="btn btn-sm btn-primary button-mode">всем
                </button>
              </div>

            </td>
          </div>
        </tr>
        <tr class="container-tr cart-desktop"
            v-for="analysisGroup in
          filteredAnalyzeFavorite"
            :key="analysisGroup.id"
            data-bs-toggle="collapse"
            :data-bs-target="`#completedOrders-collapseTwo-${analysisGroup.id}`"
        >
          <td @click="openInfoAnalysis(analysisGroup)" class="container-time">
                    <span style="width: 60%">
                  <code class="me-0 me-sm-2 analyse-code">{{
                      analysisGroup.code
                    }}</code>
                  </span>
          </td>
          <td @click="openInfoAnalysis(analysisGroup)" class="container-time">
                    <span style="width: 60%">
                  {{ analysisGroup.name }}
                  </span>
          </td>
          <td @click="openInfoAnalysis(analysisGroup)" style="width: 100px; align-content: center;">
            <div
                class="d-flex justify-content-end align-items-center w-100"
            >
              {{ analysisGroup.price }}&#8381;
            </div>
          </td>
          <td class="cart-control">
            <div class="col-12 col-sm-9">
              <div style="position: relative;">
                <button
                    v-if="favoriteList.gender===1 && !favoriteList.selectedAnalyzes.all.includes(parseInt(analysisGroup.code))"
                    type="button"
                    class="btn btn-sm add-to-order"
                    @click="selectAnalyzeTypeFirst(analysisGroup.code)">
                  Добавить
                </button>
                <div v-if="favoriteList.gender===2 " class="btn-group " role="group" aria-label="Basic example">
                  <div></div>
                  <button style="border-radius: 5px
" @click="selectAnalyzeTypeBoth(analysisGroup.code,1)" type="button" class="btn btn-sm btn-primary button-mode">мужской
                  </button>
                  <button style="border-radius: 5px" @click="selectAnalyzeTypeBoth(analysisGroup.code,2)" type="button"
                          class="btn btn-sm btn-primary button-mode">женский
                  </button>
                  <button style="border-radius: 5px" @click="selectAnalyzeTypeBoth(analysisGroup.code,3)" type="button"
                          class="btn btn-sm btn-primary button-mode">всем
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <div style="color: red" ref="loadMoreTrigger" id="loading-trigger">
          <span style="width: 100%; height: 10px"></span>
        </div>
        <div class="btn-up btn-up_hide"></div>
        </tbody>
        <LoadingSpinner
            v-if="tabAnalysis === 2 && analyzesStore.loadingTopAnalyzes"
            spinnerStyle="min-height: var(--ps-height);"
            :showSpinner="true"
        />
        <div
            style="height: 300px"
            class="text-center"
            v-if="
          tabAnalysis === 2 &&
          !filteredTopAnalyzes.length &&
          !analyzesStore.loadingTopAnalyzes
        "
        >
          <h2

              class="text-uppercase h4 message-no-analysis"
          >
            Анализы не найдены
          </h2>
        </div>
        <div
            style="height: 300px"
            class="text-center"
            v-if="
          tabAnalysis === 1 &&
          searchQuery.length > 0 &&
          analyzesStore.contentItem.length > 0 &&
          !filteredAndSearchedAnalyzes.length
        "
        >
          <h2
              class="text-uppercase h4 message-no-analysis"
          >
            Анализы не найдены
          </h2>
        </div>
      </table>
      <div style="height: 300px;" class="text-center"
           v-if="filteredAnalyzeFavorite.length === 0 && analyzesStore.contentItem.length !== 0">
        <h2 style="height: 50%; color: #aeaeae; font-size: larger;
    align-content: end;" class="text-uppercase h4">Ничего не найдено</h2>
      </div>
    </div>

  </div>
  <div v-if="favoriteList.descriptionAnalysis">
    <InfoAnalysisModal :infoAnalysis="favoriteList.descriptionAnalysis"/>
  </div>

</template>

<script setup>
import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
import { getAnalysis } from '@/api/FavoriteAnalyzes'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { computed, ref, provide } from 'vue'
import { showSuccessNotification } from '@/services/notifications'
import InfoAnalysisModal from '@/components/Profile/Modals/InfoAnalysisModal.vue'

const isMobile = ref(window.innerWidth <= 575.98)
const analyzesStore = useAnalyzesStore()
const infoAnalysisModal = ref(null)
const infoAnalysis = ref(null)
provide('infoAnalysisModal', infoAnalysisModal)
const searchQuery = ref('')
const filteredAnalyzeFavorite = computed(() => {
      const searchTerms = searchQuery.value.toLowerCase().split(' ')
      if (searchQuery.value === '') {
        return analyzesStore.analyzes
      } else {
        return analyzesStore.analyzes.filter((value) => {
          return searchTerms.every((term) => {
            return (
                value.code.includes(term) ||
                value.name.toLowerCase().includes(term) ||
                value.federal_code?.includes(term)
            )
          })
        })
      }
    }
)

const favoriteList = useFavoriteListsStore()
const props = defineProps(['type'])
const selectAnalyzeTypeFirst = (assayCode) => {
  const analyzeCode = parseInt(assayCode)
  if (!favoriteList.selectedAnalyzes.all.includes(analyzeCode)) {
    favoriteList.selectedAnalyzes.all.push(analyzeCode)
    showSuccessNotification('Анализ добавлен в общий список')
  }
}

const selectAnalyzeTypeBoth = (assayCode, type) => {
  const analyzeCode = parseInt(assayCode)
  if (type === 1) {
    if (!favoriteList.selectedAnalyzes.male.includes(analyzeCode)) {
      favoriteList.selectedAnalyzes.male.push(analyzeCode)
      showSuccessNotification('Анализ добавлен в мужской список')
    }
  }
  if (type === 2) {
    if (!favoriteList.selectedAnalyzes.female.includes(analyzeCode)) {
      favoriteList.selectedAnalyzes.female.push(analyzeCode)
      showSuccessNotification('Анализ добавлен в женский список')
    }
  }
  if (type === 3) {
    if (!favoriteList.selectedAnalyzes.female.includes(analyzeCode)) {
      favoriteList.selectedAnalyzes.female.push(analyzeCode)
      showSuccessNotification('Анализ добавлен в женский список')
    }
    if (!favoriteList.selectedAnalyzes.male.includes(analyzeCode)) {
      favoriteList.selectedAnalyzes.male.push(analyzeCode)
      showSuccessNotification('Анализ добавлен в мужской список')
    }
  }
}
const deSelectAnalyze = (assayCode) => {
  const index = favoriteList.selectedAnalyzes.all.findIndex(item => item === assayCode)
  favoriteList.selectedAnalyzes.all.splice(index, 1)
  showSuccessNotification('Анализ удален из общего списка')
}

const deSelectAnalyzeMale = (assayCode) => {
  const index = favoriteList.selectedAnalyzes.male.findIndex(item => item === assayCode)
  favoriteList.selectedAnalyzes.male.splice(index, 1)
  showSuccessNotification('Анализ удален из мужского списка')
}

const deSelectAnalyzeFemale = (assayCode) => {
  const index = favoriteList.selectedAnalyzes.female.findIndex(item => item === assayCode)
  favoriteList.selectedAnalyzes.female.splice(index, 1)
  showSuccessNotification('Анализ удален из женского списка')
}

const openInfoAnalysis = (analysis) => {
  getAnalysis(analysis.id)
  infoAnalysisModal.value.show()
}
</script>
<style scoped lang="scss">
.ps {
  position: relative;
  min-height: fit-content;
  max-height: calc(100vh - 300px);
  border-radius: var(--bs-border-radius);
  border: 1px solid #cecece;

  .selectedRow {
    margin-bottom: 4px;
  }

  .selectedRow:nth-child(even) {
    background: #cecece;
  }
}

.button-mode {
  background-color: #ebebeb;
  color: black;
  border-radius: 5px;
  border: 1px solid #cecece;
  @media (min-width: 575.98px) {
    padding: 0 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.message-no-select {
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.add-to-order {
  @media (max-width: 575.98px) {
    height: 100%;
    top: 0;
    right: 0;
    transform: none;
    border-radius: 0;
  }
}

button.add-to-order:hover {
  background-color: #0dcaf0;
  color: black;
  opacity: 1;
}

.container-analysis-body {
  @media (max-width: 575.98px) {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
  }
}

.container-tr {
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;

  code {
    display: flex;
  }
}

.group-analysis {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 15px;
  display: inline-grid;
  align-items: center;
}

.container-cart-control {

  display: flex;
  justify-content: center;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 15px;
  align-content: center;
}

.cart-mobile {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  @media (min-width: 575.98px) {
    display: none;
  }
}

.cart-desktop {
  display: none;
  @media (min-width: 575.98px) and (max-width: 799px) {
    display: table-row;
  }
  @media (min-width: 800px) {
    display: table-row;
  }
}

.row {
  &.content-container {
    [class*="col-"] {
      min-height: fit-content;
      max-height: var(--ps-height);

      input {
        transition: 0.3s;

        &:focus {
          box-shadow: 2px 2px 12px 0 rgba(34, 60, 80, 0.06);
          transition: none;
        }
      }

      .ps {
        position: relative;
        max-height: var(--ps-height);
        box-shadow: 0 0 0 1px var(--bs-border-color),
        2px 2px 12px 0 rgba(34, 60, 80, 0.15);

        &:nth-child(2) {
          max-height: calc(var(--ps-height) - 54px);
        }

        .accordion {
          .accordion-item {
            .accordion-header {
              button.accordion-button {
                @media (max-width: 575.98px) {
                  &:after {
                    display: none;
                  }
                }

                .inner-content {
                  @media (max-width: 575.98px) {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 90px);
                    margin-right: 0;
                    gap: 1rem;

                    span {
                      &.price {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }

              button.add-to-order {
                @media (max-width: 575.98px) {
                  height: 100%;
                  top: 0;
                  right: 0;
                  transform: none;
                  border-radius: 0;
                  width: 100%;
                }
              }

              button.add-to-order:hover {
                background-color: #0dcaf0;
                color: black;
                opacity: 1;
              }

              button.add-to-order {
                background-color: #ededed;
                color: black;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.check-icon {
  color: #736f6f;
}

.footer-cart-price {
  display: inline-flex;
  justify-content: space-between;
}

.cart-all-gender {
  @media (max-width: 900px) {
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
  }
}

.cart-control {
  align-content: center;
}
</style>
