<template>
  <div v-if="tabAnalysis === 2">
    <div class="row content-container" style="position: relative">
      <div class="d-sm-block col-xs-12 col-12 col-lg-4 col-md-12 col-xl-7">
        <div class="analysis-container d-sm-block col-xs-3 col-12 col-lg-12 col-md-12 col-xl-6"
             :class="{active: renderCategory, height1: tabAnalysis===1, height2: tabAnalysis === 2}">
          <div class="tab-menu">
            <button
                class="list-group-item list-group-item-action d-flex align-items-center"
                :class="{ active: tabAnalysis === 1 }"
                @click="changeTab(1)"
            >
              <i class="bi bi-eyedropper me-1 fs-5"></i>
              Анализы
            </button>
            <button
                class="list-group-item list-group-item-action d-flex align-items-center"
                :class="{ active: tabAnalysis === 2 }"
                @click="changeTab(2)"
            >
              <i class="bi bi-star me-1 fs-5"></i>
              Топ анализов
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row content-container">
      <TopAnalyzes :tabAnalysis="tabAnalysis" @category="renderCategory = !renderCategory"/>
    </div>
  </div>
  <div v-if="tabAnalysis === 1">
    <div class="row content-container">
      <div class="d-sm-block col-xs-4 col-12 col-lg-4 col-md-12 col-xl-4">
        <div class="analysis-container"
             :class="{active: renderCategory, height1: tabAnalysis===1, height2: tabAnalysis === 2}">
          <div class="tab-menu">
            <button
                class="list-group-item list-group-item-action d-flex align-items-center"
                :class="{ active: tabAnalysis === 1 }"
                @click="changeTab(1)"
            >
              <i class="bi bi-eyedropper me-3 fs-5"></i>
              Анализы
            </button>
            <button
                class="list-group-item list-group-item-action d-flex align-items-center"
                :class="{ active: tabAnalysis === 2 }"
                @click="changeTab(2)"
            >
              <i class="bi bi-star me-3 fs-5"></i>
              Топ анализов
            </button>
          </div>
          <div
              v-if="analyzesStore.groups.length > 0"
              class="accordion list-group-main-style "
              id="accordionCategories"
          >
            <div
                class="accordion-item"
                v-for="category in analyzesStore.groups"
                :key="category.id"
            >
              <div class="accordion-header ">
                <button
                    class="accordion-button category-space "
                    :class="{ collapsed: analyzesStore.selectedCategory !== 's' }"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#category-${category.id}`"
                    aria-expanded="false"
                    :aria-controls="`category-${category.id}`"
                    @click="
                    getCategoriesOrgan(category.sub_groups, category.id);
                    selectCategory(category.id);
                  "
                >
                  {{ category.name }}
                </button>
              </div>
              <div
                  :id="`category-${category.id}`"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionCategories"
              >
                <div>
                  <div class="list-group" id="accordionPanelOrder">
                    <div
                        v-for="additionallyCategories in categoriesOrgan"
                        :key="additionallyCategories.id"
                    >
                      <button
                          class="list-group-item list-group-item-action item-category "
                          :style="
                            analyzesStore.selectedAnalysis === additionallyCategories.id
                              ? 'background-color: #149cdd; color: white;'
                              : 'background-color: white;'
                          "

                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="`#additionallyCategories-${additionallyCategories.id}`"
                          aria-expanded="false"
                          :aria-controls="`additionallyCategories-${additionallyCategories.id}`"
                          @click="loadAnalyze(additionallyCategories.id), renderCategory = !renderCategory"
                      >
                          <span class="inner-content">
                            <span>{{ additionallyCategories.name }}</span>
                          </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-header btn-accordion" id="headingTwo">
              <button
                  class="accordion-button collapsed category-space category-border"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
              >
                Все категории
              </button>
            </div>
            <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
            >
              <div class=" d-sm-block">
                <div
                    v-if="analyzesStore.categories.length"
                    class="list-group list-group-main-style"
                >
                  <button
                      class="list-group-item list-group-item-action"
                      :class="{ active: renderAllCategory }"
                      @click="getAllAnalyzes(), renderCategory = !renderCategory"
                      v-if="analyzesStore.categories"
                  >
                    Все анализы
                  </button>
                  <button
                      class="list-group-item list-group-item-action"
                      :class="{
                      active: Number(selectId) === category.id,
                    }"
                      v-for="category in analyzesStore.categories"
                      @click="getAnalysis(category.id), renderCategory = !renderCategory"
                      :key="category.id"
                  >
                    {{ category.name }}
                  </button>
                </div>
                <LoadingSpinner
                    spinnerStyle="min-height: var(--ps-height);"
                    v-else-if="
                    !analyzesStore.categories ||
                    analyzesStore.categories.length === 0
                  "
                    :showSpinner="true"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <LoadingSpinner
                spinnerStyle="min-height: var(--ps-height);"
                :showSpinner="true"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 mt-3 col-md-12 col-lg-8 col-xl-8">
        <AnalysisItem :tabAnalysis="tabAnalysis" :isLoading="isLoading"
                      @openCategory="renderCategory = !renderCategory"/>
        <div>
          <div class="loading-analysis"
               v-if="isLoading"
          >
            <div
                class="d-flex justify-content-center align-items-center inner-wrapper"
            >
              <div
                  class="spinner-border text-secondary"
                  :class="{ small: smallSpinner }"
                  role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import AnalysisItem from '@/components/Profile/AnalysisItem.vue'
import { selectAnalysis } from '@/api/Analyzes'
import { getAllOrgans } from '@/api/Organs'
import { getCategories } from '@/api/Categories'
import { getTopAnalyzes } from '@/api/TopAnalyzes'
import TopAnalyzes from '@/components/TopAnalyzes/TopAnalyzes.vue'

const analyzesStore = useAnalyzesStore()
const route = useRoute()
const router = useRouter()
const renderAllCategory = ref(false)
const categoriesOrgan = ref(0)
const selectedCategory = ref(0)
const isLoading = ref(false)
const selectId = ref(0)
const tabAnalysis = ref(1)
const renderCategory = ref(false)
const changeTab = (number) => {
  analyzesStore.loadingTopAnalyzes = true
  getTopAnalyzes()
  tabAnalysis.value = number
  renderCategory.value = !renderCategory.value
}
const getAnalysis = (id) => {
  selectId.value = id
  renderAllCategory.value = false
  analyzesStore.contentItem = analyzesStore.analyzes.filter((analysis) => {
    return analysis.categories.some((category) => {
      if (category && category.id === id) {
        return category.id === id
      } else {
        return false
      }
    })
  })
}
const getAllAnalyzes = () => {
  selectId.value = ''
  renderAllCategory.value = true
  analyzesStore.searchQuery = ''
  analyzesStore.contentItem = analyzesStore.analyzes
  router.push({ name: 'Analyzes' })
}
const getCategoriesOrgan = (organ) => {
  categoriesOrgan.value = organ
}
const loadAnalyze = (id) => {
  isLoading.value = true
  selectAnalysis(id)
      .then(() => (isLoading.value = false))
      .catch(() => (isLoading.value = false))
}
const selectCategory = (categoryId) => {
  selectedCategory.value = categoryId
}
onMounted(() => {
  getCategories()
  getAllOrgans()
  route.params.categoryId = ''
})
</script>

<style scoped lang="scss">
.selected-analysis {
  background-color: #149cdd;
}

.button-dop-category:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

input {
  &.form-control {
    box-shadow: none;
  }
}

.row {
  &.content-container {
    [class*="col-"] {
      min-height: fit-content;
      max-height: var(--ps-height);

      input {
        transition: 0.3s;

        &:focus {
          box-shadow: 2px 2px 12px 0 rgba(34, 60, 80, 0.06);
          transition: none;
        }
      }

      .ps {
        max-height: calc(100vh - 250px);
        box-shadow: 0 0 0 1px var(--bs-border-color),
        2px 2px 12px 0 rgba(34, 60, 80, 0.15);

        &:nth-child(2) {
          max-height: calc(var(--ps-height) - 54px);
        }

        .accordion {
          .accordion-item {
            .accordion-header {
              button.accordion-button {

                @media (max-width: 575.98px) {
                  &:after {
                    display: none;
                  }
                }

                .inner-content {
                  font-size: 14px;
                  @media (max-width: 575.98px) {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 90px);
                    margin-right: 0;
                    gap: 1rem;
                    span {
                      &.price {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }

              button.add-to-order {
                @media (max-width: 575.98px) {
                  height: 100%;
                  top: 0;
                  right: 0;
                  transform: none;
                  border-radius: 0;
                }
              }

              button.add-to-order:hover {
                color: red;
              }
            }
          }
        }
      }
    }
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item.active {
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color),
  2px 2px 12px 0 rgba(34, 60, 80, 0.15);
}

.accordion-button {
  &:after {
    margin-left: 0;
    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.accordion-main-style {
  button.accordion-button {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tab-menu {
  display: flex;
  justify-content: space-between;
  height: 49px;
  border: 1px solid #dbdbdb;
  border-top-left-radius: 5px;
}

.tab-menu button {
  flex: 1;
  text-align: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding-left: 5px;
}

.tab-menu button.active {
  background-color: #149cdd;
  color: white; // Цвет текста на активном табе
}

.category-space {
  display: flex;
  justify-content: space-between;
}
.category-border{
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.container-tab-menu {
  @media (min-width: 1000px) {
    margin-top: 15px;
    position: absolute;
    // width: 20%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  @media (min-width: 1570px) {
    //  width: 14%;
  }
  @media (min-width: 560px) and (max-width: 999px) {
    margin-top: 15px;
    margin-bottom: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 20px;
}

.analysis-container {
  overflow-y: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 0 0px var(--bs-border-color),
  0px 3px 4px 0 rgba(34, 60, 80, 0.15);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  flex: 0 0 auto;
  @media (max-width: 560px) {
    //width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    z-index: 1000;
    position: fixed;
    left: -1000px;
    transition: all .3s;
    &.active {
      left: 10px;
      width: calc(100% - 75px);
      z-index: 1000;
      background: #fff;
    }

    &.height1 {
      height: calc(100vh - 100px)
    }
    &.height2 {
      height: auto;
    }
  }

  @media (min-width: 560px) and (max-width: 999px) {
    position: relative;
    box-shadow: 0 0 0 0px var(--bs-border-color),
    0px 3px 4px 0 rgba(34, 60, 80, 0.15);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: static;
    flex: 0 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media (min-width: 1000px) {
    box-shadow: 0 0 0 0px var(--bs-border-color),
    0px 3px 4px 0 rgba(34, 60, 80, 0.15);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: static;
    flex: 0 0 auto;
    //width: 20%;
    margin-top: 20px;
  }
  //@media (min-width: 1570px)   {
  //      width: 14%;
  //}
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 27%;
  }
}

.list-group-main-style button.list-group-item:last-child {
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
