<template>
  <div :style="wrapperStyle" class="scroll-wrapper">
    <div :style="innerStyle"
         v-if="computedShowSpinner"
         class="d-flex justify-content-center align-items-center inner-wrapper"
    >
      <div class="spinner-border text-secondary" :class="{'small' : smallSpinner}" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps(['showSpinner', 'smallSpinner', 'spinnerStyle', 'wrapperStyle', 'innerStyle'])

const smallSpinner = props.smallSpinner || false
const computedShowSpinner = computed(() => props.showSpinner || false)
const computedSpinnerStyle = computed(() => props.spinnerStyle || '')
</script>

<style scoped>
.spinner-border {
  width: 64px;
  height: 64px;
  font-size: 48px;
  opacity: 0.3;
  --bs-spinner-animation-speed: 1.25s;
  &.small {
    width: 40px;
    height: 40px;
    font-size: 30px;
  }
}
</style>
