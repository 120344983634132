import { defineStore } from 'pinia'
import axios from '@/services/axios'
import { showErrorNotification } from '@/services/notifications'

export const useBranchesStore = defineStore('branchesStore', {
    state: () => ({
        branches: null
    }),

    actions: {
        async getAllBranches() {
            await axios.get('/api/branch')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.branches = response.data.data
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
        },

        resetState() {
            this.branches = null
        }
    }
})
