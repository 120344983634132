import { defineStore } from 'pinia'

export const useAnalyzesStore = defineStore('analyzesStore', {
    state: () => ({
        analyzes: [],
        categories: [],
        contentItem: [],
        groups: [],
        analyzeGroup: [],
        topAnalyzes: [],
        loadingTopAnalyzes: []
    })
})
