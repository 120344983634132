<template>
    <span :class="['badge', className]">
		{{ text }}
	</span>
</template>

<script setup>
    import { ref, computed } from 'vue'

    const props = defineProps(['type'])

    const classMap = ref({
        0: 'text-bg-secondary',
        1: 'text-bg-primary',
        2: 'text-bg-warning',
        3: 'text-bg-success',
        4: 'text-bg-danger'
    })

    const textMap = ref({
        0: 'Не зарегистрирована',
        1: 'В работе',
        2: 'Часть результатов готова',
        3: 'Все результаты готовы',
        4: 'Заказ отменен'
    })

    const className = computed(() => classMap.value[props.type])
    const text = computed(() => textMap.value[props.type])
</script>

<style scoped>
    span {
        font-size: 0.75rem;
        margin-right: 2px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7);

        @media (max-width: 575.98px) {
            position: absolute;
            top: 0;
            right: -2px;
            width: fit-content;
            border-radius: 0 0 0 5px;
            font-size: 0.65rem;
        }
    }
</style>
