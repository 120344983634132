import { defineStore } from 'pinia'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'

export const useProfileStore = defineStore('profileStore', {
    state: () => ({
        id: null,
        name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: null,
        doctorBonus: {},
        isLoading: false
    }),

    actions: {
        getProfileData() {
            this.isLoading = true
            axios.get('/api/profile')
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.id = response.data.data.id
                        this.first_name = response.data.data.first_name
                        this.middle_name = response.data.data.middle_name
                        this.last_name = response.data.data.last_name
                        this.email = response.data.data.email
                        this.phone = response.data.data.phone
                        this.doctorBonus = response.data.data.doctorBonus
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }).finally(() => this.isLoading = false)
        },

        resetState() {
            this.id = null
            this.name = ''
            this.first_name = ''
            this.middle_name = ''
            this.last_name = ''
            this.email = ''
            this.phone = null
        }
    }
})
