<template>
    <div class="modal fade" id="deleteOrderModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Удаление заказа</h1>
                    <button type="button" class="btn-close" @click=hideDeleteOrderModal aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="orderDetails">
                    Удалить <b>{{ orderDetails.firstName }} {{ orderDetails.middleName }} {{ orderDetails.lastName }}</b>?
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideDeleteOrderModal">Закрыть</button>
                    <button type="button"
                            class="btn btn-danger position-relative"
                            :class="{'pe-none': orderStore.isLoading}"
                            @click="deleteOrder"
                    >
                        Удалить заказ
                        <span v-if="orderStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted } from 'vue'
    import { useOrderStore } from '@/stores/OrderStore'
    import { Modal } from 'bootstrap'

    const orderStore = useOrderStore()
    const props = defineProps(['orderDetails'])
    const deleteOrderModal = inject('deleteOrderModal')

    const deleteOrder = () => orderStore.deleteOrderById(props.orderDetails)
        .then(() => hideDeleteOrderModal())

    const hideDeleteOrderModal = () => deleteOrderModal.value.hide()

    onMounted(() => deleteOrderModal.value = new Modal('#deleteOrderModal'))
</script>
