import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import axios from '@/services/axios'
import { showErrorNotification } from '@/services/notifications'

export async function getCategories() {
    const analyzesStore = useAnalyzesStore()
    try {
        const result = await axios.get('/api/analyzes/categories')
            if (result.data.success && result.data.data) {
                analyzesStore.categories = result.data.data
            } else if (result.data.message) {
                return showErrorNotification(result.data.message)
            } else {
                return showErrorNotification('Ошибка сервера')
            }
        } catch (error) {
            if (error.response.data.message) {
                showErrorNotification(error.response.data.message)
            } else {
                showErrorNotification('Ошибка сервера')
            }
    }
}
