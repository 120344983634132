import axios from 'axios'
import router from '@/router'
import { useAuthStore } from '@/stores/AuthStore'

const instance = axios.create({
     baseURL: 'https://api.cl-book.ru'
})
instance.interceptors.request.use(config => {
    const authStore = useAuthStore()
    const token = authStore.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, error => Promise.reject(error))

instance.interceptors.response.use(null, error => {
    const authStore = useAuthStore()
    if (error.code === 'ERR_NETWORK') {
        const serverError = new Error('Ошибка сервера')
        serverError.response = { data: { message: 'Ошибка сервера' } }
        return Promise.reject(serverError)
    }
    if (error.response.status === 401) {
        authStore.removeToken()
        router.push({ name: 'Login' })
    }

    return Promise.reject(error)
})

export default instance
