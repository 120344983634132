<template>
    <div class="modal fade" id="deletePatient" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Удаление пациента </h1>
                    <button type="button" class="btn-close" @click=hideDeleteFavoriteListModal aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Вы уверены что хотите удалить пациента?
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideDeleteFavoriteListModal">Закрыть</button>
                    <button :disabled="isLoadingDelete" type="button"
                            class="btn btn-danger position-relative"
                            :class="{'pe-none': favoriteListsStore.isLoading}"
                            @click="deleteFavoriteList"
                    >
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted, ref } from 'vue'
    import { Modal } from 'bootstrap'
    import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
    import axios from '@/services/axios'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
    import { useOrderStore } from '@/stores/OrderStore'
    const favoriteListsStore = useFavoriteListsStore()
    const orderStore = useOrderStore()
    const isLoadingDelete = ref(false)
    const props = defineProps(['id'])
    const deletePatient = inject('deletePatient')
    
    const deleteFavoriteList = () => {
      isLoadingDelete.value = true
      axios.delete(`api/doctor/patients/${props.id}`).then((response) => {
        if (response.data.success && response.data.data) {
          isLoadingDelete.value = false
          hideDeleteFavoriteListModal()
          showSuccessNotification(response.data.message)
          orderStore.patients = orderStore.patients.filter(item => item.id !== props.id)
        } else if (response.data.message) {
          showErrorNotification(response.data.message)
        } else {
          showErrorNotification('Ошибка сервера')
        }
        }
      ).catch(error => {
        if (error.response.data.message) {
          showErrorNotification(error.response.data.message)
          isLoadingDelete.value = false
        } else {
          showErrorNotification('Ошибка сервера')
          isLoadingDelete.value = false
        }
      })
    }

    const hideDeleteFavoriteListModal = () => deletePatient.value.hide()

    onMounted(() => deletePatient.value = new Modal('#deletePatient'))
</script>
