import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import axios from '@/services/axios'
import { showErrorNotification } from '@/services/notifications'
import { userCartStore } from '@/stores/CartStore'
export async function getAnalyzes() {
    const cartStore = userCartStore()
    try { 
        const dataFromLocalStorage = localStorage.getItem('analyzes')
        if (dataFromLocalStorage) cartStore.state.assays = JSON.parse(dataFromLocalStorage)
        const analyzesStore = useAnalyzesStore()
        const result = await axios.get('/api/analyzes/analysis')

        if (result.data.success && result.data.data) {
            analyzesStore.analyzes = result.data.data
            analyzesStore.contentItem = result.data.data
            cartStore.state.analyzes = result.data.data
        } else if (result.data.message) {
            return showErrorNotification(result.data.message)
        } else {
            return showErrorNotification('Ошибка сервера')
        }
    } catch (error) {
        if (error.response.data.message) {
            showErrorNotification(error.response.data.message)
        } else {
            showErrorNotification('Ошибка сервера')
        }
        cartStore.state.analyzes = []
    } 
}
export async function selectAnalysis(analysisId) {
    try {
        const analyzesStore = useAnalyzesStore()
        analyzesStore.selectedAnalysis = analysisId
        analyzesStore.contentItem = []
        const result = await axios.get(`/api/analyzes/groups/${analysisId}`)
        if (result.data.success && result.data.data) {
            analyzesStore.contentItem = result.data.data
        } else if (result.data.message) {
            return showErrorNotification(result.data.message)
        } else {
            return showErrorNotification('Ошибка сервера')
        }
    } catch (error) {
        if (error.response.data.message) {
            showErrorNotification(error.response.data.message)
        } else {
            showErrorNotification('Ошибка сервера')
        }
    } 
}
