<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark navbar-fixed"
       v-show="isAuthenticated && !authStore.isLoading">
    <div class="container-fluid">
      <img src="@/assets/cl-book.svg" alt="" class="mx-2 image-with-hover-effect"
           @click="!authStore.isManager ? $router.push('/analyzes') : ''">
      <!--TODO: Временное решение показа кнопки оформления заказ -->
      <router-link :to="{name: 'OrderItems'}"
                   v-if="orderStore.assaysInCart.length > 0 && isAuthenticated"
                   class="d-block d-sm-none ms-auto me-3 me-sm-3 btn"
                   :class="[
                                    isActiveOrderItems ? 'btn-success pe-none order-items-active' : 'btn-primary',
                                    orderStore.assaysInCart.length === 0 ? 'disabled' : ''
                                 ]"
      >
        <i class="bi bi-bag me-1"/>
        <span class="badge text-bg-secondary ms-2">{{ orderStore.assaysInCart.length }}</span>
      </router-link>

      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarCollapse">

        <ul class="navbar-nav me-auto mt-4 mt-sm-0 mb-4 mb-sm-0">
          <li class="nav-item" v-if="authStore.isManager">
            <router-link :class="{ 'active btn-light': isTopManager }" aria-current="page"
                         class="nav-link ps-3 ps-sm-2" :to="{name: 'TopManager'}" v-if="authStore.isManager"
            >
              Менеджеры
            </router-link>
          </li>
          <!-- <li class="nav-item" v-if="authStore.isManager">
            <router-link  :class="{ 'active btn-light': isPriceDoctor }" aria-current="page"
            class="nav-link ps-3 ps-sm-2" :to="{name: 'DoctorPrice'}" v-if="authStore.isManager"
                         >
              Отчет о заказах
            </router-link>
          </li> -->
          <!--<li class="nav-item">
              <router-link class="nav-link"
                           :class="isActiveDashboard ? 'active btn-light' : ''"
                           aria-current="page"
                           :to="/dashboard"
                           v-if="isAuthenticated"
              >
                  Панель управления
              </router-link>
          </li>-->
          <li class="nav-item" v-if="isAuthenticated && (authStore.isDoctor || authStore.isAdmin)">
            <router-link class="nav-link ps-3 ps-sm-2"
                         :class="{ 'active btn-light': isActiveAnalyzes }"
                         aria-current="page"
                         :to="{name: 'Analyzes'}"
            >
              Анализы
            </router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated && (authStore.isDoctor || authStore.isAdmin)">
            <router-link class="nav-link ps-3 ps-sm-2"
                         :class="isActiveBranches ? 'active btn-light' : ''"
                         aria-current="page"
                         :to="{name: 'Branches'}"
            >
              Филиалы
            </router-link>
          </li>
          <li class="nav-item" v-if="authStore.isStaff || authStore.isAdmin">
            <router-link class="nav-link ps-3 ps-sm-2"
                         :class="isActiveDoctors ? 'active btn-light' : ''"
                         aria-current="page"
                         :to="{name: 'Doctors'}"
            >
              Врачи
            </router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated && (authStore.isDoctor )">
            <router-link class="nav-link ps-3 ps-sm-2" :to="{name: 'FavoriteLists'}" v-if="authStore.isDoctor "
                         :class="isActiveFavorite ? 'active btn-light' : '' " aria-current="page">
              Избранные списки
            </router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated && (authStore.isChiefDoctor)">
            <router-link class="nav-link ps-3 ps-sm-2" :to="{name: 'GlobalFavoriteLists'}"
                         v-if=" authStore.isChiefDoctor"
                         :class="route.name === 'GlobalFavoriteLists' ? 'active btn-light' : ''">
              Избранные списки
            </router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated && authStore.isDoctor">
            <router-link @click="getComponent" class="nav-link ps-3 ps-sm-2" :to="{name: 'Orders'}"
                         v-if="authStore.isDoctor"
                         :class="isActiveOrder ? 'active btn-light' : '' " aria-current="page">
              Заказы
            </router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated && authStore.isDoctor">
            <router-link class="nav-link ps-3 ps-sm-2" :to="{name: 'Patient'}" v-if="authStore.isDoctor"
                         :class="isPatientsDoctor ? 'active btn-light' : '' " aria-current="page">
              Мои пациенты
            </router-link>
          </li>
          <li class="nav-item" v-if="!isAuthenticated">
            <router-link class="nav-link ps-3 ps-sm-2" :to="{name: 'Login'}">Вход</router-link>
          </li>

          <li class="nav-item" v-if="isAuthenticated && authStore.isAdmin">
            <router-link class="nav-link ps-3 ps-sm-2"
                         :class="isActiveManagers ? 'active btn-light' : ''"
                         aria-current="page"
                         :to="{name: 'Managers'}"
            >
              Менеджеры
            </router-link>
          </li>

          <li class="nav-item" v-if="isAuthenticated && authStore.isAdmin">
            <router-link class="nav-link ps-3 ps-sm-2"
                         :class="route.name === 'Permissions' ? 'active btn-light' : ''"
                         aria-current="page"
                         :to="{name: 'Permissions'}"
            >
              Права доступа
            </router-link>
          </li>

          <li class="nav-item">
            <a class="nav-link disabled" aria-disabled="true" v-if="!isAuthenticated">Регистрация</a>
          </li>

        </ul>
        <div v-if="authStore.isDoctor"
             class="container-price"
        >
          <img src="@/assets/money.svg" width="22" height="20" class="rounded-circle flex-shrink-0 icon-money"/>
          <template v-if="authStore.isDoctor"> {{ profileStore.doctorBonus ? profileStore.doctorBonus.bonus : 0 }}
            &#8381;
          </template>
        </div>

        <div v-if="isAuthenticated && authStore.userProfile" class="bnt-wrapper">

          <router-link v-if="!authStore.isManager" :to="{name: 'OrderItems'}"
                       type="button"
                       class="btn btn-sm"
                       :class="[
                                    isActiveOrderItems ? 'btn-success pe-none' : 'btn-primary',
                                    !cartStore.state.assays.length ? 'disabled' : ''
                                 ]"
          >
            <i class="bi bi-bag me-1"/>
            {{ isActiveOrderItems ? 'Оформление заказа' : 'Оформить заказ' }}
            <span class="badge text-bg-secondary ms-2"
                  v-if="cartStore.state.assays.length">{{ cartStore.state.assays.length }}</span>
          </router-link>

          <router-link v-if="!authStore.isManager"
                       :to="{name: authStore.isDoctor ? 'ManagerProfile' : routeName}"
                       :class="[isActiveProfile ? 'pe-none' : '']"
                       class="btn btn-sm btn-info"
                       @click.stop="toggleMenu"
                       type="button"
                       ref="userContextMenuButtonRef">
            <i class="bi bi-person-vcard me-1"/>
            Мой профиль
          </router-link>
        </div>
        <button class="btn btn-outline-success mt-4 mt-sm-0 mb-2 mb-sm-0" @click="authStore.logout()"
                v-if="isAuthenticated">Выход
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore'
import { useOrderStore } from '@/stores/OrderStore'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Collapse } from 'bootstrap'
// import UserContextMenu from '@/components/Navbar/userContextMenu.vue'
import { userCartStore } from '@/stores/CartStore'
import { useProfileStore } from '@/stores/ProfileStore'

const profileStore = useProfileStore()
const cartStore = userCartStore()
const authStore = useAuthStore()
const orderStore = useOrderStore()
const route = useRoute()

const isAuthenticated = computed(() => authStore.isAuthenticated)
const isActiveDashboard = ref(false)
const isActiveBranches = ref(false)
const isActiveDoctors = ref(false)
const isActiveOrderItems = ref(false)
const isActiveAnalyzes = ref(false)
const isActiveManagers = ref(false)
const isActiveProfile = ref(false)
const isActiveFavorite = ref(false)
const isActiveOrder = ref(false)
const isMenuVisible = ref(false)
const isPatientsDoctor = ref(false)
const isTopManager = ref(false)
const isPriceDoctor = ref(false)
const roleToRouteMap = {
  'doctor': 'Profile',
  'staff': 'PersonalData',
  'admin': 'Users',
  'manager': 'Manager',
  'chief-doctor': 'ChiefDoctor'
}
const getComponent = () => {
  orderStore.currentTab = 1
}
// const userContextMenuRef = ref(null)
const userContextMenuButtonRef = ref(null)

const toggleMenu = () => isMenuVisible.value = !isMenuVisible.value

const handleClickOutside = () => isMenuVisible.value = false

const routeName = computed(() => roleToRouteMap[authStore.userProfile.role] || 'PersonalData')
watch(() => route.path, (newPath) => {
      isActiveDashboard.value = newPath === '/dashboard'
      isActiveBranches.value = newPath === '/branches'
      isActiveDoctors.value = newPath.startsWith('/doctors')
      isActiveManagers.value = newPath === '/managers'
      isActiveOrderItems.value = newPath === '/order-items' || newPath === '/checkout'
      isActiveAnalyzes.value = newPath.startsWith('/analyzes')
      isActiveProfile.value = newPath.startsWith('/profile')
      isActiveFavorite.value = newPath.startsWith('/favorite-lists')
      isActiveOrder.value = newPath.startsWith('/orders')
      isPatientsDoctor.value = newPath.startsWith('/patients')
      isTopManager.value = newPath.startsWith('/profile/topmanager')
      isPriceDoctor.value = newPath.startsWith('/profile/price')
    },
    { immediate: true }
)

watch(() => route.path, () => {
  const navbarToggler = document.querySelector('.navbar-toggler')
  const navbarCollapseElement = document.getElementById('navbarCollapse')

  if (navbarToggler && navbarToggler.offsetParent !== null && navbarCollapseElement) {
    if (navbarCollapseElement.classList.contains('show')) {
      new Collapse(navbarCollapseElement).hide()
    }
  }
})

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
  if (authStore.isDoctor) {
    profileStore.getProfileData()
  }
})
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))

</script>

<style scoped lang="scss">
.card-error {
  color: black;
  background-color: orange;
  font-size: x-large;
  text-align: center;
}

.image-with-hover-effect:hover {
  cursor: pointer; /* Добавляем подсветку курсора при наведении */
}

nav {
  .navbar-brand {
    cursor: default;
    user-select: none;
  }

  .order-items-active {
    @media (max-width: 575.98px) {
      box-shadow: 0 0 4px 2px var(--bs-success);
      border: 1px solid black !important;
    }
  }

  .router-link-active {
    @media (max-width: 575.98px) {
      border: 1px solid white;
    }
  }

  #navbarCollapse {
    ul {
      li {
        @media (min-width: 576px) {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        a {
          border-radius: 0.375rem;
          border: 1px solid transparent;

          &.active {
            background-color: #5a5a5a;

            @media (max-width: 575.98px) {
              border: 1px solid white;
            }
          }
        }
      }
    }

    > div {
      a {
        @media (max-width: 575.98px) {
          width: 100%;
        }
      }
    }
  }
}

.icon-money {
  margin-right: 5px
}

.container-price {
  display: flex;
  justify-content: center;
  place-items: center;
  border: 1px solid #797979;
  background-color: transparent;
  color: #d1d1d1;
  padding: 6px;
  border-radius: var(--bs-border-radius);
  font-size: 12px;
  flex-wrap: nowrap;
  width: 90px;
  margin-right: 10px;
}

.bnt-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 15px;

  a:first-child {
    margin-right: 10px;
  }

  @media screen and (max-width: 990px) {
    margin: 10px 0;
    width: 100%;
    min-width: 300px;
    justify-content: flex-start;
    flex-direction: column;

    a:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }

  }
}
</style>
