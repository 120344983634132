<template>
  <div class="mt-3 mb-4">
    <span
        class="mt-3 mb-4"
        style="cursor: pointer; font-weight: bold"
        @click="backDoctor()"
    ><i class="bi bi-arrow-left"> Назад к списку </i></span
    >
  </div>
  <div class="col-sm-6 d-flex align-items-center mt-3">
    <h2 class="text-uppercase h4 m-0">
      <i class="bi bi-person-fill-add fs-4 me-2"/>{{
        topManager.selectedManagerFullName
      }}
    </h2>
  </div>
  <h5 style="margin-top: 30px">Список врачей</h5>
  <input
      type="text"
      class="form-control mb-1 mt-4 mb-3"
      placeholder="Поиск доктора"
      v-model="searchQuery"
  />
  <div
      class="text-center message-no-list"
      v-if="filteredAndSearchedDoctors.length === 0"
  >
    <h5 class="text-uppercase container-message">Ничего не найдено</h5>
  </div>
  <div class="list-group">
    <template v-for="doctor in filteredAndSearchedDoctors" :key="doctor">
      <a style="cursor: default"
         class="list-group-item"
         aria-current="true"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="fw-bold">
            {{ doctor.lastname }} {{ doctor.firstname }}
            {{ doctor.middlename }}
          </h5>
        </div>
        <p class="mb-1">{{ doctor.email }} </p>
        <div class="justify-content-center">
          <div class="doctor-bonus">
            <DoctorBonusGraph
                v-if="doctorStore.doctorBonus?.find((item) => item.doctor_id === doctor.doctor_id) && !isLoading && (doctorStore.doctorBonus?.find((item) => item.doctor_id === doctor.doctor_id))?.data"
                :data="doctorStore.doctorBonus.find((item) => item.doctor_id === doctor.doctor_id).data"
                :main-chart-title="`mainChart_${doctor.doctor_id}`"></DoctorBonusGraph>
            <div v-else class="message-container">
              <p v-if="!doctorStore.isLoading">Данные по бонусам формируются...</p>
            </div>
            <LoadingSpinner v-if="doctorStore.isLoading && !doctorStore.doctorBonus.length" :wrapperStyle="`width: 100%;
    height: 1px;
    justify-content: center;
    position: relative;
    display: contents;`" :innerStyle="`position: relative;
    left: 50%;
    top: 20px;
    transform: translate(-50%, -50%);`" :smallSpinner="`smallSpinner`" :showSpinner="true"/>

          </div>
          <div>
          </div>
        </div>

      </a>
    </template>
  </div>
</template>
<script setup>
import { useTopManager } from '@/stores/TopManager'
import { onMounted, ref, provide, computed } from 'vue'
import DoctorBonusGraph from '@/components/ui/DoctorBonusGraph.vue'
import { useDoctorsStore } from '@/stores/DoctorsStore'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'

const searchQuery = ref('')
const topManager = useTopManager()
const doctorStore = useDoctorsStore()
const filteredAndSearchedDoctors = computed(() => {
  const searchTerms = searchQuery.value.toLowerCase().split(' ')
  if (searchQuery.value === '') {
    return topManager.doctors
  } else {
    return topManager.doctors.filter((value) => {
      return searchTerms.every((term) => {
        return (
            value.lastname.toLowerCase().includes(term) ||
            value.firstname.toLowerCase().includes(term) ||
            value.middlename.toLowerCase().includes(term) ||
            value.email.toLowerCase().includes(term)
        )
      })
    })
  }
})

const backDoctor = () => {
  topManager.tabManager = 'ManagerList'
}
</script>

<style setup>
.progress-bar div {
  position: absolute;
  height: 100%;
}

.message-no-list {
  height: 300px;
}

.container-message {
  height: 50%;
  color: #aeaeae;
  font-size: larger;
  align-content: end;
}

.message-container {
  display: flex;
  justify-content: center;
  color: #7d7b7b;
  font-size: 14px;
}

.doctor-bonus {
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 20px
}

</style>
