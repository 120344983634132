import { defineStore } from 'pinia'

export const useTopManager = defineStore('topManager', {
    state: () => ({
        managers: [],
        managersInCreate: [],
        tabManager: 'ManagerList',
        doctors: [],
        selectedManagerFullName: '',
        isLoadingDoctor: false
    })
})
