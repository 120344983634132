<template>
    <component :is="layout + '-layout'" />
</template>

<script>
    import { computed, watchEffect } from 'vue'
    import { useRoute } from 'vue-router'
    import AuthLayout from '@/layouts/AuthLayout.vue'
    import MainLayout from '@/layouts/MainLayout.vue'

    export default {
        components: { MainLayout, AuthLayout },

        setup() {
            const route = useRoute()

            const layout = computed(() => route.meta.layout)
            const title = computed(() => route.meta.title)

            watchEffect(() => document.title = title.value)

            return { layout }
        }
    }
</script>
