<template>
    <div class="modal fade" id="infoAnalysisModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 1140px;">
            <div class="modal-content">
                <div class="modal-header pb-0">
                <div>
                </div>
                <div class="float-end order-badge" >
                </div>
                <div class="d-flex flex-column mb-3"> 
                    <button  type="button" class="btn-close close-button" @click="hideInfoAnalysisModal()"  aria-label="Close"></button>
                </div>
            </div>
                <div class="modal-body mt-1 px-4" >
            <div class="mb-2">
              <strong class="pr-2 description-container">Имя</strong>
              <span class="color-description" v-html="infoAnalysis?.name" />
            </div>
            <div class="mb-2" v-if="infoAnalysis?.description_client">
              <strong class="description-container">Описание</strong>
              <span
                class="color-description"
                v-html="infoAnalysis?.description_client"
              />
            </div>
            <div class="mb-2" v-else>
              <strong class="description-container">Описание</strong>
              <span class="color-description" v-html="'-'" />
            </div>
            <div class="mb-2">
              <strong class="description-container">Подготовка</strong>
              <span
                class="color-description"
                v-html="infoAnalysis?.preparation_client"
              />
            </div>
            <div class="mb-2" v-if="infoAnalysis?.biomaterials?.length > 0">
              <strong class="description-container">Биоматериалы:</strong>
              <span
                v-for="biomaterial in infoAnalysis?.biomaterials"
                :key="biomaterial.id"
                class="badge badge-primary color-description badge-container"
                >{{ biomaterial.name }}</span
              >
            </div>
            <div class="mb-2" v-else>
              <strong class="description-container">Биоматериалы:</strong>
              -
            </div>
          </div><div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideInfoAnalysisModal()" aria-label="Close">Закрыть</button> 
                </div>
                </div>
                
            </div>
        </div>
</template>
<script setup>
    import { defineProps, inject, onMounted, ref, computed } from 'vue'
    import { useOrderStore } from '@/stores/OrderStore'
    import { Modal } from 'bootstrap'
    const orderStore = useOrderStore()
    const props = defineProps(['infoAnalysis'])
    const infoAnalysisModal = inject('infoAnalysisModal')
    const assayId = ref(null)
    const hideInfoAnalysisModal = () => infoAnalysisModal.value.hide()
    onMounted(() => {
     infoAnalysisModal.value = new Modal('#infoAnalysisModal')
     })
</script>

<style lang="scss" scoped>
@media (max-width: 576px) { 
    .list-group-item { 
        display: flex; 
        flex-direction: column; 
        height: 65px;
        place-content: center;
    } 
}
.order-badge{
    position: absolute;
    right: 60px;
    top: 15px;
        @media (max-width: 576px) { 
            position: static;
        } 
}
.check-badge{
    position: absolute;
    right: 220px;
    top: 10px;
    @media (max-width: 576px) { 
        position: absolute;
        top: 0;
        left: 0;
        }
}
.close-button{
    margin-bottom: 20px;
}
.price-container{
    width: 260px;
    display: flex;
    justify-content: flex-end;
}
.description-container{
  padding-right: 10px
}
.badge-container{
  background-color: #007bff;
                  color: white;
                  margin-right: 10px;
}
</style>
