function clearNonNumeric(str) {
    // Очищаем строку от всех нецифровых символов
    return str ? str.replace(/\D+/g, '') : ''
}

export function formatPhoneNumberToSend(phone) {
    if (!phone) return

    let cleanPhone = clearNonNumeric(phone)

    // Убедимся, что номер содержит ровно 11 цифр
    if (cleanPhone.length > 11) {
        cleanPhone = cleanPhone.slice(-11)
    }

    // Возвращаем подстроку строки, начиная с индекса 1
    return '+7' + cleanPhone.substring(1)
}
