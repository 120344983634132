<template>
    <div style="background-color: #f2f2f2e8;" class="modal fade" id="changeTypeAnalyzeModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Изменение списка </h1>
                    <button type="button" class="btn-close" @click=hideDeleteFavoriteListModal aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    У вас уже есть выбранные анализы. При смене типа, анализы будут перераспределены. Продолжить?
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideDeleteFavoriteListModal">Закрыть</button>
                    <button type="button"
                            class="btn btn-danger position-relative"
                            :class="{'pe-none': favoriteListsStore.isLoading}"
                            @click="changeType"
                    >
                        Продолжить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted } from 'vue'
    import { Modal } from 'bootstrap'
    import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
    import axios from '@/services/axios'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
    import { useOrderStore } from '@/stores/OrderStore'
    const favoriteListsStore = useFavoriteListsStore()
    const orderStore = useOrderStore()

    const emit = defineEmits(['changeAnalyze'])
    const props = defineProps(['id'])
    const changeTypeAnalyzeModal = inject('changeTypeAnalyzeModal')
    
    const changeType = () => {
        emit('changeAnalyze')
        changeTypeAnalyzeModal.value.hide()
    }
    const hideDeleteFavoriteListModal = () => {
        favoriteListsStore.gender = favoriteListsStore.gender === 1 ? 2 : 1
        changeTypeAnalyzeModal.value.hide()
    }

    onMounted(() => changeTypeAnalyzeModal.value = new Modal('#changeTypeAnalyzeModal'))
</script>
