import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Error404 from '@/views/Error404.vue'
import Analyzes from '@/views/Analyzes.vue'
import Branches from '@/views/Branches.vue'
import Doctors from '@/views/Doctors.vue'
import DoctorsProfile from '@/components/Profile/Doctors.vue'
import AccessDenied from '@/views/AccessDenied.vue'
import Checkout from '@/views/Checkout.vue'
import Profile from '@/views/Profile.vue'
import Orders from '@/components/Profile/Orders.vue'
import Manager from '@/components/Profile/Manager.vue'
import PersonalData from '@/components/Profile/PersonalData.vue'
import Roles from '@/components/Profile/Roles.vue'
import Users from '@/components/Profile/Users.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import Managers from '@/views/Managers.vue'
import DoctorsList from '@/components/Doctors/DoctorsList.vue'
import DoctorsGenesis from '@/components/Doctors/DoctorsGenesis.vue'
import FavoriteLists from '@/components/FavoriteAnalyzes/FavoriteLists.vue'
import Patient from '@/components/Patient/Patient.vue'
import AddPatient from '@/components/Patient/Modals/AddPatient.vue'
import CreateFavoriteListModal from '@/components/FavoriteAnalyzes/CreateFavoriteListModal.vue'
import SuperManagerProfile from '@/components/SuperManager/SuperManagerProfile.vue'
import DoctorPrice from '@/components/SuperManager/DoctorPrice.vue'
import ChiefDoctor from '@/components/ChiefDoctor/ChiefDoctor.vue'
import Permission from '@/components/Permission/Permission.vue'
import GlobalFavoriteLists from '@/components/GlobalFavoriteAnalyzes/GlobalFavoriteLists.vue'
import GlobalCreateFavoriteListModal from '@/components/GlobalFavoriteAnalyzes/GlobalCreateFavoriteListModal.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            layout: 'main',
            auth: false,
            title: 'Главная - лендинг'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'auth',
            auth: false,
            title: 'Вход'
        }
    },
    {
        path: '/profile/topmanager',
        name: 'TopManager',
        component: SuperManagerProfile,
        meta: {
            layout: 'main',
            title: 'Личный кабинет - Менеджер',
            allowedRoles: ['manager']
        }
    },
    {
        path: '/profile/manager',
        name: 'ManagerProfile',
        component: Manager,
        meta: {
            layout: 'main',
            title: 'Личный кабинет - Менеджер',
            allowedRoles: ['doctor']
        }
    },
    {
        path: '/profile/price',
        name: 'DoctorPrice',
        component: DoctorPrice,
        meta: {
            layout: 'main',
            title: 'Сумма по докторам',
            allowedRoles: ['manager']
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Личный кабинет',
            allowedRoles: ['admin', 'doctor', 'staff']
        },
        children: [
            {
                path: 'doctors',
                name: 'DoctorsProfile',
                component: DoctorsProfile,
                meta: {
                    title: 'Личный кабинет -  Врачи',
                    allowedRoles: ['admin', 'staff']
                }
            },
            {
                path: 'manager',
                name: 'Manager',
                component: Manager,
                meta: {
                    title: 'Личный кабинет - Менеджер',
                    allowedRoles: ['doctor']
                }
            },

            {
                path: 'personal-data',
                name: 'PersonalData',
                component: PersonalData,
                meta: {
                    title: 'Личный кабинет - Личные данные'
                }
            },
            {
                path: 'roles',
                name: 'Roles',
                component: Roles,
                meta: {
                    title: 'Личный кабинет - Роли пользователей',
                    allowedRoles: ['admin']
                }
            },
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    title: 'Личный кабинет - Все пользователи',
                    allowedRoles: ['admin']
                }
            }
        ]
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Панель управления'
        }
    },
    {
        path: '/analyzes/:categoryId?',
        name: 'Analyzes',
        component: Analyzes,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Анализы'
        }
    },
    {
        path: '/branches',
        name: 'Branches',
        component: Branches,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Филиалы'
        }
    },
    {
        path: '/patients',
        name: 'Patient',
        component: Patient,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Мои пациенты',
            allowedRoles: ['doctor']
        }
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
        meta: {
            allowedRoles: ['doctor'],
            layout: 'main',
            title: 'Заказы'
        }
    },
    {
        path: '/favorite-lists',
        name: 'FavoriteLists',
        component: FavoriteLists,
        meta: {
            layout: 'main',
            title: 'Избранные списки',
            allowedRoles: ['doctor', 'chief-doctor'],
            auth: true
        }
    },
    {
        path: '/favorite-lists/createList',
        name: 'CreateFavoriteListModal',
        component: CreateFavoriteListModal,
        meta: {
            layout: 'main',
            title: 'Создать список',
            allowedRoles: ['doctor', 'chief-doctor']
        }
    },
    {
        path: '/global-favorite-lists',
        name: 'GlobalFavoriteLists',
        component: GlobalFavoriteLists,
        meta: {
            layout: 'main',
            title: 'Избранные списки',
            allowedRoles: ['chief-doctor']
        }
    },
    {
        path: '/global-favorite-lists/createList',
        name: 'GlobalCreateFavoriteListModal',
        component: GlobalCreateFavoriteListModal,
        meta: {
            layout: 'main',
            title: 'Создать список',
            allowedRoles: ['chief-doctor']
        }
    },
    {
        path: '/doctors',
        name: 'Doctors',
        component: Doctors,
        redirect: { name: 'DoctorsList' },
        meta: {
            layout: 'main',
            auth: true,
            title: 'Список докторов',
            allowedRoles: ['admin', 'staff']
        },
        children: [
            {
                path: 'list',
                name: 'DoctorsList',
                component: DoctorsList
            },
            {
                path: 'genesis',
                name: 'DoctorsGenesis',
                component: DoctorsGenesis
            }
        ]
    },
    {
        path: '/managers',
        name: 'Managers',
        component: Managers,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Менеджеры',
            allowedRoles: ['admin']
        }
    },
    {
        path: '/chief-doctor',
        name: 'ChiefDoctor',
        component: ChiefDoctor,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Главный доктор',
            allowedRoles: ['chief-doctor']
        }
    },
    {
        path: '/order-items',
        name: 'OrderItems',
        component: Checkout,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Оформление заказа',
            allowedRoles: ['admin', 'doctor', 'staff', 'chief-doctor']
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Оформление заказа',
            allowedRoles: ['admin', 'doctor']
        }
    },
    {
        path: '/reset-password/:token',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            layout: 'auth',
            auth: false,
            title: 'Восстановление пароля'
        }
    },
    {
        path: '/access-denied',
        name: 'AccessDenied',
        component: AccessDenied,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Недостаточно прав'
        }
    },
    {
        path: '/permissions',
        name: 'Permissions',
        component: Permission,
        meta: {
            layout: 'main',
            auth: true,
            title: 'Права доступ для роли',
            allowedRoles: ['admin']
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: Error404,
        meta: {
            layout: 'main',
            title: 'Страница не существует'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const requiredAuth = to.meta.auth
    const allowedRoles = to.meta.allowedRoles
    const authStore = useAuthStore()

    function checkRouter() {
        if (authStore.isDoctor || authStore.isAdmin) {
            return router.push({ name: 'Analyzes' })
        } else if (authStore.isManager) {
            return router.push({ name: 'TopManager' })
        } else if (authStore.isChiefDoctor) {
            return router.push({ name: 'FavoriteLists' })
        } else {
            return router.push({ name: 'Doctors' })
        }
    }

    // Редирект с '/' на '/analyzes', если пользователь авторизован
    if (to.path === '/' && authStore.isAuthenticated) {
        checkRouter()
    }

    // Редирект с '/' на '/login', если пользователь не авторизован
    if (to.path === '/' && !authStore.isAuthenticated) {
        return next('/login')
    }

    // Если пользователь пытается зайти на '/login', будучи авторизованным
    if (to.path === '/login' && authStore.isAuthenticated) {
        checkRouter()
    }

    // Если для маршрута требуется авторизация и пользователь не авторизован
    if (requiredAuth && !authStore.isAuthenticated) {
        return next('/login')
    }

    // Проверка на разрешенные роли
    if (allowedRoles && !allowedRoles.includes(authStore.userProfile.role)) {
        return next('/access-denied')
    }
    next()
})

export default router
