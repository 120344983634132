<template>
    <div id="top-element"></div>
  <div >
    <table class="table table-hover">
      <thead>
      <tr>
      <td>
        <div class="p-0" :class="['wrapper rounded ', {'border': shouldShowAccordion}]">
          <div class="row">
          <div class="col-sm">
          <div class="input-container">
    <div class="container-search">
        <div class='position-relative'>
            <input 
                type="text" 
                class="form-control w-100" 
                v-model="searchApplicationsQuery"
                @input="setSearch(searchApplicationsQuery)"
                placeholder="Поиск по ФИО пациента" 
            />
            <div v-if="searchApplicationsQuery !== ''" class="clear-button" @click="clearSearchResult">
            </div>
        </div> </div>
      </div></div>
        </div>
  <div class="row align-items-center">
    <div class="col-md-5 col-6">
      <label for="startDateOrder" class="date-picker-label ">Дата начала
        <input class="date-picker form-control"
               type="date"
               id="startDateOrder"
               placeholder="Дата начала"
               min="2012-01-01"
               :max="getTodayDate()"
               @input="setStartDate(startDateApplication)"
               v-model="startDateApplication"
        />
        <div v-if="startDateApplication" @click="clearStartResultCompleted()" class="clear">+</div>
      </label>
    </div>
    <div class="col-md-5 col-6">
      <label for="endDateOrder" class="date-picker-label mb-0">Дата окончания
        <input class="date-picker form-control"
               type="date"
               id="endDateApplication"
               placeholder="Дата окончания"
               min="2012-01-01"
               :max="getTodayDate()"
               @input="setEndDate(endDateApplication)"
               v-model="endDateApplication"
        />
        <div v-if="endDateApplication" @click="clearEndResultCompleted()" class="clear">+</div>
      </label>
    </div>
    <div class="col-md-auto group-search-btn">
      <button :disabled="!checkSearch() || orderStore.loadingCompleted" @click="filterApplications" type="button" class="btn btn-secondary order-search">
        Поиск
      </button>
      <button  :disabled="checkSearch() || orderStore.loadingCompleted" type="button" class="btn btn-secondary clear-application" @click="clearSearch()">
        Очистить
      </button>
    </div>
  </div></div>
      </td>

      </tr>
      </thead>
    </table>
  </div>
    <div :class="{'not-ps' : isMobile }"  :style="{ height: !isMobile ? 'calc(100vh - 374px)' : 'auto'}"  class="mt-3" >
        <table class="table table-hover">
          <LoadingSpinner spinnerStyle="min-height: var(--ps-height);" :showSpinner="true"
          v-if="orderStore.loadingCompleted"/>
      <tbody >
        <div class="text-center" v-if="!orderStore.completedOrders.length && !orderStore.loadingCompleted">
        <p class="text-uppercase h4 no-order" >Нет заявок</p>
        </div>
      <tr class="list-order" v-for="order in orderStore.completedOrders" :key="order.id" @click="showEditOrderModal(order)">
        <td class="td-datetime">
          <span class="container-date"><b
            class="d-none d-sm-inline ms-0 text-wrap">&nbsp;</b>{{ formatDate(order.updated_datetime) }} &nbsp;&nbsp;</span>
        </td>
        <td  class="td-full-name">
          <p v-if="order?.firstName"> 
            {{ order?.lastName }} {{ order?.firstName?.charAt(0) }}
            <span v-if="order?.firstName">.</span> 
            {{ order?.middleName?.charAt(0) }}
            <span v-if="order?.middleName">.</span>
            </p>
        </td>
        <td class="td-status">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#completedOrders-collapseTwo-${order.id}`" aria-expanded="false">  
            <OrderStatusBadge :type="order.status" class="ms-auto d-sm-block d-none"/>
            <OrderStatusBadge :type="order.status" class="d-sm-none d-flex"/>
           <span class="price-order"> {{ calculateAssay(order) }} &#8381; </span>
          </button>
        </td>
        
      </tr>
      <div ref="loadMoreTriggerCompleted" id="loading-trigger-completed"><span class="w-50 trigger-loading"></span></div>
      </tbody>
    </table>
  </div>
    <EditOrderModal  :orderDetails="orderDetails"/>
    <DeleteOrderModal :orderDetails="orderDetails"/>
</template>

<script setup>
import { onMounted, ref, provide, computed, onBeforeUnmount } from 'vue'
import { useOrderStore } from '@/stores/OrderStore'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import { formatUnixTimestampOnlyDate, formatDateHelper, formatDate } from '@/helpers/dateHelpers'
import EditOrderModal from '@/components/Profile/Modals/EditOrderModal.vue'
import DeleteOrderModal from '@/components/Profile/Modals/DeleteOrderModal.vue'
import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { useAuthStore } from '@/stores/AuthStore'

const orderStore = useOrderStore()
const analyzesStore = useAnalyzesStore()
const authStore = useAuthStore()
const editOrderModal = ref(null)
const deleteOrderModal = ref(null)
const orderDetails = ref(null)
const startDateApplication = ref('')
const endDateApplication = ref('')
const searchApplicationsQuery = ref('')
const loadMoreTrigger = ref(null)
const isLoadingMoreData = ref(false)
const isMobile = ref(window.innerWidth <= 575.98)
let observerCompleted = null
const calculateAssay = (order) => {
  if (!order?.assays?.length) return 0
  const price = order.assays
    .map(code => analyzesStore.analyzes.find(analysis => analysis.code === code)?.price || 0)
    .reduce((acc, price) => acc + price, 0)
  return price
}
const setSearch = (search) => {
  orderStore.searchQueryCompleted = search
}
const setStartDate = (startDate) => {
  orderStore.dateFromCompleted = startDate
}
const setEndDate = (endDate) => {
  orderStore.dateToCompleted = endDate
}
const checkSearch = () => {
  if (endDateApplication.value && endDateApplication.value !== '') {
    return true
  }
  if (startDateApplication.value && startDateApplication.value !== '') {
    return true
  }
  if (searchApplicationsQuery.value.length >= 2) {
    return true
  }
  return false
}
const checkClearSearch = () => { 
  if (endDateApplication.value && endDateApplication.value !== '') {
    return true
  }
  if (startDateApplication.value && startDateApplication.value !== '') {
    return true
  }
  if (searchApplicationsQuery.value.length < 2) {
    return true
  }
  return false
}
const clearStartResultCompleted = () => {
  startDateApplication.value = ''
  orderStore.dateFromCompleted = null
}
const clearEndResultCompleted = () => {
  endDateApplication.value = ''
  orderStore.dateToCompleted = null
}
const clearSearchResult = () => {
  orderStore.searchQueryCompleted = null
  searchApplicationsQuery.value = ''
}

const getTodayDate = () => {
  const today = new Date().toISOString().split('T')[0]
  return today
}
const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}
const loadMoreDataCompleted = () => {
  if (!isLoadingMoreData.value) {
    isLoadingMoreData.value = true
    orderStore.getCompletedOrders().finally(() => {
      isLoadingMoreData.value = false
    })
  }
}

const clearSearch = () => {
  orderStore.searchQueryCompleted = null
  searchApplicationsQuery.value = ''
  endDateApplication.value = ''
  orderStore.dateToCompleted = null
  startDateApplication.value = ''
  orderStore.dateFromCompleted = null
  orderStore.setCompletedDates(1, startDateApplication.value, endDateApplication.value, searchApplicationsQuery.value)
  orderStore.getCompletedOrders()
}

const filterApplications = () => {
  orderStore.setCompletedDates(1, startDateApplication.value, endDateApplication.value, searchApplicationsQuery.value)
  orderStore.getCompletedOrders()
}

const handleIntersectionCompleted = (entries) => {
  const entry = entries[0]
  if (entry.isIntersecting && !isLoadingMoreData.value) {
    loadMoreDataCompleted()
  }
}

provide('editOrderModal', editOrderModal)
provide('deleteOrderModal', deleteOrderModal)

const setOrderData = orderData => orderDetails.value = orderData

const showEditOrderModal = (order) => {
  orderDetails.value = order
  orderStore.renderEditOrder = false
  editOrderModal.value.show()
}

const convertedOrders = computed(() => {
  if (orderStore.completedOrders) {
    return orderStore.completedOrders.map(order => {
      return {
        ...order,
        convertedAssays: analyzesStore.convertAssaysIdToName(order.assays)
      }
    })
  } else {
    return orderStore.completedOrders
  }
})

onMounted(() => {
  orderStore.searchQueryCompleted ? searchApplicationsQuery.value = orderStore.searchQueryCompleted : searchApplicationsQuery.value = ''
  orderStore.dateToCompleted ? endDateApplication.value = orderStore.dateToCompleted : endDateApplication.value = null
  orderStore.dateFromCompleted ? startDateApplication.value = orderStore.dateFromCompleted : startDateApplication.value = null
  orderStore.setCompletedDates(1, startDateApplication.value, endDateApplication.value, searchApplicationsQuery.value)
  const options = {
    rootMargin: '0px 0px 0px 0px',
    threshold: 0
  }
  observerCompleted = new IntersectionObserver(handleIntersectionCompleted, options)
  const loadingTrigger = document.getElementById('loading-trigger-completed')
  if (loadingTrigger) observerCompleted.observe(loadingTrigger)
})
</script>
<style scoped lang="scss">
.price-order {
  width:120px; text-align: end; padding-right: 10px;
  @media (max-width: 575.98px) {
      font-size: 13px;
      
  }
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

.accordion-button {
  @media (max-width: 575.98px) {
      padding: 0;
      height: 60px;
      justify-content: space-between;
    }
  &:after {
    margin-left: 0;
    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.accordion-main-style {
  button.accordion-button {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}

.tab-menu {
  display: flex;
  justify-content: space-between;
}

.tab-menu button {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tab-menu button.active {
  background-color: #149cdd;
  color: white; // Цвет текста на активном табе
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}
.no-order{
 color: #b0b0b0;
 position: relative;
    top: 20%;
    width: 100%;
}
.clear-search-input {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: transparent;
}

.clear-button {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: transparent;
}

.clear-button::before,
.clear-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
    background-color: black;
}

.clear-button::before {
    transform: rotate(45deg);
}

.clear-button::after {
    transform: rotate(-45deg);
}
.order-search{
  margin-right: 16px;
  @media (max-width: 575.98px) {
    margin-left: 5px;
  }
}
.container-mobile{
  width:150px;
  @media (max-width: 575.98px) {
    padding-top: 0;
    padding-right: 0;
}
}
.container-date{
width: auto;
    @media (max-width: 575.98px) { 
    width: 80px;
    font-size: 16px;
    }
}
.container-fio{
  @media (max-width: 575.98px) {
    font-size: 16px;
        white-space: nowrap;
        overflow-wrap: anywhere;
        margin-top: 0;
        -webkit-hyphens: auto;
        hyphens: auto;
}
}
.clear-application{
  width: 115px;
  @media (max-width: 575.98px) {
    margin-left: 5px; 
}
}
.input-container {
  margin-bottom: 10px; 
  position: relative; 
  display: flex; 
  margin-top: 20px;
}
.group-search-btn{
  padding: 0px;
    margin-top: 20px;
    margin-left: 5px;    align-self: flex-end;
}
.container-search{
  flex: 1;
}
.list-order{
  width: 100%; border-bottom: 1px solid #dddddd; cursor: pointer;
}
.trigger-loading{
  display: flex;
  height: 50px;
}
.order-list-container{
    border-collapse: collapse;
    width: 100%;

}

.td-datetime {
  width: 175px;
  @media screen and (max-width: 800px) {
    align-content: center;
    width: 80px;
    padding-left: 0;
    span {
      font-size: 13px;
      display: flex;
    align-items: center;
    }
  }

}

.td-full-name {
  width: auto;
  align-content: center;
}
.td-full-name p {
  text-align: left;
  font-weight: bold;
  margin:0;
  padding:0;
  @media screen and (max-width: 800px) {
    text-align: left;
    white-space: nowrap;
    font-size: 13px;
  }
}
.td-status{
  @media screen and (max-width: 800px) {
  padding-right: 0;
  }
}

</style>
