<template>
    <div class="row d-none d-sm-flex mx-3 mb-4" style="margin-top: -5px">
        <div class="col-6 d-flex align-items-center">
            <h2 class="text-uppercase h4 m-0"><i class="bi bi-people fs-4 me-2" />Пользователи</h2>
        </div>
        <div class="col-6">
            <button type="button" class="btn btn-success float-end" @click="showCreateUserModal">
                <i class="bi bi-plus-circle me-1" />
                Создать пользователя
            </button>
        </div>
    </div>

    <div class="row mx-0 mx-sm-3 mb-3 mb-sm-0">
        <div class="col-12 container-doctor">
                <ul class="list-group list-group-main-style" v-if="usersStore.users">
                    <li class="list-group-item p-3" v-for="user in usersStore.users" :key="user.id">
                        <h4 class="mb-1 d-flex align-items-center">
                          {{ user.last_name }} {{ user.first_name }} {{ user.middle_name }}
                            <ActiveStatus :type="user.active" class="user-select-none" />
                        </h4>
                        <p class="mb-1"><b>Последний онлайн:</b> {{ user.online_at ? user.online_at : '-' }}</p>
                        <p class="mb-1"><b>Почта:</b> {{ user.email ? user.email : '-' }}</p>
                        <p class="mb-1"><b>Телефон:</b> {{ user.phone ? phoneMask(user.phone) : `-` }}</p>
                        <p v-if="user.role === 'staff'" class="mb-1"><b>Роль:</b> Менеджер</p>
                        <p v-if="user.role === 'admin'" class="mb-1"><b>Роль:</b> Администратор</p>
                        <p v-if="user.role === 'doctor'" class="mb-1"><b>Роль:</b> Доктор</p>
                        <p v-if="user.role === 'manager'" class="mb-1"><b>Роль:</b> Региональный менеджер</p>

                        <div class="mt-3 row row-cols-2 row-cols-sm-1 g-1 buttons-wrapper">

                            <div class="col">
                                <button type="button"
                                        class="btn btn-sm btn-primary me-2"
                                        @click="setUserData(user); showEditUserModal()"
                                >
                                    <i class="bi bi-pencil-square" />
                                    Редактировать
                                </button>
                                <button type="button"
                                        class="btn btn-sm btn-secondary me-2 position-relative mt-2 mt-md-0"
                                        :class="{'btn-secondary': user.active, 'btn-success': !user.active, 'pe-none': usersStore.isLoading}"
                                        @click="usersStore.activeStatusUserSwitcher(user.id)"
                                >
                                    <i class="bi bi bi-shield-x" v-if="user.active" />
                                    <i class="bi bi-shield-plus" v-if="!user.active" />
                                    {{ user.active ? 'Заблокировать' : 'Разблокировать' }}

                                    <span v-if="usersStore.activeStatusLoading[user.id]" class="placeholder-glow placeholder-surface">
                                    <span class="placeholder" />
                                </span>
                                </button>
                                <button v-if="user.email"
                                        type="button"
                                        class="btn btn-sm btn-outline-danger position-relative mt-2 mt-md-0"
                                        @click="usersStore.getPasswordResetUrl(user.email, user.id)"
                                >
                                    <i class="bi bi-arrow-clockwise"></i>
                                    Сбросить пароль

                                    <span v-if="usersStore.passwordResetLoading[user.id]" class="placeholder-glow placeholder-surface">
                                    <span class="placeholder" />
                                </span>
                                </button>
                            </div>

                        </div>
                    </li>
                </ul>

                <LoadingSpinner :showSpinner="true" spinnerStyle="min-height: var(--ps-height);" v-if="usersStore.users.length === 0" />
        </div>
    </div>

    <CreateUser />
    <EditUserModal :userDetails="userDetails" />

</template>

<script setup>
    import { useUsersStore } from '@/stores/UsersStore'
    import { onMounted, ref, provide, onBeforeUnmount } from 'vue'
    import CreateUser from '@/components/Profile/Modals/CreateUser.vue'
    import ActiveStatus from '@/components/Profile/Badges/ActiveStatus.vue'
    import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
    import EditUserModal from '@/components/Profile/Modals/EditUserModal.vue'

    const usersStore = useUsersStore()
    const phoneMask = (phone) => {
    if (phone) {
        const x = phone
            .replace(/\D/g, '')
            .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
        x[1] = '+7'
        phone = !x[3]
            ? x[1] + '(' + x[2]
            : x[1] +
              '(' +
              x[2] +
              ')' +
              x[3] +
              (x[4] ? '-' + x[4] : '') +
              (x[5] ? '-' + x[5] : '')
    }
    return phone
}
    const userDetails = ref(null)
    const createUserModal = ref(null)
    const editUserModal = ref(null)

    provide('createUserModal', createUserModal)
    provide('editUserModal', editUserModal)

    const setUserData = userData => userDetails.value = userData
    const showCreateUserModal = () => createUserModal.value.show()
    const showEditUserModal = () => editUserModal.value.show()

    /*
        Расчет высоты нужен так как header становится больше за счет высоты меню,
        которое находится по самим заголовком в мобильной версии.
     */

    const resizeHandler = () => {
        const mediaQuery = window.matchMedia('(max-width: 575.98px)')

        if (mediaQuery.matches) {
            const listGroupElement = document.querySelector('.menu-wrapper')

            if (listGroupElement) {
                // Посчитываю высоту меню так как для разных пользователей отображаются разные пункты меню
                const computedStyle = window.getComputedStyle(listGroupElement)
                const marginTop = parseFloat(computedStyle.marginTop)
                const marginBottom = parseFloat(computedStyle.marginBottom)
                const height = listGroupElement.offsetHeight + marginTop + marginBottom
                document.documentElement.style.setProperty('--header-height', `${height + 78}px`)
            }
        } else {
            // Вернуть значение переменной к исходному состоянию, если разрешение больше 575.98px
            document.documentElement.style.setProperty('--header-height', null)
        }
    }

    onMounted(() => {
        usersStore.fetchUsers()

        // Вызов при монтировании
        resizeHandler()

        // Подписка на событие resize
        window.addEventListener('resize', resizeHandler)
    })

    onBeforeUnmount(() => {
        // Восстановление первоначального значения
        document.documentElement.style.setProperty('--header-height', null)
        window.removeEventListener('resize', resizeHandler)
    })
</script>

<style scoped lang="scss">
    .ps {
        max-height: calc(var(--ps-height));
        box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
        border-radius: var(--bs-border-radius);

        @media (min-width: 576px) {
            max-height: calc(var(--ps-height) - 57px);
        }

        .list-group {
            .list-group-item {
                .buttons-wrapper {
                    @media (min-width: 576px) {
                        flex-wrap: nowrap;
                    }

                    .col {
                        @media (min-width: 576px) {
                            width: fit-content;
                            flex-shrink: 1;
                        }

                        button {
                            @media (max-width: 575.98px) {
                                width: 100%;
                            }

                            //Минимальная ширина для текста кнопок в одну линию
                            @media (max-width: 390px) {
                                font-size: calc(var(--bs-btn-font-size) * 0.9);
                            }
                        }
                    }
                }
            }
        }
    }
    .container-doctor{
    margin-bottom: 50px;
}
</style>
