import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
import router from '@/router'

export async function selectAnalysis(analysisId) {
    try {
        const favoriteList = useFavoriteListsStore()
        const result = await axios.get(`/api/analyzes/groups/${analysisId}`)
        if (result.data.success && result.data.data) {
            favoriteList.favoriteAnayze = result.data.data
        } else if (result.data.message) {
            return showErrorNotification(result.data.message)
        } else {
            return showErrorNotification('Ошибка сервера')
        }
    } catch (error) {
        if (error.response.data.message) {
            showErrorNotification(error.response.data.message)
        } else {
            showErrorNotification('Ошибка сервера')
        }
    }
}

export async function fetchFavoriteListsGlobal() {
    const favoriteList = useFavoriteListsStore()
    await axios.get('/api/doctor/global/favorite-list')
        .then((response) => {
            if (response.data.success && response.data.data) {
                favoriteList.favoriteLists = response.data.data
                showSuccessNotification(response.data.message)
            } else if (response.data.message) {
                showErrorNotification(response.data.message)
            } else {
                showErrorNotification('Ошибка сервера')
            }
        })
        .catch(error => {
            if (error.response.data.message) {
                showErrorNotification(error.response.data.message)
            } else {
                showErrorNotification('Ошибка сервера')
            }
        })
        .finally(() => this.isLoading = false)
}

export function updateList() {
    const favoriteList = useFavoriteListsStore()
    favoriteList.isloadingList = false
    if (!favoriteList.listName) {
        favoriteList.isloadingList = true
        return showErrorNotification('Не введено название')
    }
    if (!favoriteList.gender) {
        favoriteList.isloadingList = true
        return showErrorNotification('Непередан код')
    }
    if (Number(favoriteList.gender) === 1 && !favoriteList.selectedAnalyzes.all.length) {
        favoriteList.isloadingList = true
        return showErrorNotification('Анализы не выбраны')
    }
    if (Number(favoriteList.gender) === 2 && (favoriteList.selectedAnalyzes.male.length + favoriteList.selectedAnalyzes.female.length === 0)) {
        favoriteList.isloadingList = true
        return showErrorNotification('Анализы не выбраны')
    }
    const formData = {
        'name': favoriteList.listName,
        'type': favoriteList.gender
    }
    axios.patch(`api/doctor/global/favorite-list/${favoriteList.listId}`, formData)
        .then((response) => {
            if (response.data.success && response.data.data) {
                updateFavoriteList(favoriteList.listId, favoriteList.gender)
                favoriteList.isloadingList = true
            } else if (response.data.message) {
                favoriteList.isloadingList = true
                showErrorNotification(response.data.message)
            } else {
                favoriteList.isloadingList = true
                showErrorNotification('Ошибка сервера')
            }
        }).catch(error => {
        if (error.response.data.message) {
            favoriteList.isloadingList = true
            showErrorNotification(error.response.data.message)
        } else {
            favoriteList.isloadingList = true
            showErrorNotification('Ошибка сервера')
        }
    }).finally(() => favoriteList.isloadingList = true)
}

export function createList() {
    const favoriteList = useFavoriteListsStore()
    if (!favoriteList.listName) {
        favoriteList.isloadingList = true
        return showErrorNotification('Не введено название')
    }
    if (!favoriteList.gender) {
        favoriteList.isloadingList = true
        return showErrorNotification('Непередан код')
    }
    if (Number(favoriteList.gender) === 1 && !favoriteList.selectedAnalyzes.all.length) {
        favoriteList.isloadingList = true
        return showErrorNotification('Анализы не выбраны')
    }
    if (Number(favoriteList.gender) === 2 && (favoriteList.selectedAnalyzes.male.length + favoriteList.selectedAnalyzes.female.length === 0)) {
        favoriteList.isloadingList = true
        return showErrorNotification('Анализы не выбраны')
    }
    const formData = {
        'name': favoriteList.listName,
        'type': favoriteList.gender
    }
    axios.post('api/doctor/global/favorite-list', formData)
        .then((response) => {
            if (response.data.success && response.data.data) {
                updateFavoriteList(response.data.data.id, favoriteList.gender)
                favoriteList.isloadingList = true
            } else if (response.data.message) {
                showErrorNotification(response.data.message)
                favoriteList.isloadingList = true
            } else {
                showErrorNotification('Ошибка сервера')
                favoriteList.isloadingList = true
            }
        }).catch(error => {
        const response = error.response.data
        if (response?.message) {
            showErrorNotification(response.message)
            if (response?.errors && response?.errors.length) {
                response.errors.forEach(err => {
                    showErrorNotification(err)
                })
            }
            favoriteList.isloadingList = true
        } else {
            showErrorNotification('Ошибка сервера')
            favoriteList.isloadingList = true
        }
    })
}

export async function updateFavoriteList(favoriteListId, type) {
    const favoriteList = useFavoriteListsStore()
    axios.post(`/api/doctor/global/favorite-analyzes/${favoriteListId}`, {
        'analyzes': favoriteList.selectedAnalyzes
    }).then(response => {
        if (response.data.success && response.data.data) {
            showSuccessNotification(response.data.message)
            favoriteList.selectedAnalyzes.all = []
            router.push({ name: 'GlobalFavoriteLists' })
        } else if (response.data.message) {
            showErrorNotification(response.data.message)
        } else {
            showErrorNotification('Ошибка сервера')
        }
    }).catch(error => {
            if (error.response.data.message) {
                showErrorNotification(error.response.data.message)
            } else {
                showErrorNotification('Ошибка сервера')
            }
    }
    )
}

export async function deleteFavoriteList(favoriteListId) {
    const favoriteList = useFavoriteListsStore()
    const listToSave = favoriteList.favoriteLists.find(list => list.id === favoriteListId)
    await axios.delete(`/api/doctor/favorite-analyzes/${listToSave.id}`)
        .then(response => {
            if (response.data.success && response.data.data) {
                favoriteList.favoriteLists = favoriteList.favoriteLists.filter(list => list.id !== favoriteListId)
                showSuccessNotification(response.data.message)
            } else if (response.data.message) {
                showErrorNotification(response.data.message)
            } else {
                showErrorNotification('Ошибка сервера')
            }
        })
        .catch(error => {
            if (error.response.data.message) {
                    showErrorNotification(error.response.data.message)
                } else {
                    showErrorNotification('Ошибка сервера')
                }
            }
        )
        .finally(() => favoriteList.isLoading = false)
}
