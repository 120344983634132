<template>
    <div class="modal fade" id="addDoctorModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Добавить врача</h1>
                    <button type="button" class="btn-close" @click="hideAddDoctorModal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="form-floating mb-0">
                        <input type="email" class="form-control mb-0" id="email" v-model="doctorEmail" @input="validateEmail" placeholder="Почта">
                        <label for="email">Почта</label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>

                    <button type="button"
                            :disabled="!isEmailValid"
                            class="btn btn-primary position-relative"
                            @click="createDoctor"
                    >
                        Добавить
                        <span v-if="doctorsStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>

                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
    import { useDoctorsStore } from '@/stores/DoctorsStore'
    import { inject, onMounted, ref } from 'vue'
    import { Modal } from 'bootstrap'
    import { showErrorNotification } from '@/services/notifications'

    const doctorsStore = useDoctorsStore()
    const props = defineProps(['selectedDoctorId'])
    const addDoctorModal = inject('addDoctorModal')
    const doctorEmail = ref(null)
    const isEmailValid = ref(false)

    const hideAddDoctorModal = () => addDoctorModal.value.hide()

    const createDoctor = () => {
        doctorsStore.createDoctor(props.selectedDoctorId, doctorEmail.value)
            .then(() => {
                hideAddDoctorModal()
                doctorsStore.clearSearchResult()
            })
            .catch(error => showErrorNotification(error))
    }

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        isEmailValid.value = emailRegex.test(doctorEmail.value)
    }

    onMounted(() => addDoctorModal.value = new Modal('#addDoctorModal'))
</script>

<style lang="scss" scoped>
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .handle-add-doctor {
                        position: absolute;
                        right: 1rem;
                        bottom: 1rem;

                        button {

                        }
                    }
                }
            }
        }
    }
</style>
