<template>
    <div class="modal fade" id="createUserModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <form class="modal-content" @submit.prevent="createUser">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Создание пользователя</h1>
                    <button type="button" class="btn-close" @click="hideCreateUserModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="usersStore.lastName">
                    <label for="lastName">Фамилия</label>
                  </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="usersStore.firstName">
                        <label for="firstName">Имя</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="middleName" placeholder="Отчество" v-model="usersStore.middleName">
                        <label for="middleName">Отчество</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="emailAddress" placeholder="Электронная почта" v-model="usersStore.emailAddress">
                        <label for="emailAddress">Электронная почта</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="tel" class="form-control" id="phoneNumber" placeholder="Телефон" v-maska data-maska="+7 (###) ###-##-##" v-model="usersStore.phoneNumber">
                        <label for="phoneNumber">Телефон</label>
                    </div>

                    <div class="form-floating mb-3">
                        <select class="form-select" id="userRoles" v-model="usersStore.selectedRole">
                            <option disabled value="null">Выберите роль пользователя</option>
                            <option v-for="role in usersStore.roles" :key="role.id" :value="role.name">
                                {{ role.title }}
                            </option>
                        </select>
                        <label for="userRoles">Роли</label>
                    </div>
                    <div v-if="usersStore.selectedRole === 'staff'" class="form-floating ">
                        <select class="form-select" id="userRoles" v-model="usersStore.managerId">
                            <option disabled value="null">Выберите топ менеджера</option>
                            <option v-for="manager in topManager.managersInCreate" :key="manager.id" :value="manager.id">
                                {{ manager.lastname }} {{ manager.firstname }} {{ manager.middlename }}
                            </option>
                        </select>
                        <label for="userRoles">Региональный менеджеры</label>
                    </div>

                    <div v-if="messageFromSuccessResponse" class="alert alert-success mt-3 mb-0" role="alert">
                        {{ messageFromSuccessResponse }}
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideCreateUserModal">Закрыть</button>
                    <button type="submit" class="btn btn-primary position-relative">
                        Сохранить
                        <span v-if="usersStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { inject, onMounted, ref } from 'vue'
    import { Modal } from 'bootstrap'
    import { useUsersStore } from '@/stores/UsersStore'
    import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
    import { vMaska } from 'maska'
    import { getTopManager } from '@/api/TopManager'
    import { useTopManager } from '@/stores/TopManager'

    const topManager = useTopManager()
    const usersStore = useUsersStore()
    const messageFromSuccessResponse = ref(null)
    const createUserModal = inject('createUserModal')

    const hideCreateUserModal = () => createUserModal.value.hide()

    const createUser = () => usersStore.createUser()
        .then(data => {
            if (data) {
              usersStore.firstName = null
              usersStore.middleName = null
              usersStore.lastName = null
              usersStore.emailAddress = null
              usersStore.phoneNumber = null
              usersStore.selectedRole = null
              usersStore.managerId = null
              usersStore.isLoading = null
              usersStore.passwordResetLoading = {}
              usersStore.activeStatusLoading = {}
              getTopManager()
              hideCreateUserModal()
            }
        })
        .catch(error => showErrorNotification(error))

    onMounted(() => { 
        createUserModal.value = new Modal('#createUserModal')
        getTopManager()
    })
</script>
