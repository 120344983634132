<template>
  <table class="table">
    <tbody>
      <tr
        v-for="assay in cartStore.FormattedAnalyzes"
        :key="assay.id + assay.code"
      >
        <td>
          <div class="analyze-block">
            <div>
              <code>{{ assay.code }}</code> 
              <p >{{  assay.name }}</p>
            </div>
            <div class="analyze-block-price-block">
              {{ assay.price ? assay.price + ' &#8381;' : "Цена не указана" }}
            </div>
          </div>  
        </td>
        <td class="td-vertical-align-middle td-cart-control">
          <CartControll
            :code="assay.code"
            v-if="cartStore.isAssayExist(assay.code)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<style lang="scss" scoped>
.price {
  width: 80px;
  @media (max-width: 576px) {
    display: none;
  }
}
.price-mobile {
  display: none;
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
  }
}

.analyze-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  } 
  .analyze-block-price-block {
    white-space: nowrap;
  }
  div{
    @media screen and (min-width: 800px) {
    display: inline-flex;
    align-items: center;
    align-content: center;
    }
  }
  p{
    @media screen and (min-width: 600px) {
    margin: auto;
    margin-left: 10px;
    }
  }
}
.td-cart-control{
  @media screen and (min-width: 600px) {
    width: 100px;
  }
}
</style>
<script setup>
import { useOrderStore } from '@/stores/OrderStore'
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { userCartStore } from '@/stores/CartStore'
import CartControll from '@/components/Cart/Controlls/CartControll.vue'

const cartStore = userCartStore()

const orderStore = useOrderStore()
const analyzesStore = useAnalyzesStore()

const assaysInCart = ref(null)
const componentData = reactive({
  analyzes: []
})

const isAnalysisInCart = (code) => orderStore.assaysInCart.includes(code)

const toggleAssayInCart = (analysis) => {
  isAnalysisInCart(analysis.code)
  orderStore.removeAssayFromCart(analysis.code)
}
</script>
