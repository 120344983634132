import { defineStore } from 'pinia'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import { useAuthStore } from '@/stores/AuthStore'

export const useFavoriteListsStore = defineStore('favoriteLists', {
    state: () => ({
        isloadingList: true,
        favoriteAnayze: [],
        favoriteLists: [],
        listName: '',
        gender: 1,
        isLoading: false,
        selectedAnalyzes: {
            all: [],
            male: [],
            female: []
        },
        listId: null,
        listType: null,
        idDeleteList: 0,
        descriptionAnalysis: {}
    }),

    getters: {
        userId() {
            return useAuthStore().userProfile.id
        }
    },
    action: {
        clearSelectedAnalyzes() {
            this.selectedAnalyzes = {
                all: [],
                male: [],
                female: []
            }
        }
    }
})
