<template> 
<div class="col-sm-6 d-flex align-items-center mt-3"><div id="top-element"></div>
            <h2 class="text-uppercase h4 m-0"> <i class="bi bi-person-vcard fs-4 me-2" />Мои менеджеры</h2>
        </div>
        <input v-if="topManager.managers && topManager.managers.length"
        type="text"
        class="form-control mb-3 mt-5"
        placeholder="Поиск менеджера"
        v-model="searchQuery"
      />
                  <div  class="text-center message-and-spinner" v-if="filteredAndSearchedManagers.length === 0 && !topManager.isLoadingDoctor">
                      <span class="text-uppercase message-not-manager">Ничего не найдено</span>
                      </div>
                      <div v-if="topManager.isLoadingDoctor">
                          <LoadingSpinner :wrapperStyle="`top: 150px`" :showSpinner="true"/>
                      </div>
  <div v-if="!topManager.isLoadingDoctor" class="list-group">
    <template  v-for="manager in filteredAndSearchedManagers" :key="manager">
        <a  @click="getDoctors(manager)"  class="list-group-item list-group-item-action manager-cursor" aria-current="true">
            <div class="d-flex w-100 justify-content-between">
            <h5 class="fw-bold">{{ manager.fullname }} </h5>
            <span class="badge bg-primary rounded-pill" style="    width: 30px;
            height: 25px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;">{{ manager.doctors?.length }}</span>
            </div>
            <p class="mb-1">{{ manager.phone }} {{ manager.email }} </p>
        </a>      
</template>
</div>

</template>

<script setup>
 import { useTopManager } from '@/stores/TopManager'
 import { onMounted, ref, provide, computed } from 'vue' 
 import LoadingSpinner from '../Global/LoadingSpinner.vue'
 import { useProfileStore } from '@/stores/ProfileStore'
 import { useDoctorsStore } from '@/stores/DoctorsStore'
 const searchQuery = ref('')
 const doctorsId = ref([])
 const doctorStore = useDoctorsStore()
 const filteredAndSearchedManagers = computed(() => {
            const searchTerms = searchQuery.value.toLowerCase().split(' ')
            if (searchQuery.value === '') {
                return topManager.managers
            } else {
               return topManager.managers.filter((value) => {
                    return searchTerms.every((term) => {
                        return (
                            value.fullname.toLowerCase().includes(term) ||
                            value.email.toLowerCase().includes(term) || 
                            String(value.phone).includes(term) 
                        )
                    })
                })
            }
        })
    const topManager = useTopManager()
        const getDoctors = (manager) => {
            if (manager && manager.doctors.length > 0) {
                topManager.doctors = manager.doctors
                topManager.selectedManagerFullName = manager.fullname
              if (topManager.doctors) {
                doctorsId.value = manager.doctors.map(doctor => doctor.doctor_id)
                doctorStore.getDoctorBonus(doctorsId.value)
              }
            } else {
              topManager.selectedManagerFullName = manager.fullname
                topManager.doctors = []
            }
            topManager.tabManager = 'DoctorList'
        }
    
</script>

<style setup> 

.message-and-spinner{
  height: 300px;
}
.message-not-manager{
  height: 50%;
  color: #aeaeae;
  font-size: larger;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.manager-cursor{
  cursor: pointer;
}
</style>
