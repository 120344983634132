<template>
    <div class="modal fade" id="deleteDoctorModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Удаление доктора</h1>
                    <button type="button" class="btn-close" @click=hideDeleteDoctorModal aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="doctorDetails">
                    Удалить <b>{{ doctorDetails.first_name }} {{ doctorDetails.middle_name }} {{ doctorDetails.last_name }}</b>?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideDeleteDoctorModal">Закрыть</button>
                    <button type="button"
                            class="btn btn-danger position-relative"
                            :class="{'pe-none': doctorsStore.isLoading}"
                            @click="deleteDoctor"
                    >
                        Удалить доктора
                        <span v-if="doctorsStore.isLoading" class="placeholder-glow placeholder-surface">
                            <span class="placeholder" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted } from 'vue'
    import { useDoctorsStore } from '@/stores/DoctorsStore'
    import { Modal } from 'bootstrap'

    const doctorsStore = useDoctorsStore()
    const props = defineProps(['doctorDetails'])
    const deleteDoctorModal = inject('deleteDoctorModal')

    const deleteDoctor = () => doctorsStore.deleteDoctorById(props.doctorDetails.id)
        .then(() => hideDeleteDoctorModal())

    const hideDeleteDoctorModal = () => deleteDoctorModal.value.hide()

    onMounted(() => {
        deleteDoctorModal.value = new Modal('#deleteDoctorModal')
    })
</script>
