<template>
  <div class="col-12 col-sm-9 mb-3 mb-sm-0 patient-personal-info">
    <div class="card card-form">
      <div class="card-header ">Данные пациента</div>
      <div class="menu-cart" style="box-shadow: none;">
        <div class="card-body ps" style="box-shadow: none;
">
          <div class="mb-0 mb-sm-4">
            <div class="tab-menu menu-wrapper mb-3">
              <button
                  class="list-group-item-action d-flex align-items-center left-tab"
                  :class="{ active: cartStore.orderTub === 1 }"
                  @click="changeTab(1)"
              >
                <i class="bi bi-telephone me-3 fs-5"></i>
                Анонимная заявка
              </button>
              <button
                  class="list-group-item-action d-flex align-items-center right-tab"
                  :class="{ active: cartStore.orderTub === 2 }"
                  @click="changeTab(2)"
              >
                <i class="bi bi-person me-3 fs-5"></i>
                Верифицированная заявка
              </button>
            </div>
          </div>
          <select
              v-model="userSelect"
              class="form-select"
              aria-label="Default select example"
              @change="changeUser()"
          >
            <option selected="selected" value="0">Не выбран пациент</option>
            <option
                v-for="item in orderStore.patientsOrderCard"
                :key="item.id"
                :value="item.id"
            >
              {{ item.lastname }} {{ item.firstname }} {{ item?.middlename }}
            </option>
          </select>

          <div v-if="cartStore.orderTub === 2" class="form-floating mb-3 mt-3">
            <input
                :disabled="noActiveUser"
                class="form-control"
                placeholder="Фамилия"
                v-model="cartStore.state.personalData.lastName"
            />
            <label for="lastName">Фамилия<span class="required-field">*</span></label>
          </div>
          <div v-if="cartStore.orderTub === 2" class="form-floating mt-3">
            <input
                :disabled="noActiveUser"
                class="form-control"
                placeholder="Имя"
                v-model="cartStore.state.personalData.firstName"
            />
            <label for="firstName">Имя<span class="required-field">*</span></label>
          </div>

          <div v-if="cartStore.orderTub === 2" class="form-floating mt-3">
            <input
                :disabled="noActiveUser"
                placeholder="Отчество"
                class="form-control"
                v-model="cartStore.state.personalData.middleName"
            />
            <label for="middleName">Отчество</label>
          </div>
          <div class="form-floating mt-3">
            <input
                :disabled="noActiveUser"
                v-model="cartStore.state.personalData.phone"
                class="form-control"
                placeholder="Номер телефона"
                v-maska
                data-maska="+7(9##)###-##-##"
            />
            <label for="phoneNumber"
            >Номер телефона<span class="required-field">*</span></label
            >
          </div>
          <div v-if="cartStore.orderTub === 2" class="form-floating mt-3">
            <input
                :disabled="noActiveUser"
                min="1930-01-01"
                :max="getTodayDate()"
                type="date"
                class="form-control"
                placeholder="Дата рождения"
                v-model="cartStore.state.personalData.birthDate"
            />
            <label for="birthDate">Дата рождения<span class="required-field">*</span></label>
          </div>

          <div class="form-floating mt-3">
          <textarea
              class="form-control"
              placeholder="Комментарий к заказу"
              v-model="cartStore.state.personalData.comment"
              style="height: 100px"
          ></textarea>
            <label for="comment">Комментарий к заказу</label>
          </div>
          <div class="form-floating">
            <div
                v-if="cartStore.orderTub === 2"
                class="gender-card"
            >
              <div
                  class="form-floating mt-3 col-12 col-sm-4"
              >
                <div class="card" style="margin-right:20px">
                  <div class="card-header">Пол</div>
                  <div class="card-body">
                    <label class="form-check-label" for="genderRadioMale">
                      <input
                          :disabled="noActiveUser"
                          class="form-check-input"
                          type="radio"
                          id="genderRadioMale"
                          value="Male"
                          v-model="cartStore.state.personalData.gender"
                      />
                      Мужчина
                    </label>
                    <label class="form-check-label" for="genderRadioFemale">
                      <input
                          :disabled="noActiveUser"
                          class="form-check-input"
                          type="radio"
                          id="genderRadioFemale"
                          value="Female"
                          v-model="cartStore.state.personalData.gender"
                      />
                      Женщина
                    </label>
                  </div>
          
                </div>
              </div>
              <div class="form-floating mb-3 col-12 col-sm-4 mt-3">
                <div style="margin-right:20px"
                     v-if="cartStore.state.personalData.gender === 'Female' && cartStore.orderTub === 2" class="card
                ">
                  <div class="card-header">Менопауза</div>
                  <div class="card-body">
                    <div class="form-check me-0 me-sm-5 ps-0">
                      <label class="form-check-label" for="menopauseRadioYes">
                        <input
                            class="form-check-input"
                            type="radio"
                            id="menopauseRadioYes"
                            v-model="cartStore.state.personalData.menopause"
                            :value="true"
                        />
                        Да
                      </label>
                    </div>
                    <div class="form-check me-0 me-sm-5 ps-0">
                      <label class="form-check-label" for="menopauseRadioNo">
                        <input
                            class="form-check-input"
                            type="radio"
                            id="menopauseRadioNo"
                            v-model="cartStore.state.personalData.menopause"
                            :value="false"
                        />
                        Нет
                      </label>
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-floating col-12 col-sm-4 mt-3">
                <div
                    v-if="cartStore.state.personalData.gender === 'Female' && cartStore.orderTub === 2 && cartStore.state.personalData.menopause === false"
                    class="card" style="margin-right:20px"
                >
                  <div class="card-header">
                    Дата первого дня последней менструации
                  </div>
                  <div class="card-body" style="height: 100px;">
                    <div class="d-flex flex-nowrap">
                      <input
                          type="date"
                          class="form-control mb-3"
                          placeholder="дата последней менструации"
                          v-model="
                          cartStore.state.personalData.lastMenstruationDate
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                  class=""
                  v-if="cartStore.state.personalData.menopause === false && cartStore.state.personalData.gender === 'Female' && cartStore.orderTub === 2"
              >
                <div class="card" style="margin-right:20px">
                  <div class="card-header">Беременность</div>
                  <div class="card-body">
                    <div class="d-flex flex-nowrap flex-column flex-sm-row">
                      <div class="form-check me-0 me-sm-4 ps-0 ps-sm-4">
                        <label class="form-check-label" for="pregnancyRadioYes">
                          <input
                              class="form-check-input"
                              type="radio"
                              id="pregnancyRadioYes"
                              v-model="cartStore.state.personalData.pregnancy"
                              value="1"
                          />
                          <span>Да</span>
                        </label>
                      </div>
                      <div class="form-check ps-0 me-sm-3">
                        <label class="form-check-label" for="pregnancyRadioNo">
                          <input
                              class="form-check-input"
                              type="radio"
                              id="pregnancyRadioNo"
                              v-model="cartStore.state.personalData.pregnancy"
                              value="0"
                          />
                          <span>Нет</span>
                        </label>
                      </div>
                      <div class="form-check ps-0">
                        <label
                            class="form-check-label"
                            for="pregnancyRadioMaybe"
                        >
                          <input
                              class="form-check-input"
                              type="radio"
                              id="pregnancyRadioMaybe"
                              v-model="cartStore.state.personalData.pregnancy"
                              value="2"
                          />
                          <span>Может быть</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="card " style="height: 120px;"
                  v-if="
                    cartStore.state.personalData.menopause === false &&
                    cartStore.state.personalData.pregnancy === '1' && cartStore.state.personalData.gender === 'Female'
                  "
              >
                <div class="card-header">Количество недель беременности</div>
                <div class="card-body">
                  <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="cartStore.state.personalData.pregnancyPeriod"
                  >
                    <option v-for="n in 91" :key="n" :value="n - 1">
                      {{ n - 1 }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { vMaska } from 'maska'

// cartStore
import { userCartStore } from '@/stores/CartStore'

// orderStore
import { useOrderStore } from '@/stores/OrderStore'
import { showErrorNotification } from '@/services/notifications'
import router from '@/router'
import { disable } from 'ol/rotationconstraint'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

const cartStore = userCartStore()
const orderStore = useOrderStore()

const currentComponent = ref()
const userSelect = ref(0)
const noActiveUser = ref(false)
const getTodayDate = () => {
  const today = new Date().toISOString().split('T')[0]
  return today
}
const changeTab = (index) => {
  cartStore.orderTub = index
}
const changeUser = () => {
  const selectedUser = userSelect.value
  if (parseInt(selectedUser) === 0) {
    cartStore.clearPersonalData()
    noActiveUser.value = false
  } else {
    const data = orderStore.patientsOrderCard.filter(
        (item) => item.id === selectedUser
    )[0]
    cartStore.state.personalData.birthDate = data.birthday
    cartStore.state.personalData.email = data.email
    cartStore.state.personalData.firstName = data.firstname
    cartStore.state.personalData.lastName = data.lastname
    cartStore.state.personalData.middleName = data.middlename
    cartStore.state.personalData.phone = data.phone
    cartStore.state.personalData.gender = data.gender === 1 ? 'Male' : 'Female'
    noActiveUser.value = true
  }
}

onMounted(() => {
  cartStore.orderTub = 1
  if (!cartStore.state.assays.length) {
    router.push({ name: 'Analyzes' })
  }
  orderStore.getPatient()
})
</script>

<style lang="scss" scoped>
.menu-cart {
    height: auto;
}

.required-field {
  color: red;
  margin-left: 2px;
}

.left-tab {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  @media (max-width: 575.98px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}

.right-tab {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (max-width: 575.98px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 5px;
  }
}

.menu-wrapper {
  box-shadow: 0 0 0 1px var(--bs-border-color),
  2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: 5px;
}

.error-red {
  box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.501);
}

.patient-secondary-data {
  input,
  label {
    cursor: pointer;
  }
}

label {
  padding: 10px;

  input {
    margin-top: 0;
    margin-left: 0 !important;
    margin-right: 0.75rem;
  }
}

.form-check-label {
  display: flex;
  align-items: center;
}

.tab-menu {
  display: flex;
  height: 35px;
  @media (max-width: 575.98px) {
    width: 100%;
    display: inline-flex;
    margin: 0;
    height: auto;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.tab-menu button {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tab-menu button.active {
  background-color: #149cdd;
  color: white; // Цвет текста на активном табе
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color),
  2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

.accordion-button {
  &:after {
    margin-left: 0;

    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.accordion-main-style {
  button.accordion-button {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}

.card-form {
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  height: auto;
}

.gender-card {
  max-height: var(--ps-height);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: baseline;
}
</style>
