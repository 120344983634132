<template>
  <div id="top-element"></div>
  <div  class="container-search">
    <div class="size-search">
      <input
        v-if="analyzesStore.contentItem.length && !isLoading"
        type="text"
        class="form-control mb-3 mobile-search-input search-analysis"
        placeholder="Поиск анализа по названию, коду или федеральному коду"
        v-model="searchQuery"
      />
      <button v-if="analyzesStore.contentItem.length && !isLoading"
              class=" container-open-category d-absolute d-sm-none list-group-item list-group-item-action d-flex align-items-center bi bi-funnel me-3 fs-5"
              :class="{ active: tabAnalysis === 1 }"
              @click="openCategory()"
            >
            </button>
    </div> 
    <table class="table table-hover" style="width: 100%">
      <tbody class="container-analysis-body">
      <LoadingSpinner
          v-if="tabAnalysis === 2 && analyzesStore.loadingTopAnalyzes"
          spinnerStyle="min-height: var(--ps-height);"
          :showSpinner="true"
      />
        <tr
          class="container-tr cart-desktop"
          v-for="analysisGroup in tabAnalysis === 2
            ? filteredTopAnalyzes
            : filteredAndSearchedAnalyzes"
          :key="analysisGroup.id"
          data-bs-toggle="collapse"
          :data-bs-target="`#completedOrders-collapseTwo-${analysisGroup.id}`"
        >
          <td @click="openInfoAnalysis(analysisGroup)" class="container-time">
            <span style="width: 60%">
              <code class="me-0 me-sm-2 analyse-code">{{
                analysisGroup.code
              }}</code>
            </span>
          </td>
          <td @click="openInfoAnalysis(analysisGroup)" class="container-time">
            <span style="width: 60%">
              {{ analysisGroup.name }}
            </span>
          </td>
          <td
            @click="openInfoAnalysis(analysisGroup)"
            style="width: 100px; align-content: center"
          >
            <div class="d-flex justify-content-end align-items-center w-100">
              {{ analysisGroup.price }}&#8381;
            </div>
          </td>
          <td class="cart-control">
            <div class="col-12 col-sm-9">
              <CartControll
                :code="analysisGroup.code"
                v-if="cartStore.isAssayExist(analysisGroup.code)"
              />
              <button 
                v-else
                type="button"
                :class="
                  tabAnalysis === 2
                    ? 'btn btn-sm add-to-order'
                    : 'btn btn-sm add-to-order'
                "
                @click="cartStore.setAssaysAmount(analysisGroup.code, 1)"
              >
                Добавить
              </button>
            </div>
          </td>
        </tr>

        <tr
          v-for="analysisGroup in tabAnalysis === 2
            ? filteredTopAnalyzes
            : filteredAndSearchedAnalyzes"
          :key="analysisGroup.id"
          class="container-tr row cart-mobile"
        >
          <div class="col-9 group-analysis">
            <td @click="openInfoAnalysis(analysisGroup)" colspan="3">{{ analysisGroup.name }}</td>
            <td @click="openInfoAnalysis(analysisGroup)">
              <code>{{ analysisGroup.code }}</code>
              <span style="text-align: right"
                >{{ analysisGroup.price }}&#8381;</span
              >
            </td>
          </div>
          <div class="col-2 container-cart-control">
            <td  style="justify-content: center">
              <CartControll
                :code="analysisGroup.code"
                v-if="cartStore.isAssayExist(analysisGroup.code)"
              />
              <button
                v-else
                type="button"
                :class="
                  tabAnalysis === 2
                    ? 'btn btn-sm add-to-order'
                    : 'btn btn-sm add-to-order'
                "
                @click="cartStore.setAssaysAmount(analysisGroup.code, 1)"
              >
                <i class="bi bi-cart fs-5"></i>
              </button>
            </td>
          </div>
        </tr>

        <div style="color: red" ref="loadMoreTrigger" id="loading-trigger">
          <span style="width: 100%; height: 10px"></span>
        </div>
        <div class="btn-up btn-up_hide"></div>
      </tbody>
      <div
        style="height: 300px"
        class="text-center"
        v-if="
          tabAnalysis === 2 &&
          !filteredTopAnalyzes.length &&
          !analyzesStore.loadingTopAnalyzes
        "
      >
        <h2 class="text-uppercase h4 message-no-analysis">
          Анализы не найдены
        </h2>
      </div>
      <div
        style="height: 300px"
        class="text-center"
        v-if="
          tabAnalysis === 1 &&
          searchQuery.length > 0 &&
          analyzesStore.contentItem.length > 0 &&
          !filteredAndSearchedAnalyzes.length
        "
      >
        <h2 class="text-uppercase h4 message-no-analysis">
          Анализы не найдены
        </h2>
      </div>
    </table>
    <div
      style="height: 300px"
      class="text-center"
      v-if="
        tabAnalysis === 1 &&
        searchQuery.length === 0 &&
        analyzesStore.contentItem.length === 0 &&
        !filteredAndSearchedAnalyzes.length &&
        !isLoading
      "
    >
      <h2 class="text-uppercase h4 message-no-analysis">Анализы не найдены</h2>
    </div>
  </div>
  <InfoAnalysisModal :infoAnalysis="infoAnalysis" />
</template>

<script setup>
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { userCartStore } from '@/stores/CartStore'
import CartControll from '@/components/Cart/Controlls/CartControll.vue'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import {
  ref,
  onMounted,
  computed,
  defineProps,
  provide,
  onBeforeUnmount
} from 'vue'
import InfoAnalysisModal from './Modals/InfoAnalysisModal.vue'
import axios from '@/services/axios'

const cartStore = userCartStore()
const analyzesStore = useAnalyzesStore()
const searchQuery = ref('')
const props = defineProps(['isLoading', 'tabAnalysis'])
const emit = defineEmits(['openCategory'])
const openCategory = () => {
  emit('openCategory')
}
const infoAnalysisModal = ref(null)
const infoAnalysis = ref(null)
provide('infoAnalysisModal', infoAnalysisModal)
const openInfoAnalysis = (analysis) => {
  getAnalysis(analysis.id)
  infoAnalysis.value = analysis
}
const getAnalysis = (analysis) => {
  axios
    .get(`/api/analyzes/analysis/${analysis}`)
    .then((response) => {
      if (response.data.success && response.data.data) {
        infoAnalysis.value = response.data.data
        infoAnalysisModal.value.show()
      } else if (response.data.message) {
        showErrorNotification(response.data.message)
      } else {
        showErrorNotification('Ошибка сервера')
      }
    })
    .catch(error => {
      if (error.response.data.message) {
        showErrorNotification(error.response.data.message)
      } else {
        showErrorNotification('Ошибка сервера')
      }
    })
}
const filteredTopAnalyzes = computed(() => {
  const searchTerms = searchQuery.value.toLowerCase().split(' ')
  if (searchQuery.value === '') {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    return analyzesStore.topAnalyzes.sort((a, b) => b.amount - a.amount)
  } else {
    return analyzesStore.topAnalyzes?.filter((value) => {
      return searchTerms.every((term) => {
        return (
          value.code.includes(term) ||
          value.name.toLowerCase().includes(term) ||
          value.federal_code?.includes(term) ||
          (value.synonym &&
            value.synonym.some((syn) => syn.toLowerCase().includes(term)))
        )
      })
    })
  }
})
const filteredAndSearchedAnalyzes = computed(() => {
  const searchTerms = searchQuery.value.toLowerCase().split(' ')
  if (searchQuery.value === '') {
    return analyzesStore.contentItem
  } else {
    return analyzesStore.contentItem?.filter((value) => {
      return searchTerms.every((term) => {
        return (
          value.code.includes(term) ||
          value.name.toLowerCase().includes(term) ||
          value.federal_code?.includes(term) ||
          (value.synonym &&
            value.synonym.some((syn) => syn.toLowerCase().includes(term)))
        )
      })
    })
  }
})
const getDescriptionOrder = (analyze) => {
  analyzesStore.analyzeGroup = analyze
}

</script>

<style lang="scss" scoped>
.mobile-count-analyze {
  position: absolute;
  color: #6b6b6b;
  top: 50%;
  right: 4.8rem;
  width: 25px;
  transform: translateY(-50%);
  z-index: 3;
  @media (max-width: 575.98px) {
    position: absolute;
    top: 50%;
    right: 2.2rem;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.mobile-remove-analyze {
  position: absolute;
  top: 50%;
  right: 3.6rem;
  right: 7.6rem;
  transform: translateY(-50%);
  z-index: 3;
  width: 27px;
  @media (max-width: 575.98px) {
    position: absolute;
    top: 50%;
    right: 5.2rem;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.mobile-add-analyze {
  position: absolute;
  top: 50%;
  right: 3.6rem;
  transform: translateY(-50%);
  z-index: 3;
  @media (max-width: 575.98px) {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.color-description {
  color: #6c6d6e;
}

.selected-analysis {
  background-color: #149cdd;
}

.button-dop-category:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

input {
  &.form-control {
    box-shadow: none;
  }
}

.row {
  &.content-container {
    [class*="col-"] {
      min-height: fit-content;
      max-height: var(--ps-height);

      input {
        transition: 0.3s;

        &:focus {
          box-shadow: 2px 2px 12px 0 rgba(34, 60, 80, 0.06);
          transition: none;
        }
      }

      .ps {
        position: relative;
        max-height: var(--ps-height);
        box-shadow: 0 0 0 1px var(--bs-border-color),
          2px 2px 12px 0 rgba(34, 60, 80, 0.15);

        &:nth-child(2) {
          max-height: calc(var(--ps-height) - 54px);
        }

        .accordion {
          .accordion-item {
            .accordion-header {
              button.accordion-button {
                @media (max-width: 575.98px) {
                  &:after {
                    display: none;
                  }
                }

                .inner-content {
                  @media (max-width: 575.98px) {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 90px);
                    margin-right: 0;
                    gap: 1rem;

                    span {
                      &.price {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }

              button.add-to-order {
                @media (max-width: 575.98px) {
                  height: 100%;
                  top: 0;
                  right: 0;
                  transform: none;
                  border-radius: 0;
                }
              }

              button.add-to-order:hover {
                background-color: #0dcaf0;
                color: black;
                opacity: 1;
              }
              button.add-to-order {
                background-color: #ededed;
                color: black;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.inner-content-tab {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

button.add-to-order {
  @media (max-width: 575.98px) {
    height: 100%;
    top: 0;
    right: 0;
    transform: none;
    border-radius: 0;
  }
}

button.add-to-order:hover {
  background-color: #0dcaf0;
  color: black;
  opacity: 1;
}

.amount-top {
  color: rgb(0, 0, 0);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.tab-amount {
  display: flex;
  z-index: 100;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.bg-color-top {
  background-color: #ededed;
}

.mobile-search-input {
  @media (max-width: 575.98px) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    width: 85%;
  }
}

.mobile-scrollbar {
  @media (max-width: 575.98px) {
    max-height: calc(100vh - 250px);
  }
}

.mobile-accordion-header {
  @media (max-width: 575.98px) {
    padding: 0 10px;
  }
}

.mobile-accordion-button {
  @media (max-width: 575.98px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
.container-tr {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  code {
    display: flex;
  }
}
@media (max-width: 575.98px) {
  .container-tr {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid #dddddd;
  }
  .container-tr td {
    width: 100%;
    border-bottom: 0px;
    display: flex;
    justify-content: space-between;
  }
}
.cart-control {
  width: 148px;
  padding-right: 20px;
  position: relative;
  align-content: center;
  text-align: -webkit-center;
  button{
    border: 1px solid #dbdbdb;
  }
}
.container-info-analyzes {
  width: 70px;
  padding-right: 20px;
  position: relative;
  align-content: center;
  button {
    font-size: larger;
    display: flex;
    place-content: center;
    width: 20px;
    align-items: center;
    height: 25px;
    position: relative;
    vertical-align: middle;
    padding: 13px;
    border-radius: 50%;
  }
}
.message-no-analysis {
  height: 50%;
  color: #aeaeae;
  font-size: larger;
  align-content: end;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  font-size: 24px;
  color: #fff;
  background: #000;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.back-to-top:hover {
  background: #333;
  text-decoration: none;
}
#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.cart-desktop {
  display: none;
  @media (min-width: 575.98px) and (max-width: 799px) {
    display: table-row;
  }
  @media (min-width: 800px) {
    display: table-row;
  }
}
.cart-mobile {
  display: flex;
  @media (min-width: 575.98px) {
    display: none;
  }
}
.container-analysis-body {
  @media (max-width: 575.98px) {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
  }
}
.container-search {
  position: relative;
  height: auto;
  min-height: 98%;
  padding-top: 10px;
  z-index: 100;
  top: 1px;
}
.size-search {
  position: sticky;
  top: 50px;
  width: 100%;
  z-index: 100;
  padding-bottom: 10px;
  background-color: white;
  margin-top: -27px;
  padding-top: 10px;
}
.group-analysis {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 15px;
  display: inline-grid;
  align-items: center;
}
.container-cart-control {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 15px;
  align-content: center;
}
.message-no-analysis {
  height: 50%;
  color: #aeaeae;
  font-size: larger;
  align-content: end;
}
.container-open-category{
  position: absolute;
    top: 25%;
    left: 88%;
    width: 10%;
    height: 44%;
    place-content: center;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
}
.search-analysis{
  margin-top: 10px; background: white
}
.list-group-item:last-child {
   border-radius: 5px;
}
.loading-analysis{
  position: fixed;
              width: 80%;
              height: calc(-333px + 100vh);
              top: 48%;
              right: 0px;
              z-index: 1002;
}
.item-category{
  position: relative;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            padding: var(--bs-accordion-btn-padding-y)
                              var(--bs-accordion-btn-padding-x);
                            font-size: 1rem;
                            text-align: left;
                            border: 0;
                            border-radius: 0;
                            overflow-anchor: none;
                            transition: var(--bs-accordion-transition);
}
</style>
