import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import axios from '@/services/axios'
import notyf from '@/services/notifications'
import 'bootstrap'
import '@popperjs/core'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import { initResizeHandler } from '@/handlers/resizeHandler'

import '@olegopro/notyf/notyf.min.css'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@/assets/css/main.css'

// Обработчик обновления высоты
initResizeHandler()

// Создаю экземпляр приложения
const app = createApp(App)

// Устанавливаем глобальные свойства
app.config.globalProperties.$axios = axios
app.config.globalProperties.$notyf = notyf

// Использую плагины и монтируем приложение
app.use(createPinia())
    .use(router)
    .use(PerfectScrollbar, { options: { minScrollbarLength: 30 } })
    .mount('#app')
