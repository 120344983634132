<template>
  <div class="col-12 col-sm-3 mb-4 mb-sm-0 menu-wrapper mt-4">
      <div class="tab-menu">
        <button
            class="list-group-item list-group-item-action d-flex align-items-center"
            :class="{ 'active': orderStore.currentTab === 1 }"
            @click="changeTab(1)"
        >
          <i class="bi bi-file-earmark-text me-3 fs-5"></i>
          Заказы
        </button>
        <button
            class="list-group-item list-group-item-action d-flex align-items-center"
            :class="{ 'active': orderStore.currentTab === 2}"
            @click="changeTab(2)"
        >
          <i class="bi bi-file-earmark-plus me-3 fs-5"></i>
          Заявки
        </button>
      </div>
  </div>
  <component :is="currentComponent"></component>

</template>
<script setup>
import { useOrderStore } from '@/stores/OrderStore'
import { onMounted, ref, provide, computed, onBeforeUnmount } from 'vue'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate, stateAnalyzes } from '@/helpers/dateHelpers'
import EditOrderModal from '@/components/Profile/Modals/EditOrderModal.vue'
import DeleteOrderModal from '@/components/Profile/Modals/DeleteOrderModal.vue'
import InfoOrderModal from '@/components/Profile/Modals/InfoOrderModal.vue'
import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import AnalyzesStatus from '@/components/Profile/Badges/AnalyzesStatus.vue'
import { useAuthStore } from '@/stores/AuthStore'
import OrderList from '@/components/Profile/OrderList.vue'
import ApplicationList from '@/components/Profile/ApplicationList.vue'

const orderStore = useOrderStore()
const currentComponent = ref(OrderList)
const changeTab = (index) => {
  orderStore.currentTab = index
  if (orderStore.currentTab === 1) {
    currentComponent.value = OrderList
  } else {
    currentComponent.value = ApplicationList
  }
}
onMounted(() => {
  if (orderStore.currentTab === 1) {
    currentComponent.value = OrderList
  } else {
    currentComponent.value = ApplicationList
  }
})

</script>
<style scoped lang="scss">
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

.accordion-button {
  &:after {
    margin-left: 0;

    @media (max-width: 575.98px) {
      display: none;
    }
  }
}

.accordion-main-style {
  button.accordion-button {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}

.tab-menu {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.tab-menu button {
flex: 1;
text-align: center;
padding: 10px;
border: none;
background-color: transparent;
cursor: pointer;
  border-radius: 5px;
}

.tab-menu button.active {
background-color: #149cdd;
color: white; // Цвет текста на активном табе
}
</style>
