import { defineStore } from 'pinia'
import axios from '@/services/axios'
import { showErrorNotification, showSuccessNotification } from '@/services/notifications'
import router from '@/router'
import { formatPhoneNumberToSend } from '@/helpers/phoneFormattersHelper'

export const useDoctorsStore = defineStore('doctorsStore', {
    state: () => ({
        firstName: null,
        lastName: null,
        middleName: null,
        phone: null,
        onlyApproved: 1,
        updated_after: null,
        searchResult: null,
        doctors: null,
        isLoading: false,
        isLoadingSearchQuery: false,
        doctorBonus: []
    }),

    actions: {
        async searchByQueryFromGenesis() {
            this.isLoadingSearchQuery = true

            const dateStr = this.updated_after
            const unixTime = dateStr ? Math.floor(new Date(dateStr + 'T00:00:00').getTime() / 1000) : null
            await axios.get('/api/doctor/genesis', {
                params: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    middleName: this.middleName,
                    phone: formatPhoneNumberToSend(this.phone),
                    onlyApproved: this.onlyApproved,
                    updated_after: unixTime
                }
            })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.searchResult = response.data.data
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }).finally(() => this.isLoadingSearchQuery = false)
        },

        clearSearchResult() {
            this.firstName = null
            this.lastName = null
            this.middleName = null
            this.phone = null
            this.onlyApproved = 1
            this.updated_after = null
            this.searchResult = null
        },

        async createDoctor(doctorId, doctorEmail) {
            this.isLoading = true

            await axios.post(`/api/doctor/create/from-genesis/${doctorId}`, { email: doctorEmail })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.doctors = []
                        router.push({ name: 'DoctorsList' })
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        this.doctors = []
                        router.push({ name: 'DoctorsList' })
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                // Пробрасываю ошибку наверх через throw
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async getDoctors() {
            this.isLoading = true
            try {
                await axios.get('/api/doctor')
                .then((response) => {
                if (response.data.success && response.data.data) {
                   this.doctors = response.data.data
                } else if (response.data.message) {
                    showErrorNotification(response.data.message)
                } else {
                    showErrorNotification('Ошибка сервера')
                }
}
                )
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                }).finally(() => this.isLoading = false)
            } catch (error) {
                showErrorNotification('Ошибка сервера')
                showErrorNotification(error.message)
            }
        },

        async deleteDoctorById(id) {
            this.isLoading = true
            await axios.delete(`api/doctor/${id}`)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.doctors = this.doctors.filter(doctor => doctor.id !== id)
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async disableDoctor(id) {
            this.isLoading = true

            await axios.put(`api/doctor/${id}/disable`)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        const index = this.doctors.findIndex(doctor => doctor.id === id)
                        this.doctors[index].active = !this.doctors[index].active
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        async editDoctorById(id, doctorEditedData) {
            this.isLoading = true

            await axios.put(`api/doctor/${id}`, {
                first_name: doctorEditedData.first_name,
                middle_name: doctorEditedData.middle_name,
                last_name: doctorEditedData.last_name,
                email: doctorEditedData.email,
                phone: formatPhoneNumberToSend(doctorEditedData.phone)
            })
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        this.updateDoctorInStore({ id, ...doctorEditedData })
                        showSuccessNotification(response.data.message)
                    } else if (response.data.message) {
                        showErrorNotification(response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                // Пробрасываю ошибку наверх через throw
                .catch(error => {
                    if (error.response.data.message) {
                        showErrorNotification(error.response.data.message)
                    } else {
                        showErrorNotification('Ошибка сервера')
                    }
                })
                .finally(() => this.isLoading = false)
        },

        updateDoctorInStore(doctorUpdatedData) {
            const index = this.doctors.findIndex(doctor => doctor.id === doctorUpdatedData.id)
            this.doctors[index] = { ...this.doctors[index], ...doctorUpdatedData }
        },

         getDoctorBonus(doctorId) {
            this.isLoading = true
             this.doctorBonus = []
                 axios.post('api/doctors/bonus', { 'doctors_id': doctorId })
                    .then((response) => {
                            if (response.data.success && response.data.data) {
                                this.doctorBonus = response.data.data
                                this.isLoading = false
                            } else if (response.data.message) {
                                showErrorNotification(response.data.message)
                            } else {
                                showErrorNotification('Ошибка сервера')
                            }
                        }
                    )
                    .catch(error => {
                        if (error.response.data.message) {
                            showErrorNotification(error.response.data.message)
                        } else {
                            showErrorNotification('Ошибка сервера')
                        }
                    }).finally(() => this.isLoading = false)
        },

        resetState() {
            this.doctors = null
            this.searchResult = null
        }
    }
})
