import { ref, computed } from 'vue'
export function formatUnixTimestampOnlyDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000)
    return date.toLocaleDateString()
}

export function formatUnixTimestampDateAndTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000)
    return date.toLocaleString()
}

export function formatDateHelper(dateString) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }
  export function formatDate(dateString) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0') 
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}
