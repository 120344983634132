import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import axios from '@/services/axios'
import { showErrorNotification } from '@/services/notifications'
export const userCartStore = defineStore(
    'cartStore',
    () => {
        const state = reactive({
                assays: [],
                analyzes: [],
                personalData: {
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    phone: '',
                    birthDate: null,
                    comment: null,
                    gender: 'Male',
                    menopause: false,
                    lastMenstruationDate: null, // дата последней менструации
                    pregnancy: 0, // беременность true/false
                    pregnancyPeriod: 0 // кол-во дней беременности
                },
                orderTub: 1
            }
        )
        function setAssaysAmount(assay, amount) {
            if (isNaN(parseInt(amount)) || parseInt(amount) <= 0) {
                showErrorNotification('Некорректное значение')
                return null
            }
            const index = state.assays.findIndex(item => item.assay === assay)
            if (index === -1) {
                state.assays.push({
                    assay: assay,
                    amount: amount
                })
            } else {
                state.assays[index].amount = amount
            }
            storeAnalyzes()
        }

        function increaseAssaysAmount(assay) {
            const index = state.assays.findIndex(item => item.assay === assay)
            if (index !== -1) {
                state.assays[index].amount += 1
            }
            storeAnalyzes()
        }

        function decreaseAssaysAmount(assay) {
            const index = state.assays.findIndex(item => item.assay === assay)
            if (index !== -1) {
                const res = state.assays[index].amount - 1
                if (res === 0) {
                    state.assays.splice(index, 1)
                } else {
                    state.assays[index].amount = res
                }
            }
            storeAnalyzes()
        }

        function getAssays() {
            return state.assays
        }

        function isAssayExist(assay) {
            const result = state.assays.findIndex(item => item.assay === assay)
            if (result === -1) {
                return false
            } else {
                return true
            }
        }

        function getAssayAmount(assay) {
            const index = state.assays.findIndex(item => item.assay === assay)
            if (index === -1) {
                return 0
            } else {
                return state.assays[index].amount
            }
        }

        function storeAnalyzes() {
            localStorage.setItem('analyzes', JSON.stringify(state.assays))
        }

        function restoreAnalyzes() {
            const dataFromLocalStorage = localStorage.getItem('analyzes')
            if (dataFromLocalStorage) state.assays = JSON.parse(dataFromLocalStorage)
            getAllAnalyzes()
        } 
        const FormattedAnalyzes = computed(() => {
            return state.analyzes.filter((item) => {
                if (state.assays.map(item => item.assay).includes(item.code)) {
                    return true
                } else {
                    return false
                }
            }).map((item) => {
                return {
                    ...item,
                    'amount': getAssayAmount(item.code)
                }
            })
        })

        function getAllAnalyzes() {
            axios.get('/api/analysis').then((response) => {
                if (response.data.success && response.data.data) {
                    state.analyzes = response.data.data
                } else if (response.data.message) {
                    state.analyzes = []
                    showErrorNotification(response.data.message)
                } else {
                    state.analyzes = []
                    showErrorNotification('Ошибка сервера')
                }
            }).catch((error) => {
                if (error.response.data.message) {
                    state.analyzes = []
                    showErrorNotification(error.response.data.message)
                } else {
                    state.analyzes = []
                    showErrorNotification('Ошибка сервера')
                }
            })
        }

        function clearOrderData() {
            clearPersonalData()
            state.assays = []
            localStorage.removeItem('analyzes')
        }

        function clearPersonalData() {
            state.personalData = {
                lastName: '',
                firstName: '',
                middleName: '',
                phone: '',
                birthDate: null,
                comment: null,
                gender: 'Male',
                menopause: false,
                lastMenstruationDate: null, // дата последней менструации
                pregnancy: 0, // беременность true/false
                pregnancyPeriod: 0 // кол-во дней беременности
            }
        }

        return {
            setAssaysAmount,
            getAssays,
            increaseAssaysAmount,
            decreaseAssaysAmount,
            isAssayExist,
            getAssayAmount,
            restoreAnalyzes,
            FormattedAnalyzes,
            clearOrderData,
            clearPersonalData,
            state
        }
    })
