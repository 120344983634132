<template>
    <span :class="['badge ms-2', className]">
		{{ text }}
	</span>
</template>

<script setup>
    import { ref, computed } from 'vue'

    const props = defineProps(['type'])

    const classMap = ref({
        true: 'text-bg-success',
        false: 'text-bg-danger'
    })

    const textMap = ref({
        true: 'Доктор активен',
        false: 'Доктор не активен'
    })

    const className = computed(() => classMap.value[props.type])
    const text = computed(() => textMap.value[props.type])
</script>

<style scoped>
    span {
        margin-top: 3px;
        font-size: 0.75rem;

        @media (max-width: 575.98px) {
            position: absolute;
            top: -3px;
            right: 0;
            width: fit-content;
            border-radius: 0 0 0 5px;
            font-size: 0.65rem;
        }
    }
</style>
