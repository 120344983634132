<template>
  <div class="container-analysis">
    <div style="margin-top: 20px"></div>
    <AnalysisItem :isLoading="isLoading" :tabAnalysis="tabAnalysis" @openCategory="openCategory"/>
  </div>
</template>

<script setup>
import AnalysisItem from '@/components/Profile/AnalysisItem.vue'
import { defineProps, defineEmits, ref } from 'vue'
const emit = defineEmits(['category'])
const props = defineProps(['isLoading', 'tabAnalysis'])
const openCategory = () => {
  emit('category')
}
</script>

<style scoped lang="scss">
.container-analysis {
  @media (min-width: 1000px) {
    margin-top: 0px;
  }
}
</style>
