<template>
  <div id="top-element"></div>
  <div class="row d-flex mx-0 mb-4 mt-4">
    <div class="col-6 d-none d-sm-flex align-items-center">
      <h2 class="text-uppercase h4 m-0"><i class="bi bi-list-stars fs-4 me-2"/>Избранные списки анализов</h2>
    </div>
    <div class="d-flex d-sm-block col-12 col-sm-6 pe-4 pe-sm-0">
      <button type="button" class="btn btn-success float-end flex-grow-1 flex-sm-grow-0"
              @click="showCreateFavoriteListModal">
        <i class="bi bi-plus-circle me-1"/>
        Создать список
      </button>
      </div>
    </div>
    <div class="mb-3">
    </div>
    <div class="row mx-0 mx-sm-3 mb-3 mb-sm-0 w-100">
    <div class="col-12 pe-0 pe-sm-3 mb-4">
      <div style="border:1px solid #cecece; border-radius: 8px; " v-if="filteredFavoriteLists.length">
        <div class="accordion accordion-main-style">
          <div class="accordion-item" v-for="favoriteList in filteredFavoriteLists" :key="favoriteList.id">
            <FavoriteListItem class="mb-3" :favoriteList="favoriteList" @deleteModal="deleteModal"/>
          </div>
        </div>
      </div>
      <LoadingSpinner v-if="isLoading && !filteredFavoriteLists.length"  :showSpinner="true" />
      <div class="text-center mt-5" v-if="!filteredFavoriteLists.length && !isLoading">
        <h5 class="text-uppercase">Нет созданных списков</h5>
      </div>
    </div>
  </div>
  <DeleteFavorite :idList="deleteFavoriteListId"/>
</template>
<script setup>
import { useOrderStore } from '@/stores/OrderStore'
import { onMounted, ref, provide, computed } from 'vue'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
import { useAnalyzesStore } from '@/stores/AnalyzesStore'
import { useFavoriteListsStore } from '@/stores/FavoriteListsStore'
import { userCartStore } from '@/stores/CartStore'
import { fetchFavoriteLists, fetchFavoriteListsGlobal } from '@/api/FavoriteAnalyzes'
import router from '@/router'
import { showSuccessNotification } from '@/services/notifications'
import FavoriteListItem from '@/components/FavoriteAnalyzes/FavoriteListItem.vue'
import DeleteFavorite from './DeleteFavorite.vue'
import { useAuthStore } from '@/stores/AuthStore'
const authStore = useAuthStore()
const deleteFavoriteListId = ref(0)
const deleteFavorite = ref(null)
provide('deleteFavorite', deleteFavorite)
const deleteModal = (id) => {
    deleteFavoriteListId.value = id
    deleteFavorite.value.show()
}
const cartStore = userCartStore()
const orderStore = useOrderStore()
const analyzesStore = useAnalyzesStore()
const favoriteListsStore = useFavoriteListsStore()

const isLoading = ref(false)
const createFavoriteListModal = ref(null)
const favoriteListDetails = ref(null)
const searchQueries = ref({})
provide('createFavoriteListModal', createFavoriteListModal)
const setFavoriteListData = FavoriteListData => favoriteListDetails.value = FavoriteListData
const showCreateFavoriteListModal = () => {
  favoriteListsStore.listId = null
  favoriteListsStore.listName = ''
  favoriteListsStore.favoriteAnayze = []
  favoriteListsStore.selectedAnalyzes = { all: [], male: [], female: [] }
  favoriteListsStore.gender = 1
  router.push({ name: 'CreateFavoriteListModal' })
}
const selectedGender = ref(3)

const filteredFavoriteLists = computed(() => {
  if (selectedGender.value === 3) {
    return favoriteListsStore.favoriteLists
  }
  return favoriteListsStore.favoriteLists.filter(list => list.gender === selectedGender.value)
})

const addCodeToFavoriteList = (favoriteListId, code) => {
  const favoriteListById = favoriteListsStore.favoriteLists.find(list => list.id === favoriteListId)
  favoriteListById.analyzes.push(code)
}
const addAnalyzesCodesToCart = analyzesIds => {
  analyzesIds.forEach(code => {
    cartStore.setAssaysAmount(code.analyze_code, 1)
  })
  showSuccessNotification('Ваш список добавлен в заказ')
}

const removeCodeFromFavoriteList = (favoriteListId, code) => {
  const favoriteList = favoriteListsStore.favoriteLists.find(list => list.id === favoriteListId)
  if (favoriteList) {
    const index = favoriteList.analyzes.indexOf(code)
    if (index > -1) {
      favoriteList.analyzes.splice(index, 1)
    }
  }
}

const saveFavoriteList = favoriteListId => favoriteListsStore.saveFavoriteList(favoriteListId)

onMounted(() => {
  isLoading.value = true
  if (authStore.isChiefDoctor) {
    fetchFavoriteListsGlobal().then(() => {
    // favoriteListsStore.favoriteLists.forEach(list => {
    //   searchQueries.value[list.id] = ''
    // })
    // isLoading.value = false
  }).catch(() => isLoading.value = false)
  } else {
    fetchFavoriteLists().then(() => {
    favoriteListsStore.favoriteLists.forEach(list => {
      searchQueries.value[list.id] = ''
    })
    isLoading.value = false
  }).catch(() => isLoading.value = false)
  }
})
const convertAssaysIdToName = (assays) => {
  return assays.map(code => analyzesStore.analyzes.find(analysis => analysis.code === code)).filter(Boolean)
}
</script>

<style lang="scss" scoped>
.ps {
  max-height: calc(var(--ps-height) - 53px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);

  .accordion {
    .accordion-item {
      border-left: none;
      border-right: none;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }

      .accordion-header {
        position: relative;

        button {
          z-index: 1;

          &.accordion-button {
            &:after {
              margin-left: 1rem;
            }

            &:focus {
              box-shadow: none;
            }

            &:after {
              margin-left: auto;

              @media (max-width: 575.98px) {
                display: none;
              }
            }

            span.inner-content {
              display: flex;
              width: 70%;
              justify-content: space-between;
              margin-right: 110px;

              @media (max-width: 575.98px) {
                display: flex;
                flex-direction: column;
                width: calc(100% - 90px);
                margin-right: 0;
                gap: 1rem;
              }

              span.price {
                display: flex;
                align-items: center;
                white-space: nowrap;

                @media (max-width: 575.98px) {
                  font-weight: bold;
                }
              }
            }
          }

          &.add-to-order {
            position: absolute;
            top: 50%;
            right: 3.6rem;
            width: 90px;
            transform: translateY(-50%);
            z-index: 3;

            @media (max-width: 575.98px) {
              height: 100%;
              top: 0;
              right: 0;
              transform: none;
              border-radius: 0;
            }
          }
        }
      }

      .accordion-body {
        .analyzes-search {
          .wrapper {
            .input-container {
              position: relative;

              .reset-button {
                display: flex;
                height: 60%;
                align-items: center;
                position: absolute;
                top: 50%;
                right: 7px;
                transform: translateY(-50%);
                font-size: 0.7rem;
              }
            }
          }
        }

        ul.analyzes-added-list {
          li {
            &:first-child {
              button {
                border-top-right-radius: var(--bs-border-radius);
              }
            }

            &:last-child {
              button {
                border-bottom-right-radius: var(--bs-border-radius);
              }
            }

            span {
              @media (max-width: 575.98px) {
                padding-right: 64px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            button {
              @media (max-width: 575.98px) {
                position: absolute;
                right: 0;
                height: 100%;
                width: 74px;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}

h5 {
  color: #5d5c5c;
}

</style>
