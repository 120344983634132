<template>
  <div class="control-inline">
    <div class="cartControlWrapper" >
      <span
          type="button"
          class="btn-control"
          @click="decrease"
      >-
    </span>
      <input  class="input-count" type="number" min="1" v-model.number="amount" @change="setAmount()" @input="validateAmount"
       @keydown="preventInvalidInput">
      <span
          type="button"
          class="btn-control"
          @click="increase()"
      >+
  </span>
    </div>
  </div>
</template>

<script setup>

import { defineProps, onMounted, ref } from 'vue'
import { userCartStore } from '@/stores/CartStore'
import { showErrorNotification } from '@/services/notifications'

const cartStore = userCartStore()
const validateAmount = (event) => {
      if (amount.value < 1) {
        amount.value = ''
      }
    }
const preventInvalidInput = (event) => {
      if (event.key === '-' || event.key === 'e' || event.key === '.' || event.key === ',') {
        event.preventDefault()
      }
    }
 const setAmount = () => {
      if (amount.value <= 1) {
        amount.value = 1
        cartStore.setAssaysAmount(props.code, amount.value)
      }
      if (amount.value > 1) {
        cartStore.setAssaysAmount(props.code, amount.value)
      }
    }
const amount = ref(0)
const props = defineProps(['code'])
onMounted(() => {
  amount.value = cartStore.getAssayAmount(props.code)
})

const increase = () => {
  cartStore.increaseAssaysAmount(props.code)
  amount.value = cartStore.getAssayAmount(props.code)
}
const decrease = () => {
  cartStore.decreaseAssaysAmount(props.code)
  amount.value = cartStore.getAssayAmount(props.code)
}

</script>

<style scoped lang="scss">
.input-count{
  font-size: smaller;
    color: rgb(103, 103, 103);
}
.cartControlWrapper {
  display: flex;
  width:120px;
  height: 30px;
  justify-content: space-between;
  align-content: center;
  margin:0;
  padding:0;
  @media (max-width: 576px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 5px;
    width: min-content;
    height: 100%;

  }

  .btn-control {
    display: flex;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #d1d1d1;
    color: #4e4e4e;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: monospace;
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 576px) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    }
  }

  input {
    display: block;
    width: 100%;
    height: 30px;
    border:0;
    outline: 0;
    font-size: 20px;
    text-align: center;

  }
}
.control-inline{
  display: inline-block;
  height:30px;
  @media (max-width: 576px) {
  height: 100%;
  }
}
</style>
