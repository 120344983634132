<template>
  <div class="row d-none d-sm-flex mx-3 mb-4 mt-4">
    <div class="col-12 d-flex align-items-center">
      <h2 class="text-uppercase h4 m-0"><i class="bi bi-person-vcard fs-4 me-2"/>Информация о вашем аккаунте</h2>
    </div>
  </div>

  <div class="row mx-0 mx-sm-3">
    <div class="col-12 col-sm-5">

      <ul class="list-group list-group-main-style">
        <li class="list-group-item"><b>Роль:</b> Главный врач</li>
        <li class="list-group-item"><b>id: </b> {{ profileStore.id }}</li>
        <li class="list-group-item"><b>Фамилия: </b>{{ profileStore.last_name }}</li>
        <li class="list-group-item"><b>Имя: </b> {{ profileStore.first_name }}</li>
        <li class="list-group-item"><b>Отчество: </b>{{ profileStore.middle_name }}</li>
        <li class="list-group-item"><b>Почта: </b>{{ profileStore.email }}</li>
        <li class="list-group-item"><b>Телефон: </b>{{ profileStore.phone }}</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { useProfileStore } from '@/stores/ProfileStore'
import { onMounted } from 'vue'

const profileStore = useProfileStore()

onMounted(() => profileStore.getProfileData())
</script>

<style scoped lang="scss">
.ps {
  min-height: fit-content;
  max-height: calc(80vh - 104px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}
</style>
