<template>
    <div class="modal fade" id="infoOrderModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
        <div  class="modal-dialog modal-dialog-centered container-info-patient" >
            <div class="modal-content">
                <div v-if="orderStore.patientOrder" class="modal-header pb-0"></div>
            <div v-if="!orderStore.renderOrderPatient">
                <div  class="modal-body mt-1" >
                <div class="d-flex justify-content-between align-items-center mb-2"> 
                    <p class="mb-0"><b>Список заказов:</b></p> 
                </div>
                <div v-if="orderStore.patientOrder.length == 0"><span class="d-flex justify-content-center">Нет заказов</span></div>
                <table class="table" v-if="orderStore.patientOrder.length > 0">                    
                <tbody> 
                <tr v-for="order in orderStore.patientOrder" :key="order.id" @click="showOrderModal(order)"> 
                    <td colspan="2"> 
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#completedOrders-collapseTwo-${order.id}`" aria-expanded="false">   
                            <span class="d-none d-sm-inline ms-0 ms-sm-3"><b class="d-none d-sm-inline">Заказ создан:&nbsp;</b><b>{{ formatUnixTimestampDateAndTime(order.registrationDateTime) }}</b></span><span  class="ms-auto"></span>
                                <span  class="ms-auto"><AnalyzesStatus class="float-end" :order="order.lines" :type="order.orderState" /> </span><span ><b class="d-none d-sm-inline ms-0 ms-sm-3 ">Итого: &nbsp;</b>{{ order.totalPrice }} &#8381;</span>
                                    </button>                    
                                </td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideDeleteOrderModal">Закрыть</button>
                </div> 
             </div>
             <div v-else>
                <div class="modal-body mt-1" v-if="orderStore.order">
                    <p><b>{{ orderStore.order.patientLastName }} {{ orderStore.order.patientFirstName }} {{ orderStore.order.patientMiddleName }}</b></p> 
                    <p><b>id: </b>{{ orderStore.order.id }}</p> 
                                <p><b>Дата рождения: </b>{{ orderStore.order?.patientBirthDate ? formatUnixTimestampOnlyDate(orderStore.order?.patientBirthDate) : 'Не указан' }}</p>
                                <p><b>Телефон: </b>{{ orderStore.order?.patientPhone ?? 'Не указана' }}</p>
                                <p><b>Пол: </b>{{ orderStore.order?.gender === 'Male' ? 'Мужской' : 'Женский' }}</p>
                                <p><b>Почта: </b>{{ orderStore.order?.patientEmail ?? 'Не указана' }}</p>
                    <div class="d-flex justify-content-between align-items-center mb-2"> 
                        <p class="mb-0"><b>Список анализов:</b></p>
                        <span><b class="d-none d-sm-inline">Итого:&nbsp;</b>{{ orderStore.order?.totalPrice }} &#8381;</span>
                    </div>                      
                    <ul class="list-group mb-3" > 
                        <li class="list-group-item" v-for="assay in orderStore.order.lines" :key="assay.id"> 
                            <div class="d-flex justify-content-between align-items-center"> 
                                <div>  <code class="me-2">{{ assay.assayCode }}</code> {{ assay.assayName }}   
                                </div>  
                                <div class="ms-auto">
                                    <span class="d-block d-md-inline ms-3 ml-3 mr-1">{{ assay?.price }} &#8381; </span>
                                </div>
                                <div class="status-badge"> 
                                    <AnalyzeStatus :type="assay.state" /> 
                                </div> 
                            </div> 
                        </li> 
                    </ul>
                <p><b> Уникальный номер: </b>{{ orderStore.order?.uniqueCode }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="endOrderModal">Закрыть</button>
                    <button type="button" class="btn btn-primary" @click="orderStore.printDocument(orderStore.order?.lines[0])"> 
                    Распечатать заказ 
                </button> 
                </div></div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, inject, onMounted } from 'vue'
    import { useOrderStore } from '@/stores/OrderStore'
    import { Modal } from 'bootstrap'
    import { formatUnixTimestampDateAndTime, formatUnixTimestampOnlyDate } from '@/helpers/dateHelpers'
    import OrderStatusBadge from '@/components/Profile/Badges/OrderStatusBadge.vue'
    import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'
    import AnalyzesStatus from '@/components/Profile/Badges/AnalyzesStatus.vue'
    import AnalyzeStatus from '@/components/Profile/Badges/AnalyzeStatus.vue'
    const orderStore = useOrderStore()
    const props = defineProps(['patientOrders'])
    const infoOrderModal = inject('infoPatientOrder')
    const hideDeleteOrderModal = () => {
        orderStore.renderOrderPatient = false
        orderStore.order = {}
        infoOrderModal.value.hide()
    }
    const endOrderModal = () => {
        orderStore.renderOrderPatient = false
    }
    const showOrderModal = (order) => {
        orderStore.order = order
        orderStore.renderOrderPatient = !orderStore.renderOrderPatient
    }
    onMounted(() => 
        infoOrderModal.value = new Modal('#infoOrderModal'))
</script>
<style scoped>
@media (max-width: 576px) { 
    .list-group-item { 
        display: flex; 
        flex-direction: column; 
    } 
}
.accordion-button {
    position: relative;
    display: flow;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}
.container-info-patient{
    --bs-modal-width: 1140px;
}
.status-badge{
    overflow-wrap: anywhere
}
</style>
