<template>
    <div class="row d-none d-sm-flex mx-3 mb-4">
        <div class="col-12 d-flex align-items-center">
            <h2 class="text-uppercase h4 m-0"> <i class="bi bi-shield-lock fs-4 me-2" />Список ролей пользователей</h2>
        </div>
    </div>

    <div class="row mx-0 mx-sm-3">
        <div class="col-12 col-sm-5">
                <ul class="list-group list-group-main-style">
                    <li class="list-group-item" v-for="userRole in usersStore.roles" :key="userRole.name">
                        <p class="mb-1"><b>Описание:</b> {{ userRole.title }} </p>
                        <p v-if="userRole.name === 'staff'" class="mb-1"><b>Роль:</b> Менеджер</p>
                        <p v-if="userRole.name === 'admin'" class="mb-1"><b>Роль:</b> Администратор</p>
                        <p v-if="userRole.name === 'doctor'" class="mb-1"><b>Роль:</b> Доктор</p>
                        <p v-if="userRole.name === 'manager'" class="mb-1"><b>Роль:</b> Региональный менеджер</p>
                    </li>

                </ul>
        </div>
    </div>

</template>

<script setup>
    import { useUsersStore } from '@/stores/UsersStore'
    import { onMounted } from 'vue'

    const usersStore = useUsersStore()

    onMounted(() => usersStore.fetchRoles())
</script>

<style scoped lang="scss">
    .ps {
        min-height: fit-content;
        max-height: calc(80vh - 104px);
        box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
        border-radius: var(--bs-border-radius);
    }
</style>
