<template>
    <span  class="float-end" :class="['badge', className]">
		{{ text }}
	</span>
</template>

<script setup>
    import { ref, computed } from 'vue'
    const props = defineProps(['type'])

    const classMap = ref({
        0: 'text-bg-primary',
        1: 'text-bg-primary',
        2: 'text-bg-primary',
        3: 'text-bg-primary',
        4: 'text-bg-danger',
        5: 'text-bg-success',
        6: 'text-bg-primary',
        7: 'text-bg-danger',
        8: 'text-bg-success',
        9: 'text-bg-success',
        10: 'text-bg-warning'
    })
    const textMap = ref({
        0: 'Выполняется',
        1: 'Выполняется',
        2: 'Выполняется',
        3: 'Выполняется',
        4: 'Отменен',
        5: 'Принято врачом',
        7: 'Отменено',
        8: 'Завершен',
        9: 'Выполнено',
        10: 'Услуга'
    })
    const className = computed(() => classMap.value[props.type])
    const text = computed(() => textMap.value[props.type])
</script>

<style scoped>
    span {
        font-size: 0.70rem;
        width: 110px;
        margin-right: 2px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7);

        @media (max-width: 575.98px) {
            position: absolute;
            top: 0;
            right: -2px;
            width: fit-content;
            border-radius: 0 0 0 5px;
            font-size: 0.65rem;
        }
    }
</style>
